import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { lrTheme } from "./core/theme";
import styled from "styled-components";

import "./index.css";
import { SidebarProvider } from "./shared/context/SidebarContext";
import { StackStateProvider } from "./shared/context/StackStateContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <StyledEngineProvider>
      <ThemeProvider theme={lrTheme}>
        <BrowserRouter>
          <StackStateProvider>
            <SidebarProvider>
              <App />
            </SidebarProvider>
          </StackStateProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </>
);
