import React, { useEffect, useState } from "react";
import { HomeCard } from "./components/HomeCard";
import { makeStyles } from "tss-react/mui";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../core/auth/hooks/useAuth";
import { customFetch } from "../../utils/axios";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { useSidebar } from "../../shared/context/SidebarContext";
import Cookies from "js-cookie";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    margin: "7.4vh 0 7.4vh 5.2vw",
  },
  homeCards: {
    display: "flex",
    justifyContent: "center",
    gap: "3.7vh",
  },
  lessHomeCards: {
    display: "flex",
    justifyContent: "left",
    marginLeft: "5.2vw",
    gap: "3.7vh",
    width: "auto",
  },
  notification: {
    position: "absolute",
    top: "3.7vh",
    right: "2.1vw",
    display: "flex",
    alignItems: "center",
    width: "28.6vw",
    borderRadius: "23px",
    background: theme.palette.primary.light,
  },
  notificationText: {
    padding: "2.3vh 2.1vw 2.3vh 0.8vw",
    color: theme.palette.primary.contrastText,
  },
  img: {
    width: "2.1vw",
    padding: "1.9vh 0.5vw 1.9vh 1.6vw",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  link: {
    color: "white",
    "&:visited": {
      color: "white",
    },
  },
}));

interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  const { classes } = useStyles();
  const { hasTwofa, loading, setLoading } = useAuth();
  const navigate = useNavigate();
  const { isSidebarOpen } = useSidebar();

  const role = Cookies.get("role");

  const [subscriberInfo, setSubscriberInfo] = useState({
    planType: "",
    enddate: "",
  });
  const [hostCount, setHostCount] = useState<number>();
  const [has2fa, setHas2fa] = useState<boolean>();

  useEffect(() => {
    document.title = "Home | NMS";
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (!subscriberInfo.planType) {
        const response = await customFetch.get("billing/subscriber-info");
        setSubscriberInfo(response.data);
      }

      if (!hostCount) {
        const response2 = await customFetch.get("configurator/get-all");
        setHostCount(response2.data.length);
      }

      if (!has2fa) {
        const response3 = await hasTwofa();
        setHas2fa(response3);
      }

      // setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const parsedDate = new Date(dateString);

    const day = String(parsedDate.getUTCDate()).padStart(2, "0");
    const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0");
    const year = parsedDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <MediaQuery minWidth={1224}>
          <div
            style={{
              width: "inherit",
              marginLeft: isSidebarOpen ? "21%" : "5%",
            }}
          >
            <Sidebar></Sidebar>
            <Typography variant="h1" className={classes.title}>
              Home
            </Typography>

            {!has2fa && (
              <Box className={classes.notification}>
                <img
                  className={classes.img}
                  src="/images/notification-2fa.png"
                  alt="notification-2faogo"
                />
                <Typography
                  variant="subtitle"
                  className={classes.notificationText}
                >
                  2FA is not enabled for your account. We strongly recommend
                  enabling it{" "}
                  <a href="/settings" className={classes.link}>
                    here
                  </a>
                  .
                </Typography>
              </Box>
            )}

            <Grid
              container
              className={
                role === "CustomerOwner" || role === "CustomerAdmin"
                  ? classes.homeCards
                  : classes.lessHomeCards
              }
            >
              {role !== "CustomerViewer" && (
                <Grid item xs={3}>
                  <HomeCard
                    title="Host Configurator"
                    description={
                      hostCount
                        ? `${hostCount} hosts configured`
                        : "No hosts configured yet."
                    }
                    buttonText="Start Configuration"
                    onClick={() => navigate("/configurator")}
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <HomeCard
                  title="Dashboard Platform"
                  description="Monitor and analyze your infrastructure"
                  buttonText="View Dashboards"
                  buttonIcon="/images/button-visit.png"
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_GRAFANA,
                      "_blank",
                      "noopener noreferrer"
                    )
                  }
                />
              </Grid>
              {(role === "CustomerOwner" || role === "CustomerAdmin") && (
                <Grid item xs={3}>
                  <HomeCard
                    title="Subscription"
                    description={
                      <>
                        Your{" "}
                        <strong>
                          {subscriberInfo.planType === "FreeTrial"
                            ? "Free Trial"
                            : "Pro Plan"}
                        </strong>{" "}
                        {new Date(subscriberInfo.enddate) < new Date()
                          ? `expired on`
                          : `expires on`}{" "}
                        <strong>{formatDate(subscriberInfo.enddate)}</strong>
                      </>
                    }
                    buttonText="Manage Subscription"
                    onClick={() => navigate("/plan-and-billing")}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </MediaQuery>
      )}
      <MediaQuery maxWidth={1224}>
        <MobileHeader></MobileHeader>
        {!loading && (
          <div
            style={{
              width: "100%",
              padding: "10vh 0 56px",
            }}
          >
            {role !== "CustomerViewer" && (
              <HomeCard
                title="Host Configurator"
                description={
                  hostCount
                    ? `${hostCount} hosts configured`
                    : "No hosts configured yet."
                }
                buttonText="Start Configuration"
                onClick={() => navigate("/configurator")}
              />
            )}
            <HomeCard
              title="Dashboard Platform"
              description="Monitor and analyze your infrastructure"
              buttonText="View Dashboards"
              buttonIcon="/images/button-visit.png"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_GRAFANA,
                  "_blank",
                  "noopener noreferrer"
                )
              }
            />
            {(role === "CustomerOwner" || role === "CustomerAdmin") && (
              <HomeCard
                title="Subscription"
                description={
                  <>
                    Your{" "}
                    <strong>
                      {subscriberInfo.planType === "FreeTrial"
                        ? "Free Trial"
                        : "Pro Plan"}
                    </strong>{" "}
                    {new Date(subscriberInfo.enddate) < new Date()
                      ? `expired on`
                      : `expires on`}{" "}
                    <strong>{formatDate(subscriberInfo.enddate)}</strong>
                  </>
                }
                buttonText="Manage Subscription"
                onClick={() => navigate("/plan-and-billing")}
              />
            )}
          </div>
        )}
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
