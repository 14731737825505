import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { FooterState } from "../../utils/enums";

const useStyles = makeStyles()((theme) => ({
  footer: {
    color: theme.palette.primary.light,
    textAlign: "center",

    "& a": {
      color: theme.palette.primary.light,
      textDecoration: "none",
    },
  },
}));

interface FooterProps {
  footerState: FooterState;
}

export const Footer: React.FC<FooterProps> = ({ footerState }) => {
  const { classes } = useStyles();

  const loginFooter = () => (
    <>
      <Typography
        component="a"
        href="https://nodemonitoring.io/terms"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Terms
      </Typography>
      {" | "}
      <Typography
        component="a"
        href="https://nodemonitoring.io/privacy"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Privacy
      </Typography>
      {" | "}
      <Typography
        component="a"
        href="https://support-nms.slack.com"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Support
      </Typography>
      <br />
    </>
  );

  const sidebarFooter = () => (
    <>
      <Typography
        component="a"
        href="https://nodemonitoring.io/terms"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Terms
      </Typography>
      {" | "}
      <Typography
        component="a"
        href="https://nodemonitoring.io/privacy"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Privacy
      </Typography>
    </>
  );

  const docsFooter = () => (
    <>
      <Typography
        component="a"
        href="https://nodemonitoring.io/terms"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Terms
      </Typography>
      {" | "}
      <Typography
        component="a"
        href="https://nodemonitoring.io/privacy"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Privacy
      </Typography>
      {" | "}
      <Typography
        component="a"
        href="https://support-nms.slack.com"
        target="_blank"
        rel="noreferrer"
        variant="caption1"
      >
        Support
      </Typography>
    </>
  );

  return (
    <div className={classes.footer}>
      {footerState === FooterState.Login && loginFooter()}
      {footerState === FooterState.Sidebar && sidebarFooter()}
      {footerState === FooterState.Docs && docsFooter()}
    </div>
  );
};
