import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home/Home";
import { LoginPage } from "./core/auth/pages/login/Login";
import { ForgotPasswordPage } from "./core/auth/pages/forgot-password/ForgotPassword";
import { TwoFactorAuthPage } from "./core/auth/pages/two-factor-auth/TwoFactorAuth";
import { BackupCodePage } from "./core/auth/pages/backup-code/BackupCode";
import { NewPasswordPage } from "./core/auth/pages/new-password/NewPassword";
import { AccountActivationPage } from "./core/auth/pages/account-activation/AccountActivation";
import { ConfiguratorPage } from "./pages/configurator/Configurator";
import { DashboardStorePage } from "./pages/dashboard-store/DashboardStore";
import { PlanAndBillingPage } from "./pages/billing/Billing";
import { SettingsPage } from "./pages/settings/Settings";
import { DashboardDetails } from "./pages/dashboard-store/dashboard-details/DashboardDetails";
import { AuthProvider } from "./core/auth/hooks/useAuth";
import Cookies from "js-cookie";
import DynamicDocs from "./pages/docs/DynamicDocs";
import { UserManagementPage } from "./pages/user-management/UserManagement";
import { AlertStorePage } from "./pages/alert-store/AlertStore";
import { AlertDetails } from "./pages/alert-store/alert-details/AlertDetails";

export const App: React.FC = () => {
  const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
    element,
  }) => {
    const hasAccessToken = !!Cookies.get("accessToken");
    const hasRefreshToken = !!Cookies.get("refreshToken");

    if (!hasAccessToken && hasRefreshToken) {
      return element;
    }

    if (hasAccessToken) {
      return element;
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("role");

      return <Navigate to="/login" replace />;
    }
  };

  interface RoleBasedRouteProps {
    element: React.ReactElement;
    allowedRoles: string[];
  }

  const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
    element,
    allowedRoles,
  }) => {
    const role = Cookies.get("role") ?? "";

    if (allowedRoles.includes(role)) {
      return element;
    } else {
      return <Navigate to="/home" replace />;
    }
  };

  return (
    <React.Fragment>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route path="/2fa" element={<TwoFactorAuthPage />}></Route>
          <Route path="/backup-code" element={<BackupCodePage />}></Route>
          <Route
            path="/new-password/:email"
            element={<NewPasswordPage />}
          ></Route>
          <Route
            path="/account-activation/:email"
            element={<AccountActivationPage />}
          ></Route>
          <Route
            path="/home"
            element={<PrivateRoute element={<HomePage />} />}
          ></Route>
          <Route
            path="/configurator"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<ConfiguratorPage />}
                    allowedRoles={[
                      "CustomerOwner",
                      "CustomerAdmin",
                      "CustomerEditor",
                    ]}
                  />
                }
              />
            }
          />
          <Route
            path="/dashboard-store"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<DashboardStorePage />}
                    allowedRoles={[
                      "CustomerOwner",
                      "CustomerAdmin",
                      "CustomerEditor",
                    ]}
                  />
                }
              />
            }
          />
          <Route
            path="/alert-store"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<AlertStorePage />}
                    allowedRoles={[
                      "CustomerOwner",
                      "CustomerAdmin",
                      "CustomerEditor",
                    ]}
                  />
                }
              />
            }
          />
          <Route
            path="/dashboard-store/:id"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<DashboardDetails />}
                    allowedRoles={[
                      "CustomerOwner",
                      "CustomerAdmin",
                      "CustomerEditor",
                    ]}
                  />
                }
              />
            }
          />
          <Route
            path="/alert-store/:id"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<AlertDetails />}
                    allowedRoles={[
                      "CustomerOwner",
                      "CustomerAdmin",
                      "CustomerEditor",
                    ]}
                  />
                }
              />
            }
          />
          <Route
            path="/plan-and-billing"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<PlanAndBillingPage />}
                    allowedRoles={["CustomerOwner", "CustomerAdmin"]}
                  />
                }
              />
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute
                element={
                  <RoleBasedRoute
                    element={<UserManagementPage />}
                    allowedRoles={["CustomerOwner", "CustomerAdmin"]}
                  />
                }
              />
            }
          />
          <Route
            path="/settings"
            element={<PrivateRoute element={<SettingsPage />} />}
          ></Route>
          <Route
            path="/docs/:docId"
            element={<PrivateRoute element={<DynamicDocs />} />}
          ></Route>
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </AuthProvider>
    </React.Fragment>
  );
};

export default App;
