import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Service } from "../../../../../utils/types";

const useStyles = makeStyles()((theme) => ({
  serviceBox: {
    border: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
  },
  serviceName: {
    ...theme.typography.subtitle,
    margin: "20px 0 20px 80px",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10px 0 10px 20px",
      width: "70vw",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  arrow: {
    margin: "1.9vh 2.1vw 1.9vh 0",
  },
  collapsed: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      height: "5vh",
    },
  },
  alignRight: {
    display: "flex",
    justifyContent: "end",
    padding: "10px 20px",
    [`@media screen and (max-width: 1224px)`]: {
      padding: "0.5vh 3vw",
    },
  },
  valueGrid: {
    padding: "0.9vh 1vw",
    [`@media screen and (max-width: 1224px)`]: {
      padding: "0.5vh 2vw",
    },
  },
  valuesBox: {
    margin: "1.9vh 4.2vw",
  },
  label: {
    ...theme.typography.subtitle,
  },
  value: {
    color: theme.palette.primary.light,
    ...theme.typography.subtitle,
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "1.9vh 4.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1.9vh 1vw",
    },
  },
  buttonBlack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "30%",
    height: "4.6vh",
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  buttonWhite: {
    background: theme.palette.button.light,
    borderRadius: "15px",
    width: "30%",
    height: "4.6vh",
    ...theme.typography.body1,
    border: `0.1vw solid ${theme.palette.background2}`,
    "&:hover": {
      color: "white",
      border: "none",
      "& span svg path": {
        stroke: "white !important",
      },
    },

    "& span svg path": {
      stroke: "black !important",
    },
  },

  buttonOutlined: {
    border: "0.1vw solid white",
    width: "30%",
    borderRadius: "15px",
    height: "4.6vh",
    margin: "1vw",
    "&:hover": {
      border: "none",
    }
  },
  buttonBlackDialog: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "4.6vh",
    width: "30%",
    margin: "1vw",
  },
  dialogText: {
    background: theme.palette.primary.light,
    color: "white !important",
    margin: "2.6vw",
    textAlign: "center",
  },
  dialogContent: {
    overflow: "hidden",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.primary.light,
    color: "white !important",
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  icon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    height: "2.6vw",
    width: "2.6vw",
    [`@media screen and (max-width: 1224px)`]: {
      height: "1vw",
      width: "1vw",
    },
  },
  buttonIcon: {
    [`@media screen and (max-width: 1224px)`]: {
      height: "3vw",
      width: "3vw",
    },
  },
}));

interface ServiceBoxProps {
  service: Service;
  onEdit: (serviceName: string) => void;
  onDuplicate: (serviceName: string) => void;
  onDelete: (serviceName: string) => void;
}

const ServiceBox: React.FC<ServiceBoxProps> = ({
  service,
  onEdit,
  onDelete,
  onDuplicate,
}) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.serviceBox}>
      <Dialog
        PaperProps={{
          style: {
            background: "#6D7A8D",
            borderRadius: "23px",
            height: "fit-content",
            width: "42vw",
            maxWidth: "42vw",
          },
        }}
        open={open}
        onClose={handleCloseDialog}
      >
        <img
          src="/images/info-box-close.png"
          alt="close"
          onClick={handleCloseDialog}
          className={classes.icon}
        />
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            <Typography variant="subtitle">
              Are you sure that you want to delete this service configuration?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonOutlined}
            variant="outlined"
            onClick={handleCloseDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={() => {
              onDelete(service.serviceName ?? "");
              handleCloseDialog();
            }}
            className={classes.buttonBlackDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.collapsed}>
        <Typography variant="h6" className={classes.serviceName}>
          {service.serviceName}
        </Typography>
        <IconButton
          onClick={() => setExpanded(!expanded)}
          className={classes.arrow}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      {expanded && (
        <>
          <Divider />

          <Box className={classes.valuesBox}>
            <Grid container spacing={0}>
              <Grid item xs={6} className={classes.alignRight}>
                <Typography className={classes.label}>Label:</Typography>
              </Grid>
              <Grid item xs={6} className={classes.valueGrid}>
                <Typography className={classes.value}>
                  {service.label}
                </Typography>
              </Grid>
              {service.network && (
                <>
                  <Grid item xs={6} className={classes.alignRight}>
                    <Typography className={classes.label}>Network:</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.valueGrid}>
                    <Typography className={classes.value}>
                      {service.network}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6} className={classes.alignRight}>
                <Typography className={classes.label}>IP:</Typography>
              </Grid>
              <Grid item xs={6} className={classes.valueGrid}>
                <Typography className={classes.value}>{service.ip}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Typography className={classes.label}>Port:</Typography>
              </Grid>
              <Grid item xs={6} className={classes.valueGrid}>
                <Typography className={classes.value}>
                  {service.port}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Typography className={classes.label}>Path:</Typography>
              </Grid>
              <Grid item xs={6} className={classes.valueGrid}>
                <Typography className={classes.value}>
                  {service.path}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.alignRight}>
                <Typography className={classes.label}>Protocol:</Typography>
              </Grid>
              <Grid item xs={6} className={classes.valueGrid}>
                <Typography className={classes.value}>
                  {service.protocol}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box className={classes.buttonBox}>
            <Button
              className={classes.buttonBlack}
              startIcon={<img className={classes.buttonIcon} src="/images/button-edit.png" alt="edit" />}
              onClick={() => onEdit(service.serviceName ?? "")}
            >
              Edit
            </Button>
            <Button
              className={classes.buttonBlack}
              startIcon={
                <img className={classes.buttonIcon} src="/images/button-duplicate.png" alt="duplicate" />
              }
              onClick={() => onDuplicate(service.serviceName ?? "")}
            >
              Duplicate
            </Button>
            <Button
              className={classes.buttonWhite}
              startIcon={
                <svg
                  width="23"
                  height="25"
                  viewBox="0 0 23 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6.14307H21.5714"
                    stroke="white"
                    stroke-width="1.71429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.57153 6.14307H19.0001V21.5716C19.0001 22.0263 18.8195 22.4623 18.498 22.7838C18.1765 23.1053 17.7405 23.2859 17.2858 23.2859H5.28582C4.83116 23.2859 4.39513 23.1053 4.07364 22.7838C3.75214 22.4623 3.57153 22.0263 3.57153 21.5716V6.14307Z"
                    stroke="white"
                    stroke-width="1.71429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 6.14286V5.28571C7 4.14907 7.45153 3.05898 8.25526 2.25526C9.05898 1.45153 10.1491 1 11.2857 1C12.4224 1 13.5124 1.45153 14.3162 2.25526C15.1199 3.05898 15.5714 4.14907 15.5714 5.28571V6.14286"
                    stroke="white"
                    stroke-width="1.71429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.71436 11.2876V18.1482"
                    stroke="white"
                    stroke-width="1.71429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.8572 11.2876V18.1482"
                    stroke="white"
                    stroke-width="1.71429"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              onClick={() => handleOpenDialog()}
            >
              Delete
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ServiceBox;
