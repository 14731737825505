import Cookies from "js-cookie";
import axios from "axios";

export const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${Cookies.get("accessToken")}`,
  },
});

let isRefreshing: boolean = false;
let failedQueue: any[] = [];

const processQueue = (error: null, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshToken = async () => {
  try {
    const tokenValue = Cookies.get("refreshToken");
    const resp = await customFetch.post("/auth/refresh-token", {
      refreshToken: tokenValue,
    });

    Cookies.set("accessToken", resp.data.accessToken);
    Cookies.set("refreshToken", resp.data.refreshToken);

    return resp.data;
  } catch (e) {
    return null;
  }
};

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 403 ||
        error.response.status === 401 ||
        error.response.status === 404) &&
      !originalRequest._retry
    ) {
      if (error.response.status === 404) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return customFetch(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const resp = await refreshToken();

      if (!resp) {
        processQueue(error, null);
        isRefreshing = false;
        return Promise.reject(error);
      }

      processQueue(null, resp.accessToken);
      customFetch.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${resp.accessToken}`;
      isRefreshing = false;

      return customFetch(originalRequest);
    }
    return Promise.reject(error);
  }
);

customFetch.interceptors.request.use((config) => {
  const accessToken = Cookies.get("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});
