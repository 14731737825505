import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography, Button, Box, CircularProgress } from "@mui/material";
import { EmptyStack } from "./components/empty-stack/EmptyStack";
import { CurrentService, StackItem } from "../../utils/types";
import { StackTable } from "./components/stack-table/StackTable";
import { customFetch } from "../../utils/axios";
import { ToolSelection } from "./components/tool-selection/ToolSelection";
import { ServiceTypeSelection } from "./components/service-type-selection/ServiceTypeSelection";
import { ServiceSelection } from "./components/service-selection/ServiceSelection";
import { ServiceDetails } from "./components/service-details/ServiceDetails";
import { ServiceTable } from "./components/service-table/ServiceTable";
import { Deployment } from "./components/deployment/Deployment";
import { Finish } from "./components/finish/Finish";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { useSidebar } from "../../shared/context/SidebarContext";
import {
  StackState,
  useStackState,
} from "../../shared/context/StackStateContext";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "auto",
    [`@media screen and (max-width: 1224px)`]: {
      height: "100%",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "7.4vh 0 2.8vh 2.3vw",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    width: "19%",
    height: "4.6vh",
    marginRight: "6.3vw",
    justifyContent: "space-around",
    [`@media screen and (min-width: 1224px) and (max-width: 1600px)`]: {
      width: "20%",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "2vh 5vw",
      justifyContent: "center",
    },
  },
  buttonBlackStep5: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    width: "18%",
    height: "4.6vh",
    marginRight: "6.3vw",
    justifyContent: "space-around",
    [`@media screen and (min-width: 1224px) and (max-width: 1600px)`]: {
      width: "20%",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "10vh 5vw 1vh",
      justifyContent: "center",
    },
  },
  buttonBlackText: {
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

export enum ServiceState {
  NONE = "none",
  ADD = "add",
  EDIT = "edit",
  DUPLICATE = "duplicate",
}

interface ConfiguratorPageProps {}

const getEmptyStack = (): StackItem => {
  return {
    hostName: "",
    lastModified: new Date(),
    tools: [
      {
        name: "",
        label: "",
        port: undefined,
        metricsRetentionPeriod: undefined,
      },
    ],
    services: [
      {
        id: 0,
        name: "",
        label: "",
        serviceName: "",
        ip: "",
        port: 0,
        path: "",
        protocol: "",
        network: "",
      },
    ],
  };
};

export const ConfiguratorPageComponent: React.FC<
  ConfiguratorPageProps
> = () => {
  const { classes } = useStyles();
  const [stacks, setStacks] = useState<any[]>([]);
  const [currentStack, setCurrentStack] = useState<StackItem>(getEmptyStack());
  const [currentService, setCurrentService] = useState<CurrentService>({
    id: 0,
    name: "",
    label: "",
    service: {
      id: 0,
      name: "",
      label: "",
      serviceName: "",
      ip: "",
      port: 0,
      path: "",
      protocol: "",
      network: "",
    },
  });
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState(false);
  const [stackId, setStackId] = useState<string>("");
  const { stackState, setStackState } = useStackState();
  const [serviceState, setServiceState] = useState<ServiceState>(
    ServiceState.ADD
  );
  const { isSidebarOpen } = useSidebar();

  useEffect(() => {
    document.title = "Host Configurator | NMS";
    setStackState(StackState.NONE);
    fetchData();
  }, []);

  useEffect(() => {
    if (step === 10) {
      setStackState(StackState.FINISHED);
    }

    if (step === 0) {
      setStackState(StackState.NONE);
    }

    if (stackState !== StackState.NONE || StackState.FINISHED) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [stackState, step]);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("configurator/get-all");

      const sortedStacks = response.data
        .map((stack: { hostName: any; lastModified: any; id: any }) => {
          return {
            hostName: stack.hostName,
            lastModified: stack.lastModified,
            id: stack.id,
          };
        })
        .sort(
          (
            a: { lastModified: string | number | Date },
            b: { lastModified: string | number | Date }
          ) => {
            return (
              new Date(b.lastModified).getTime() -
              new Date(a.lastModified).getTime()
            );
          }
        );

      setStacks(sortedStacks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getStack = async (name: string, id: string) => {
    try {
      const response = await customFetch.get(`configurator/get/${id}`);

      const stack = JSON.parse(response.data.stack);

      setCurrentStack({ ...stack, hostName: name });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteStack = async (id: string) => {
    try {
      await customFetch.delete(`configurator/delete/${id}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onCreateStack = () => {
    setStackState(StackState.CREATE);
    setServiceState(ServiceState.NONE);
    setCurrentStack(getEmptyStack());
    setSaved(false);
    setStep(1);
  };

  const onEditStack = (name: string, id: string) => {
    getStack(name, id);
    setStackId(id);
    setStackState(StackState.EDIT);
    setServiceState(ServiceState.NONE);
    setSaved(false);
    setStep(1);
  };

  const onDeployStack = (name: string, id: string) => {
    getStack(name, id);
    setStackState(StackState.EDIT);
    setServiceState(ServiceState.NONE);
    setSaved(false);
    setStep(6);
  };

  const onDeleteStack = (id: string) => {
    const updateStacks = stacks.filter((stack) => stack.id !== id);

    setStacks(updateStacks);
    deleteStack(id);
  };

  const onNext = (stepNumber?: number) => {
    setStep(stepNumber ?? step + 1);
  };

  const onBack = (stepNumber?: number) => {
    setStep(stepNumber ?? step - 1);
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "24%" : "8%",
          }}
        >
          <Sidebar></Sidebar>
          <Box className={classes.flex}>
            <Box className={classes.titleBox}>
              {stackState === StackState.NONE && (
                <Typography variant="h1">Host Configurator</Typography>
              )}
              {stackState === StackState.CREATE && (
                <Typography variant="h1">New Configuration</Typography>
              )}
              {stackState === StackState.EDIT && (
                <Typography variant="h1">{currentStack?.hostName}</Typography>
              )}
              {stacks.length !== 0 && step === 0 && (
                <Button
                  className={classes.buttonBlack}
                  startIcon={<img src="/images/button-add.png" alt="add" />}
                  onClick={onCreateStack}
                >
                  <Typography
                    variant="subtitle"
                    className={classes.buttonBlackText}
                  >
                    New Host Configuration
                  </Typography>
                </Button>
              )}
              {step === 5 && (
                <Button
                  className={classes.buttonBlack}
                  startIcon={<img src="/images/button-add.png" alt="add" />}
                  onClick={() => {
                    setServiceState(ServiceState.ADD);
                    setCurrentService({
                      id: 0,
                      name: "",
                      label: "",
                      service: {
                        id: 0,
                        name: "",
                        label: "",
                        serviceName: "",
                        ip: "",
                        port: 0,
                        path: "",
                        protocol: "",
                        network: "",
                      },
                    });
                    onNext(2);
                  }}
                >
                  <Typography
                    variant="subtitle"
                    className={classes.buttonBlackText}
                  >
                    Add Service
                  </Typography>
                </Button>
              )}
            </Box>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!stacks.length && step === 0 && !loading && (
              <EmptyStack onClick={onCreateStack} />
            )}
            {stacks.length !== 0 && step === 0 && !loading && (
              <StackTable
                stacks={stacks}
                onEdit={onEditStack}
                onDeploy={onDeployStack}
                onDelete={onDeleteStack}
              />
            )}
            {step === 1 && (
              <ToolSelection
                onNext={onNext}
                stacks={stacks}
                currentStack={currentStack}
                setCurrentStack={setCurrentStack}
                stackState={stackState}
              ></ToolSelection>
            )}
            {step === 2 && (
              <ServiceTypeSelection
                onBack={onBack}
                onNext={onNext}
                currentService={currentService}
                setCurrentService={setCurrentService}
                serviceState={serviceState}
              ></ServiceTypeSelection>
            )}
            {step === 3 && (
              <ServiceSelection
                onBack={onBack}
                onNext={onNext}
                currentService={currentService}
                setCurrentService={setCurrentService}
              ></ServiceSelection>
            )}
            {step === 4 && (
              <ServiceDetails
                onBack={onBack}
                onNext={onNext}
                currentStack={currentStack}
                setCurrentStack={setCurrentStack}
                currentService={currentService}
                setCurrentService={setCurrentService}
                stackState={stackState}
                serviceState={serviceState}
                setServiceState={setServiceState}
              ></ServiceDetails>
            )}
            {step === 5 && (
              <ServiceTable
                onBack={onBack}
                onNext={onNext}
                currentStack={currentStack}
                setCurrentStack={setCurrentStack}
                currentService={currentService}
                setCurrentService={setCurrentService}
                stackState={stackState}
                setServiceState={setServiceState}
                stackId={stackId}
                fetchData={fetchData}
                saved={saved}
                setSaved={setSaved}
              ></ServiceTable>
            )}
            {step >= 6 && step < 10 && (
              <Deployment
                currentStack={currentStack}
                step={step}
                setStep={setStep}
                stackState={stackState}
                onNext={onNext}
                onBack={onBack}
              ></Deployment>
            )}
            {step === 10 && stackState === StackState.FINISHED && (
              <Finish setStep={setStep} />
            )}
          </Box>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        {stackState === StackState.NONE && (
          <MobileHeader title="Host Configurator"></MobileHeader>
        )}
        {stackState === StackState.CREATE && (
          <MobileHeader title="New Configuration"></MobileHeader>
        )}
        {stackState === StackState.EDIT && (
          <MobileHeader title={currentStack?.hostName}></MobileHeader>
        )}
        <div
          style={{
            width: "100%",
            height: "94vh",
            paddingBottom: "56px",
          }}
        >
          <Box className={classes.flex}>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {!stacks.length && step === 0 && !loading && (
              <EmptyStack onClick={onCreateStack} />
            )}
            {stacks.length !== 0 && step === 0 && !loading && (
              <StackTable
                stacks={stacks}
                onEdit={onEditStack}
                onDeploy={onDeployStack}
                onDelete={onDeleteStack}
              />
            )}
            {stacks.length !== 0 && step === 0 && (
              <Button
                className={classes.buttonBlack}
                startIcon={<img src="/images/button-add.png" alt="add" />}
                onClick={onCreateStack}
              >
                <Typography
                  variant="subtitle"
                  className={classes.buttonBlackText}
                >
                  New Host Configuration
                </Typography>
              </Button>
            )}
            {step === 1 && (
              <ToolSelection
                onNext={onNext}
                stacks={stacks}
                currentStack={currentStack}
                setCurrentStack={setCurrentStack}
                stackState={stackState}
              ></ToolSelection>
            )}
            {step === 2 && (
              <ServiceTypeSelection
                onBack={onBack}
                onNext={onNext}
                currentService={currentService}
                setCurrentService={setCurrentService}
                serviceState={serviceState}
              ></ServiceTypeSelection>
            )}
            {step === 3 && (
              <ServiceSelection
                onBack={onBack}
                onNext={onNext}
                currentService={currentService}
                setCurrentService={setCurrentService}
              ></ServiceSelection>
            )}
            {step === 4 && (
              <ServiceDetails
                onBack={onBack}
                onNext={onNext}
                currentStack={currentStack}
                setCurrentStack={setCurrentStack}
                currentService={currentService}
                setCurrentService={setCurrentService}
                stackState={stackState}
                serviceState={serviceState}
                setServiceState={setServiceState}
              ></ServiceDetails>
            )}
            {step === 5 && (
              <>
                <Button
                  className={classes.buttonBlackStep5}
                  startIcon={<img src="/images/button-add.png" alt="add" />}
                  onClick={() => {
                    setServiceState(ServiceState.ADD);
                    setCurrentService({
                      id: 0,
                      name: "",
                      label: "",
                      service: {
                        id: 0,
                        name: "",
                        label: "",
                        serviceName: "",
                        ip: "",
                        port: 0,
                        path: "",
                        protocol: "",
                        network: "",
                      },
                    });
                    onNext(2);
                  }}
                >
                  <Typography
                    variant="subtitle"
                    className={classes.buttonBlackText}
                  >
                    Add Service
                  </Typography>
                </Button>
                <ServiceTable
                  onBack={onBack}
                  onNext={onNext}
                  currentStack={currentStack}
                  setCurrentStack={setCurrentStack}
                  currentService={currentService}
                  setCurrentService={setCurrentService}
                  stackState={stackState}
                  setServiceState={setServiceState}
                  stackId={stackId}
                  fetchData={fetchData}
                  saved={saved}
                  setSaved={setSaved}
                ></ServiceTable>
              </>
            )}
            {step >= 6 && step < 10 && (
              <Deployment
                currentStack={currentStack}
                step={step}
                setStep={setStep}
                stackState={stackState}
                onNext={onNext}
                onBack={onBack}
              ></Deployment>
            )}
            {step === 10 && stackState === StackState.FINISHED && (
              <Finish setStep={setStep} />
            )}
          </Box>
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};

export const ConfiguratorPage = React.memo(ConfiguratorPageComponent);
