import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { StackItem, Tool } from "../../../../utils/types";
import { customFetch } from "../../../../utils/axios";
import { StackState } from "../../../../shared/context/StackStateContext";
import { InfoBox } from "../../../../shared/InfoBox";

const useStyles = makeStyles()((theme) => ({
  toolSelection: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "0 7.3vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 0",
    },
  },
  inputProps: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.dark,
  },
  textField: {
    width: "41.7vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
    },
  },
  subtitle: {
    margin: "3.7vh 0 2.8vh",
  },
  outerToolBox: {
    display: "flex",
    gap: "2.1vw",
    overflow: "auto",
    paddingBottom: "1.9vh",
    [`@media screen and (max-width: 1224px)`]: {
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "4vw",
    },
  },
  innerToolBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "45%",
    },
  },
  fieldLabel: {
    margin: "0.9vh 0",
  },
  loading: {
    position: "relative",
    top: "50%",
    left: "50%",
  },
  imgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.9vh",
    width: "10.4vw",
    height: "10.4vw",
    border: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    "&:hover": { cursor: "pointer" },
    [`@media screen and (max-width: 1224px)`]: {
      width: "35vw",
      height: "35vw",
    },
  },
  imgBoxSelected: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.9vh",
    width: "10vw",
    height: "10vw",
    border: `0.3vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    "&:hover": { cursor: "pointer" },
    [`@media screen and (max-width: 1224px)`]: {
      width: "calc(35vw - 10px)",
      height: "calc(35vw - 10px)",
      border: `6px solid ${theme.palette.background2}`,
    },
  },
  toolImg: {
    height: "9.3vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "20vw",
      height: "20vw",
    },
  },
  toolTextField: {
    margin: 0,
    width: "10.4vw",
    "& .MuiOutlinedInput-root": {
      borderRadius: "23px",
    },
    "& input": {
      textAlign: "center",
      [`@media screen and (max-width: 1224px)`]: {
        padding: "0.5vh",
      },
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
      [`@media screen and (max-width: 1224px)`]: {
        padding: "0.5vh",
      },
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    [`@media screen and (max-width: 1224px)`]: {
      padding: "0.5vh",
      width: "30vw",
    },
  },
  small: {
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "15px",
    },
  },
  buttonBox: {
    marginTop: "1.9vh",
    display: "flex",
    justifyContent: "end",
    marginBottom: "3.7vh",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    justifyItems: "end",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  infoBox: {
    display: "flex",
    gap: "0.9vh",
  },
  icon: {
    height: "2vh",
    width: "2vh",
    marginLeft: "0.5vw",
  },
  link: {
    color: "white",
    "&:visited": {
      color: "white",
    },
  },
}));

interface ToolSelectionProps {
  onNext: (stepNumbe?: number) => void;
  stacks: any[];
  currentStack: StackItem;
  setCurrentStack: React.Dispatch<React.SetStateAction<StackItem>>;
  stackState: StackState;
}

export const ToolSelection: React.FC<ToolSelectionProps> = ({
  onNext,
  stacks,
  currentStack,
  setCurrentStack,
  stackState,
}) => {
  const { classes } = useStyles();
  const [hostNameError, setHostNameError] = useState<boolean>(false);
  const [duplicateHostNameError, setDuplicateHostNameError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showHostNameInfo, setShowHostNameInfo] = useState<boolean>(false);
  const [showToolInstallationInfo, setShowToolInstallationInfo] =
    useState<boolean>(false);

  const [toolList, setToolList] = useState<Tool[]>(currentStack.tools);
  const [selectedTools, setSelectedTools] = useState<string[]>(
    currentStack.tools.map((tool) => tool.name).filter(Boolean)
  );

  useEffect(() => {
    setSelectedTools(
      currentStack.tools.map((tool) => tool.name).filter(Boolean)
    );
    fetchData();
  }, [currentStack.tools]);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("configurator/tools");

      const transformedList = response.data.map((tool: any) => {
        return {
          name: tool.name,
          label: tool.label,
          port: tool.port,
          metricsRetentionPeriod: tool.logRetentionTime,
        };
      });

      setToolList(transformedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const changeHostName = (name: string) => {
    setCurrentStack({ ...currentStack, hostName: name });
    validateHostName(name);
  };

  const changePort = (toolName: string, newPort: number | string) => {
    setCurrentStack((prevStack) => ({
      ...prevStack,
      tools: prevStack.tools.map((tool) =>
        tool.name === toolName
          ? { ...tool, port: newPort === "" ? "" : Number(newPort) }
          : tool
      ),
    }));
  };

  const changeMetricsRetentionPeriod = (
    toolName: string,
    newMetricsRetentionPeriod: number | string
  ) => {
    const val =
      typeof newMetricsRetentionPeriod === "string"
        ? parseInt(newMetricsRetentionPeriod, 10)
        : newMetricsRetentionPeriod;
    const metricsRetentionPeriodValue = Math.max(1, Math.min(val, 15));

    setCurrentStack((prevStack) => ({
      ...prevStack,
      tools: prevStack.tools.map((tool) =>
        tool.name === toolName
          ? {
              ...tool,
              metricsRetentionPeriod: metricsRetentionPeriodValue,
            }
          : tool
      ),
    }));
  };

  const selectTool = (toolName: string) => {
    const newSelectedTools = selectedTools.includes(toolName)
      ? selectedTools.filter((tool) => tool !== toolName)
      : [...selectedTools, toolName];

    setSelectedTools(newSelectedTools);
    updateCurrentStackTools(newSelectedTools);
  };

  const updateCurrentStackTools = (newSelectedTools: string[]) => {
    const tools = newSelectedTools
      .map(
        (name) =>
          currentStack.tools.find((tool) => tool.name === name) ||
          toolList.find((tool) => tool.name === name)
      )
      .filter(Boolean) as Tool[];

    setCurrentStack({ ...currentStack, tools });
  };

  const validateHostName = (name: string) => {
    const isDuplicate = stacks.some((stack) => stack.hostName === name);

    setDuplicateHostNameError(isDuplicate);
    setHostNameError(!name);
  };

  const navigate = () => {
    if (!duplicateHostNameError && !hostNameError && selectedTools.length) {
      stackState === StackState.EDIT || currentStack.services[0].id !== 0
        ? onNext(5)
        : onNext();
    }
  };

  return (
    <Box className={classes.toolSelection}>
      {showHostNameInfo && (
        <InfoBox
          showInfo={showHostNameInfo}
          setShowInfo={setShowHostNameInfo}
          title="Host Name"
        >
          <>
            Specify the name of the machine that hosts the node, EA or other
            service you want to monitor. The machine will be displayed under
            this name on your dashboards.
          </>
        </InfoBox>
      )}
      {showToolInstallationInfo && (
        <InfoBox
          showInfo={showToolInstallationInfo}
          setShowInfo={setShowToolInstallationInfo}
          title="Tool Installation"
        >
          <>
            Select all tools to be installed on this machine and specify the
            respective port.
            <br />
            <br />
            <strong>Prometheus:</strong>
            <br />
            Scraper for service metrics
            <br />
            <br />
            <strong>Node Exporter: </strong>
            <br />
            Scraper for host metrics
            <br />
            <br />
            <strong>cAdvisor:</strong>
            <br />
            Scraper for Docker metrics
            <br />
            <br />
            <strong>Promtail:</strong>
            <br />
            Scraper for logs
            <br />
            <br />
            If you are already running data collection tools and simply want to
            connect them to take advantage of the NMS dashboards, please refer
            to the{" "}
            <a
              href={`${window.location.origin}/docs/manual-configuration`}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              documentation
            </a>
            .
          </>
        </InfoBox>
      )}
      <Box>
        <Box style={{ display: "flex" }}>
          <Typography variant="h2">Host Name</Typography>
          <Tooltip
            arrow
            title="Specify the name of the machine that hosts the node, EA or other service you want to monitor. The machine will be displayed under this name on your dashboards."
            placement="top-start"
          >
            <img
              src="/images/details-info.png"
              alt="info"
              className={classes.icon}
              onClick={() => setShowHostNameInfo(!showHostNameInfo)}
            ></img>
          </Tooltip>
        </Box>
        <TextField
          className={classes.textField}
          InputProps={{ classes: { input: classes.inputProps } }}
          inputProps={{ maxLength: 30 }}
          variant="standard"
          margin="normal"
          fullWidth
          name="host-name"
          label="Host Name"
          type="text"
          id="host-name"
          value={currentStack.hostName}
          onChange={(e) => changeHostName(e.currentTarget.value)}
          error={hostNameError || duplicateHostNameError}
          helperText={
            hostNameError
              ? "Host Name is required."
              : duplicateHostNameError
              ? "Host names should be unique."
              : ""
          }
        />
        <Box className={classes.infoBox}>
          <Typography variant="h2" className={classes.subtitle}>
            Tool Installation
          </Typography>
          <img
            src="/images/tool-info.png"
            alt="info"
            height="22px"
            className={classes.subtitle}
            onClick={() =>
              setShowToolInstallationInfo(!showToolInstallationInfo)
            }
          />
        </Box>
        {loading && <CircularProgress className={classes.loading} />}
        {!loading && (
          <Box className={classes.outerToolBox}>
            {toolList.map((tool) => {
              const currentTool = currentStack.tools.find(
                (currentTool) => currentTool.name === tool.name
              );

              return (
                <Box key={tool.name} className={classes.innerToolBox}>
                  <Box
                    className={
                      selectedTools.includes(tool.name)
                        ? classes.imgBoxSelected
                        : classes.imgBox
                    }
                    onClick={() => selectTool(tool.name)}
                  >
                    {selectedTools.includes(tool.name) ? (
                      <img
                        className={classes.toolImg}
                        src={`/images/tool-${tool.label}-selected.png`}
                        alt={`${tool.label}-selected`}
                      ></img>
                    ) : (
                      <img
                        className={classes.toolImg}
                        src={`/images/tool-${tool.label}.png`}
                        alt={`${tool.label}`}
                      ></img>
                    )}
                    <Typography variant="subtitle" className={classes.small}>
                      {tool.name}
                    </Typography>
                  </Box>
                  <Typography variant="body1" className={classes.fieldLabel}>
                    Container Port
                  </Typography>
                  <TextField
                    className={classes.toolTextField}
                    inputProps={{ maxLength: 6 }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="port"
                    type="number"
                    id={`${tool.label}-port`}
                    value={currentTool ? currentTool.port : tool.port}
                    onChange={(e) =>
                      changePort(tool.name, e.currentTarget.value)
                    }
                  />
                  {selectedTools.includes(tool.name) &&
                    tool.name === "Prometheus" && (
                      <>
                        <Typography
                          variant="body1"
                          className={classes.fieldLabel}
                        >
                          Metrics retention period
                        </Typography>
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {currentTool!.metricsRetentionPeriod === 1
                                  ? "day"
                                  : "days"}
                              </InputAdornment>
                            ),
                          }}
                          className={classes.toolTextField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="metrics-retention-period"
                          type="number"
                          id={`${tool.label}-metrics-retention-period`}
                          value={
                            currentTool
                              ? currentTool.metricsRetentionPeriod
                              : tool.metricsRetentionPeriod
                          }
                          onChange={(e) =>
                            changeMetricsRetentionPeriod(
                              tool.name,
                              e.currentTarget.value
                            )
                          }
                        />
                      </>
                    )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box className={classes.buttonBox}>
        <Button className={classes.buttonBlack} onClick={navigate}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
