import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  emptyUserBox: {
    padding: "2.8vh 2.1vw",
    margin: "0 5.2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    border: `0.1vh solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5.2vw 0 5.2vw",
      textAlign: "center",
      alignItems: "center",
    },
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    width: "25%",
    height: "4.6vh",
    marginTop: "2.8vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "80%",
      textAlign: "center",
    },
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  title: {
    paddingBottom: "2.8vh",
    width: "100%",
  },
  description: {
    alignSelf: "start",
  },
}));

interface EmptyUserProps {
  onClick: () => void;
}

export const EmptyUser: React.FC<EmptyUserProps> = ({ onClick }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.emptyUserBox}>
      <Typography variant="h2" className={classes.title}>
        Create additional users
      </Typography>
      <Typography variant="subtitle" className={classes.description}>
        You can create additional users for individual access to your NMS organization.
      </Typography>
      <Button className={classes.buttonBlack} onClick={onClick}>
        <Typography variant="subtitle" className={classes.buttonBlackText}>
          Create User
        </Typography>
      </Button>
    </Box>
  );
};
