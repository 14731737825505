import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import MediaQuery from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";
import {
  StackState,
  useStackState,
} from "../../shared/context/StackStateContext";

const useStyles = makeStyles()((theme) => ({
  navbar: {
    width: "100%",
    backgroundColor: "#E8E9F3",
    display: "flex",
    justifyContent: "space-around",
  },
  toolbar: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "7vw",
    padding: "0 !important",
  },
  navbarIcons: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  iconButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
  extendedMenu: {
    position: "absolute",
    bottom: "9px",
    width: "40px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  stayButton: {
    ...theme.typography.body1,
    border: "1px solid #000000",
    width: "5.2vw",
    height: "2.8vh",
    "&:hover": {
      color: "white",
    },
  },
  leaveButton: {
    ...theme.typography.body1,
    border: "1px solid #000000",
    width: "5.2vw",
    height: "2.8vh",
    color: theme.palette.button.mainText,
    background: theme.palette.button.main,
    textTransform: "none",
  },
  logoSmall: {
    padding: "4.6vh 0",
    display: "flex",
    justifyContent: "center",
    "&:hover": { cursor: "pointer" },
    width: "3.2vw",
  },
  listItem: {
    gap: "3.2vh",
    height: "4.1vh",
    color: theme.palette.primary.light,
    borderRadius: "25%",
    [`@media screen and (max-width: 1600px)`]: {
      maxWidth: "240px",
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

interface NavbarProps {}

export const Navbar: React.FC<NavbarProps> = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth();

  const [open, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { stackState } = useStackState();

  const determineSelectedIndex = () => {
    let foundItemIndex;

    const allLists = [
      { link: "/configurator" },
      { link: "/dashboard-store" },
      { link: "/home" },
      { link: "/alert-store" },
      { link: "/settings" },
    ];

    if (/^\/dashboard-store\/\d+$/.test(location.pathname)) {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === "/dashboard-store"
      );
    } else if (/^\/alert-store\/\d+$/.test(location.pathname)) {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === "/alert-store"
      );
    } else if (location.pathname === "/plan-and-billing") {
      foundItemIndex = allLists.findIndex((item) => item.link === "/settings");
    } else if (location.pathname === "/user-management") {
      foundItemIndex = allLists.findIndex((item) => item.link === "/settings");
    } else {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === location.pathname
      );
    }

    return foundItemIndex !== -1 ? foundItemIndex : null;
  };

  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    determineSelectedIndex
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleStay = () => {
    setOpen(false);
    setNextLocation(null);
  };

  const handleLeave = () => {
    setOpen(false);
    if (nextLocation) {
      navigate(nextLocation);
    }
  };

  const handleListItemClick = async (index: number, link: string) => {
    if (link !== "/docs/introduction" && link !== "/support") {
      setSelectedIndex(index);
    }

    if (link === "/plan-and-billing" || link === "/user-management") {
      setSelectedIndex(4);
    }

    if (link === "/docs/introduction") {
      window.open(link, "_blank");
      return;
    }

    if (link === "/support") {
      window.open("https://support-nms.slack.com/", "_blank");
      return;
    }

    if (
      location.pathname === "/configurator" &&
      link !== "/configurator" &&
      stackState !== StackState.NONE &&
      stackState !== StackState.FINISHED
    ) {
      setNextLocation(link);
      setSelectedIndex(1);
      setOpen(true);
      return;
    }

    if (link === "/login") {
      await logout();
    }

    navigate(link);
  };

  return (
    <>
      {isLoggedIn() && !location.pathname.includes("docs") && (
        <>
          <MediaQuery maxWidth={1224}>
            <AppBar
              className={classes.navbar}
              position="fixed"
              sx={{ top: "auto", bottom: 0 }}
            >
              <Toolbar className={classes.toolbar}>
                <IconButton
                  color="inherit"
                  className={`${classes.listItem} ${
                    selectedIndex === 0 ? classes.selected : ""
                  }`}
                  onClick={() => handleListItemClick(0, "/configurator")}
                >
                  <img
                    src="/images/sidebar-configurator.png"
                    alt="configurator"
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  className={`${classes.listItem} ${
                    selectedIndex === 1 ? classes.selected : ""
                  }`}
                  onClick={() => handleListItemClick(1, "/dashboard-store")}
                >
                  <img
                    src="/images/sidebar-dashboard-store.png"
                    alt="dashboard-store"
                  />
                </IconButton>
                <IconButton
                  color="inherit"
                  className={`${classes.listItem} ${
                    selectedIndex === 2 ? classes.selected : ""
                  }`}
                  onClick={() => handleListItemClick(2, "/home")}
                >
                  <img src="/images/sidebar-home.png" alt="home" />
                </IconButton>
                <IconButton
                  color="inherit"
                  className={`${classes.listItem} ${
                    selectedIndex === 3 ? classes.selected : ""
                  }`}
                  onClick={() => handleListItemClick(3, "/alert-store")}
                >
                  <img
                    src="/images/sidebar-alert-store.png"
                    alt="alert-store"
                  />
                </IconButton>
                <div>
                  <IconButton
                    color="inherit"
                    className={`${classes.listItem} ${
                      selectedIndex === 4 ? classes.selected : ""
                    }`}
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    <img src="/images/sidebar-settings.png" alt="settings" />
                  </IconButton>
                  {isMenuOpen && (
                    <div className={classes.extendedMenu}>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          toggleMenu();
                          handleListItemClick(4, "/user-management");
                        }}
                      >
                        <img
                          src="/images/sidebar-user-management.png"
                          alt="user-management"
                        />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          toggleMenu();
                          handleListItemClick(4, "/plan-and-billing");
                        }}
                      >
                        <img
                          src="/images/sidebar-plan-and-billing.png"
                          alt="plan-and-billing"
                        />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          toggleMenu();
                          handleListItemClick(4, "/docs/introduction");
                        }}
                      >
                        <img
                          src="/images/sidebar-documentation.png"
                          alt="documentation"
                        />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          toggleMenu();
                          handleListItemClick(4, "/support");
                        }}
                      >
                        <img src="/images/sidebar-support.png" alt="support" />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          toggleMenu();
                          handleListItemClick(4, "/settings");
                        }}
                      >
                        <img
                          src="/images/sidebar-settings.png"
                          alt="settings"
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </Toolbar>
            </AppBar>

            <Dialog open={open} onClose={handleStay}>
              <DialogTitle>Leave Configurator?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have an unsaved configuration. Are you sure you want to
                  leave?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.stayButton}
                  onClick={handleStay}
                  color="primary"
                >
                  Stay
                </Button>
                <Button
                  className={classes.leaveButton}
                  onClick={handleLeave}
                  color="secondary"
                  autoFocus
                >
                  Leave
                </Button>
              </DialogActions>
            </Dialog>
          </MediaQuery>
        </>
      )}
    </>
  );
};
