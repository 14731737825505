import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { customFetch } from "../../utils/axios";
import { useSidebar } from "../../shared/context/SidebarContext";
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { EmptyUser } from "./empty-user/EmptyUser";
import { UserTable } from "./user-table/UserTable";
import { CreateUser } from "./create-user/CreateUser";
import { EditUser } from "./edit-user/EditUser";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: "0 0 5.6vh 2.3vw",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    width: "18%",
    height: "4.6vh",
    marginRight: "6.3vw",
    justifyContent: "space-around",
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "1vh 5vw",
      justifyContent: "center",
    },
  },
  buttonBlackText: {
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "7.4vh",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
}));

interface UserManagementPageProps {}

export const UserManagementPage: React.FC<UserManagementPageProps> = () => {
  const { classes } = useStyles();
  const { isSidebarOpen } = useSidebar();

  const [user, setUser] = useState<any[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState<{
    user: string;
    role: string;
    twofa: boolean;
  }>({ user: "", role: "", twofa: false });
  const [state, setState] = useState<"none" | "create" | "edit">("none");
  const [loading, setLoading] = useState<boolean>(true);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = "User Management | NMS";
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await customFetch.get(
        "grafana/subUserManagement/ListAll"
      );

      const fetchedUser = response.data.map(
        (user: {
          email: string;
          role: string;
          twoFactorAuthentication: boolean;
        }) => {
          return {
            user: user.email,
            role: user.role.replace(/^Customer/, ""),
            twofa: user.twoFactorAuthentication,
          };
        }
      );

      setUser(fetchedUser);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteUser = async (selectedUser: { user: string; role: string }) => {
    try {
      setDeleteLoading(true);
      const response = await customFetch.delete(
        `grafana/subUserManagement/Remove`,
        {
          data: { email: selectedUser.user },
        }
      );

      if (response.status === 200) {
        setOpenSnackbar(true);
        setError(false);
        setSnackbarMessage("User was deleted successfully.");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setError(true);
      setSnackbarMessage("Something went wrong while deleting the user.");
      console.error("Error fetching data:", error);
    } finally {
      fetchData();
      setDeleteLoading(false);
    }
  };

  const onEdit = (selectedUser: {
    user: string;
    role: string;
    twofa: boolean;
  }) => {
    setSelectedUser(selectedUser);
    setState("edit");
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "24%" : "8%",
          }}
        >
          <Sidebar></Sidebar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            <Box className={classes.snackbar}>
              {!error && (
                <img
                  src="/images/snackbar.png"
                  alt="snackbar"
                  width="35px"
                ></img>
              )}
              {error && (
                <img src="/images/error.png" alt="snackbar" width="35px"></img>
              )}
              {snackbarMessage}
            </Box>
          </Snackbar>
          <Box className={classes.titleContainer}>
            <Typography variant="h1" className={classes.title}>
              User Management
            </Typography>
            {state === "none" && user.length > 0 && (
              <Button
                className={classes.buttonBlack}
                startIcon={<img src="/images/button-add.png" alt="add" />}
                onClick={() => setState("create")}
              >
                <Typography
                  variant="subtitle"
                  className={classes.buttonBlackText}
                >
                  Create User
                </Typography>
              </Button>
            )}
          </Box>
          {(loading || createLoading || deleteLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {state === "edit" && !loading && (
            <EditUser
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setState={setState}
              setLoading={setEditLoading}
              fetchData={fetchData}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></EditUser>
          )}
          {state === "create" && !loading && (
            <CreateUser
              setState={setState}
              setLoading={setCreateLoading}
              fetchData={fetchData}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></CreateUser>
          )}
          {state === "none" &&
            !loading &&
            !createLoading &&
            !deleteLoading &&
            !editLoading &&
            (!user.length ? (
              <EmptyUser onClick={() => setState("create")}></EmptyUser>
            ) : (
              <UserTable
                user={user}
                onEdit={onEdit}
                onDelete={deleteUser}
              ></UserTable>
            ))}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
          }}
        >
          <MobileHeader title="User Management"></MobileHeader>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            <Box className={classes.snackbar}>
              {!error && (
                <img
                  src="/images/snackbar.png"
                  alt="snackbar"
                  width="35px"
                ></img>
              )}
              {error && (
                <img src="/images/error.png" alt="snackbar" width="35px"></img>
              )}
              {snackbarMessage}
            </Box>
          </Snackbar>
          {(loading || createLoading || deleteLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {state === "edit" && !loading && (
            <EditUser
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              setState={setState}
              setLoading={setEditLoading}
              fetchData={fetchData}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></EditUser>
          )}
          {state === "create" && !loading && (
            <CreateUser
              setState={setState}
              setLoading={setCreateLoading}
              fetchData={fetchData}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></CreateUser>
          )}
          {state === "none" &&
            !loading &&
            !createLoading &&
            !deleteLoading &&
            !editLoading &&
            (!user.length ? (
              <EmptyUser onClick={() => setState("create")}></EmptyUser>
            ) : (
              <>
                <UserTable
                  user={user}
                  onEdit={onEdit}
                  onDelete={deleteUser}
                ></UserTable>
                <Button
                  className={classes.buttonBlack}
                  startIcon={<img src="/images/button-add.png" alt="add" />}
                  onClick={() => setState("create")}
                >
                  <Typography
                    variant="subtitle"
                    className={classes.buttonBlackText}
                  >
                    Create User
                  </Typography>
                </Button>
              </>
            ))}
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
