import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CommonLayout } from "../../components/common-layout/CommonLayout";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MediaQuery from 'react-responsive';

const useStyles = makeStyles()((theme) => ({
  textField: {
    ...theme.typography.subtitle,
  },
  link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.light,
    margin: "1.9vh auto 3.2vh",
  },
  icons: {
    display: "flex",
    margin: "0 20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    ...theme.typography.body1,
    marginBottom: "5.5vh",
    borderRadius: "15px",
    [`@media screen and (max-width: 1600px)`]: {
      marginBottom: "3vh",
    },
  },
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
}));

interface LoginProps {}

export const LoginPage: React.FC<LoginProps> = () => {
  const { classes } = useStyles();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { isLoggedIn, login, errorMessage, changedPassword } = useAuth();

  useEffect(() => {
    document.title = "Sign In | NMS";
    if (changedPassword) {
      setOpen(true);
    }
    if (isLoggedIn()) {
      navigate("/home");
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("role");
    }
  }, [changedPassword, isLoggedIn, navigate]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    login({ email, password });
  };

  return (
    <>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(!open)}
      >
        <Box className={classes.snackbar}>
          {<img src="/images/snackbar.png" alt="snackbar" width="35px"></img>}
          Password has been successfully changed.
        </Box>
      </Snackbar> */}
      <CommonLayout title="Sign In" desc="Welcome back to NMS.">
        <form onSubmit={handleSubmit}>
          <TextField
            InputProps={{ classes: { input: classes.textField } }}
            variant="standard"
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="off"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="off"
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() => setShowPassword(!showPassword)}
                      alt="visible"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() => setShowPassword(!showPassword)}
                      alt="invisible"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography
            className={classes.link}
            component="a"
            variant="body1"
            href="/forgot-password"
          >
            Forgot Password?
          </Typography>
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Log In
          </Button>
        </form>
      </CommonLayout>
    </>
  );
};
