import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  IconButton,
  MenuItem,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  Button,
  TableRow,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    border: "none",
    boxShadow: "none",
    margin: "0 6.3vw 0 7.3vw",
    width: "auto",
    overflow: "unset",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 0",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 0.8vw",
    width: "94%",
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
    },
  },
  headerLeft: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    borderRadius: "2.1vh 0 0 1.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      borderRadius: "2.1vh 0 0 2.1vh",
      padding: "6px 16px",
    },
  },
  headerMid: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    [`@media screen and (max-width: 1224px)`]: {
      padding: "6px 16px",
    },
  },
  headerRight: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    borderRadius: "0 1.2vw 2.1vh 0",
    [`@media screen and (max-width: 1224px)`]: {
      borderRadius: "0 2.1vh 2.1vh 0",
      padding: "6px 16px",
    },
  },
  tableRow: {
    height: "5.1vh",
  },
  cellLeft: {
    ...theme.typography.body1,
    borderTop: `0.1vw solid ${theme.palette.background2}`,
    borderLeft: `0.1vw solid ${theme.palette.background2}`,
    borderBottom: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "2.1vh 0 0 1.2vw",
    padding: "0 0.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      borderRadius: "2.1vh 0 0 2.1vh",
      maxWidth: "30vw",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      padding: "0 0 0 3vw",
    },
  },
  cellMid: {
    ...theme.typography.body1,
    borderTop: `0.1vw solid ${theme.palette.background2}`,
    borderBottom: `0.1vw solid ${theme.palette.background2}`,
    padding: "0 0.8vw",
  },
  cellRight: {
    ...theme.typography.body1,
    borderTop: `0.1vw solid ${theme.palette.background2}`,
    borderRight: `0.1vw solid ${theme.palette.background2}`,
    borderBottom: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "0 1.2vw 2.1vh 0",
    padding: "0 0.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      borderRadius: "0 2.1vh 2.1vh 0",
    },
  },
  menu: {
    backgroundColor: "#E8E9F3D",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "3.9vh",
    width: "100%",
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  buttonWhite: {
    background: theme.palette.button.light,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "3.9vh",
    border: `0.1vw solid ${theme.palette.button.lightText}`,
    width: "100%",
    "&:hover": {
      border: "none",
      "& span": {
        color: "white",
      },
      "& span svg path": {
        stroke: "white !important",
      },
    },
    "& span svg path": {
      stroke: "black !important",
    },
  },
  buttonWhiteText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.lightText,
    textTransform: "none",

    "&:hover": {
      stroke: "white !important",
    },
  },
  buttonOutlined: {
    border: "0.1vw solid white",
    width: "30%",
    height: "4.6vh",
    borderRadius: "15px",
    margin: "1vw",
    "&:hover": {
      border: "none",
    }
  },
  buttonBlackDialog: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "4.6vh",
    width: "30%",
    margin: "1vw",
  },
  dialog: {
    height: "25vh",
    width: "46.9vw",
  },
  dialogText: {
    background: theme.palette.primary.light,
    color: "white !important",
    margin: "2.6vw",
    textAlign: "center",
  },
  dialogContent: {
    overflow: "hidden",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.primary.light,
    color: "white !important",
  },
  icon: {
    position: "absolute",
    top: "1.4vh",
    right: "0.8vw",
    height: "4.6vh",
    width: "4.6vh",
  },
}));

interface StackTableProps {
  stacks: any[];
  onEdit: (name: string, id: string) => void;
  onDeploy: (name: string, id: string) => void;
  onDelete: (id: string) => void;
}

export const StackTable: React.FC<StackTableProps> = ({
  stacks,
  onEdit,
  onDelete,
  onDeploy,
}) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentMenuStack, setCurrentMenuStack] = useState<any | null>(null);
  const [selectedStack, setSelectedStack] = useState<{
    hostName: string;
    id: string;
  }>();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = (hostName: string, id: string) => {
    setSelectedStack({ hostName: hostName, id: id });
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    currentStack: any
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenuStack(currentStack);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenuStack(null);
  };

  const formatDate = (dateString: string) => {
    const parsedDate = new Date(dateString);

    const day = String(parsedDate.getUTCDate()).padStart(2, "0");
    const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0");
    const year = parsedDate.getUTCFullYear();

    return <span>{`${day} / ${month} / ${year}`}</span>;
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Dialog
        PaperProps={{
          style: {
            background: "#6D7A8D",
            borderRadius: "23px",
            height: "fit-content",
            width: "42vw",
            maxWidth: "42vw",
          },
        }}
        open={open}
        onClose={handleCloseDialog}
      >
        <img
          src="/images/info-box-close.png"
          alt="close"
          onClick={handleCloseDialog}
          className={classes.icon}
        />
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            <Typography variant="subtitle">
              Are you sure that you want to delete this host configuration?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonOutlined}
            variant="outlined"
            onClick={handleCloseDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={() => {
              onDelete(selectedStack!.id);
              handleCloseDialog();
            }}
            className={classes.buttonBlackDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell align="center" className={classes.headerLeft}>
              Host Name
            </TableCell>
            <TableCell align="center" className={classes.headerMid}>
              Last Modified
            </TableCell>
            <TableCell className={classes.headerRight} />
          </TableRow>
        </TableHead>
        <TableBody>
          {stacks.map((stack, index) => (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.cellLeft} align="center">
                {stack.hostName}
              </TableCell>
              <TableCell className={classes.cellMid} align="center">
                {formatDate(stack.lastModified)}
              </TableCell>
              <TableCell className={classes.cellRight} align="center">
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, stack)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.menu}
                  slotProps={{
                    paper: {
                      style: {
                        width: "260px",
                        border: "none",
                        boxShadow: "none",
                        backgroundColor: "#E8E9F3",
                        borderRadius: "23px",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Button
                      className={classes.buttonBlack}
                      startIcon={
                        <img src="/images/button-deploy.png" alt="add" />
                      }
                      onClick={() =>
                        onDeploy(currentMenuStack.hostName, currentMenuStack.id)
                      }
                    >
                      <Typography
                        variant="subtitle"
                        className={classes.buttonBlackText}
                      >
                        Deploy
                      </Typography>
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      className={classes.buttonBlack}
                      startIcon={
                        <img src="/images/button-edit.png" alt="add" />
                      }
                      onClick={() => {
                        onEdit(currentMenuStack.hostName, currentMenuStack.id);
                      }}
                    >
                      <Typography
                        variant="subtitle"
                        className={classes.buttonBlackText}
                      >
                        Edit
                      </Typography>
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      className={classes.buttonWhite}
                      startIcon={
                        <svg
                          width="23"
                          height="25"
                          viewBox="0 0 23 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 6.14307H21.5714"
                            stroke="white"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.57153 6.14307H19.0001V21.5716C19.0001 22.0263 18.8195 22.4623 18.498 22.7838C18.1765 23.1053 17.7405 23.2859 17.2858 23.2859H5.28582C4.83116 23.2859 4.39513 23.1053 4.07364 22.7838C3.75214 22.4623 3.57153 22.0263 3.57153 21.5716V6.14307Z"
                            stroke="white"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 6.14286V5.28571C7 4.14907 7.45153 3.05898 8.25526 2.25526C9.05898 1.45153 10.1491 1 11.2857 1C12.4224 1 13.5124 1.45153 14.3162 2.25526C15.1199 3.05898 15.5714 4.14907 15.5714 5.28571V6.14286"
                            stroke="white"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.71436 11.2876V18.1482"
                            stroke="white"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.8572 11.2876V18.1482"
                            stroke="white"
                            stroke-width="1.71429"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      onClick={() =>
                        handleOpenDialog(
                          currentMenuStack.hostName,
                          currentMenuStack.id
                        )
                      }
                    >
                      <Typography
                        variant="subtitle"
                        className={classes.buttonWhiteText}
                      >
                        Delete
                      </Typography>
                    </Button>
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
