import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { customFetch } from "../../utils/axios";
import { DashboardItem } from "./dashboard-item/DashbordItem";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { useSidebar } from "../../shared/context/SidebarContext";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: "7.4vh 0 5.6vh 2.3vw",
  },
  content: {
    margin: "0 0 3.7vh 4.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 7.3vw 0",
    },
  },
  text: {
    margin: "0 5.2vw 3.7vh 0",
    [`@media screen and (max-width: 1224px)`]: {
      color: theme.palette.primary.light,
      margin: "0",
      fontSize: "13px",
    },
  },
  selection: {
    display: "flex",
    margin: "3.7vh 0",
    gap: "0.5vw",
  },
  scrollBox: {
    display: "flex",
    gap: "1vw",
    overflowY: "auto",
    width: "100%",
    paddingBottom: "1.5vh",
    scrollbarWidth: "auto" /* For Firefox */,
    scrollbarColor: "#888 #f1f1f1" /* For Firefox */,
    "&::-webkit-scrollbar": {
      height: "50px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    [`@media screen and (max-width: 1224px)`]: {
      flexDirection: "column",
      gap: "2vh",
    },
  },
  categoryButton: {
    ...theme.typography.subtitle,
    borderRadius: "23px",
    padding: "0.5vh 1vw",
    color: theme.palette.primary.light,
    "&:hover": {
      cursor: "pointer",
      background: "#E8E9F341 !important",
      color: theme.palette.primary.dark,
    },
  },
  selectedCategoryButton: {
    ...theme.typography.subtitle,
    borderRadius: "23px",
    padding: "0.5vh 1vw",
    background: "#E8E9F3",
    "&:hover": {
      cursor: "pointer",
      background: "#E8E9F3 !important",
      color: theme.palette.primary.dark,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  link: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
  hidden: {
    display: "none",
  },
}));

enum Categories {
  CHAINLINK = "chainlink",
  RPC = "rpc",
  EXTERNAL = "external-adapter",
  OTHERS = "others",
  ALL = "all",
}

export type Dashboard = {
  id: number;
  name: string;
  category: Categories;
  description: string;
  isInstalled: boolean;
};

interface DashboardStorePageProps {}

export const DashboardStorePage: React.FC<DashboardStorePageProps> = () => {
  const { classes } = useStyles();
  const [category, setCategory] = useState<Categories>(
    () =>
      (localStorage.getItem("selectedCategory") as Categories) || Categories.ALL
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [visibleDashboards, setVisibleDashboards] = useState<Dashboard[]>();
  const { isSidebarOpen } = useSidebar();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.title = "Dashboard Store | NMS";
    fetchData();
  }, []);

  const resetScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0;
    }
  };

  const fetchData = async () => {
    try {
      const response = await customFetch.get("grafana/dashboards");

      const dashboards = response.data.map((dashboard: any) => {
        return {
          id: dashboard.dashboardId,
          name: dashboard.name,
          category: dashboard.category,
          description: dashboard.description,
          isInstalled: dashboard.isInstalled,
        };
      });

      const sortedDashboards = dashboards.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );

      setVisibleDashboards([...sortedDashboards]);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const selectCategory = (category: Categories) => {
    setCategory(category);
    resetScroll();
    localStorage.setItem("selectedCategory", category);
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "24%" : "8%",
          }}
        >
          <Sidebar></Sidebar>
          <Typography variant="h1" className={classes.title}>
            Dashboard Store
          </Typography>

          {(loading || imagesLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!loading && !imagesLoading ? "" : classes.hidden}>
            <Box className={classes.content}>
              <Box className={classes.text}>
                <Typography variant="subtitle">
                  Your{" "}
                  <a
                    href={process.env.REACT_APP_GRAFANA}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Dashboard Platform
                  </a>{" "}
                  organization comes with a default home dashboard that displays
                  your data collection stack and helpful overview panels. Here
                  you can browse and manage additional dashboards tailored to
                  the various services. Once you click “Install”, the dashboard
                  will be added to the Dashboard Platform and you will benefit
                  from constant background updates.
                </Typography>
              </Box>
              <Box className={classes.selection}>
                <Button
                  className={
                    category === Categories.CHAINLINK
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.CHAINLINK)}
                >
                  Chainlink
                </Button>
                <Button
                  className={
                    category === Categories.RPC
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.RPC)}
                >
                  RPC
                </Button>
                <Button
                  className={
                    category === Categories.EXTERNAL
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.EXTERNAL)}
                >
                  EA
                </Button>
                <Button
                  className={
                    category === Categories.OTHERS
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.OTHERS)}
                >
                  Others
                </Button>
                <Button
                  className={
                    category === Categories.ALL
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.ALL)}
                >
                  All
                </Button>
              </Box>
              <Box className={classes.scrollBox} ref={scrollRef}>
                {visibleDashboards?.map((dashboard) => {
                  return (
                    (dashboard.category === category ||
                      category === Categories.ALL) && (
                      <DashboardItem
                        setLoading={setImagesLoading}
                        key={dashboard.id}
                        dashboard={dashboard}
                        fetchData={fetchData}
                      ></DashboardItem>
                    )
                  );
                })}
              </Box>
            </Box>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
            marginBottom: "56px",
          }}
        >
          <MobileHeader title="Dashboard Store"></MobileHeader>
          {(loading || imagesLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!loading && !imagesLoading ? "" : classes.hidden}>
            <Box className={classes.content}>
              <Box className={classes.text}>
                <Typography variant="subtitle">
                  Your{" "}
                  <a
                    href={process.env.REACT_APP_GRAFANA}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    Dashboard Platform
                  </a>{" "}
                  organization comes with a default home dashboard that displays
                  your data collection stack and helpful overview panels. Here
                  you can browse and manage additional dashboards tailored to
                  the various services. Once you click “Install”, the dashboard
                  will be added to the Dashboard Platform and you will benefit
                  from constant background updates.
                </Typography>
              </Box>
              <Box className={classes.selection}>
                <Button
                  className={
                    category === Categories.CHAINLINK
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.CHAINLINK)}
                >
                  Chainlink
                </Button>
                <Button
                  className={
                    category === Categories.RPC
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.RPC)}
                >
                  RPC
                </Button>
                <Button
                  className={
                    category === Categories.EXTERNAL
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.EXTERNAL)}
                >
                  EA
                </Button>
                <Button
                  className={
                    category === Categories.OTHERS
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.OTHERS)}
                >
                  Others
                </Button>
                <Button
                  className={
                    category === Categories.ALL
                      ? classes.selectedCategoryButton
                      : classes.categoryButton
                  }
                  color="primary"
                  onClick={() => selectCategory(Categories.ALL)}
                >
                  All
                </Button>
              </Box>
              <Box className={classes.scrollBox}>
                {visibleDashboards?.map((dashboard) => {
                  return (
                    (dashboard.category === category ||
                      category === Categories.ALL) && (
                      <DashboardItem
                        setLoading={setImagesLoading}
                        key={dashboard.id}
                        dashboard={dashboard}
                        fetchData={fetchData}
                      ></DashboardItem>
                    )
                  );
                })}
              </Box>
            </Box>
          </div>
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
