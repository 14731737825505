import React, { useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";
import { Snackbar } from "@mui/material";

const useStyles = makeStyles<{ height: string | undefined }>()(
  (theme, { height }) => ({
    snackbar: {
      ...theme.typography.subtitle,
      display: "flex",
      background: "#6D7A8D",
      borderRadius: "23px",
      padding: "1.9vh 2.1vw",
      width: "23.4vw",
      gap: "2.8vh",
      color: "white",
      alignItems: "center",
      [`@media screen and (max-width: 1224px)`]: {
        width: "96%",
        justifyContent: "center",
      },
    },
    codeBlock: {
      border: `0.1vw solid ${theme.palette.background2}`,
      borderRadius: "23px",
      position: "relative",
      marginTop: "4.6vh",
      padding: "2.1vw",
      height: "auto",
      [`@media screen and (max-width: 1600px)`]: {
        marginTop: "30px",
        padding: "30px",
      },
    },
    textarea: {
      ...theme.typography.subtitle,
      width: "80%",
      resize: "none",
      border: "none",
      background: "none",
      height: "5.6vh",
      maxHeight: height || "500px",
      minHeight: height || "34px",
      [`@media screen and (min-width: 900px) and (max-width: 1600px)`]: {
        maxHeight: height || "400px",
        minHeight: height || "34px",
      },
      [`@media screen and (max-width: 1224px)`]: {
        maxHeight: height || "12vh",
        minHeight: height || "11vh",
      },
      [`@media screen and (max-width: 500px)`]: {
        maxHeight: height || "17vh",
        minHeight: height || "7vh",
      },
    },
    copy: {
      position: "absolute",
      top: "35px",
      right: "35px",
      "&:hover": { cursor: "pointer" },
      [`@media screen and (max-width: 1600px)`]: {
        height: height ? "100%" : "",
      },
    },
  })
);

interface CodeBlockProps {
  code: string;
  height?: string;
}

export const CodeBlock: React.FC<CodeBlockProps> = ({ code, height }) => {
  const { classes } = useStyles({ height });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    setSnackbarMessage("Copied to clipboard.");
    setOpenSnackbar(true);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(!openSnackbar)}
      >
        <Box className={classes.snackbar}>
          <img src="/images/snackbar.png" alt="snackbar" width="35px"></img>
          {snackbarMessage}
        </Box>
      </Snackbar>
      <Box className={classes.codeBlock}>
        <textarea
          ref={textAreaRef}
          value={code}
          readOnly
          className={classes.textarea}
        />
        <Box className={classes.copy}>
          <img
            src="/images/code-block-copy.png"
            alt="edit"
            onClick={copyToClipboard}
          />
        </Box>
      </Box>
    </>
  );
};
