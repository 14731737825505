import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../../core/auth/hooks/useAuth";
import QRCode from "qrcode.react";

const useStyles = makeStyles()((theme) => ({
  twofa: {
    margin: "0 7.3vw 3.7vh",
    display: "flex",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  twofaTitle: {
    marginBottom: "1.4vh",
  },
  qrBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-evenly",
    gap: "0.9vh",
    marginTop: "2.8vh",
    border: `0.1vh solid ${theme.palette.background2}`,
    borderRadius: "23px",
    height: "fit-content",
    padding: "2vh 1vw",
  },
  textfield: {
    display: "flex",
    margin: "2.8vh 0 3.7vh 0",
  },
  label: {
    width: "13vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  input: {
    width: "26vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBox: {
    marginTop: "1.9vh",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  dialog: {
    background: "#6D7A8D",
    borderRadius: "23px",
    maxWidth: "39.1vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "80vw",
    },
  },
  dialogText: {
    background: theme.palette.primary.light,
    color: "white !important",
    margin: "4.6vh 2.6vw",
    textAlign: "center",
  },
  dialogContent: {
    overflow: "hidden",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
    background: theme.palette.primary.light,
    color: "white !important",
    [`@media screen and (max-width: 1224px)`]: {
      justifyContent: "center",
    },
  },
  buttonOutlined: {
    border: "0.1vh solid white",
    width: "30%",
    borderRadius: "15px",
    height: "4.6vh",
    margin: "1.9vh",
  },
  buttonBlackDialog: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "4.6vh",
    width: "30%",
    margin: "1.9vh",
    [`@media screen and (max-width: 1224px)`]: {
      textAlign: "center",
      width: "40%",
    },
  },
}));

interface EnableTwofaProps {
  setTwofa: (twofa: boolean) => void;
  setOpen: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
  setError: (open: boolean) => void;
}

export const EnableTwofa: React.FC<EnableTwofaProps> = ({
  setTwofa,
  setOpen,
  setSnackbarMessage,
  setError,
}) => {
  const { classes } = useStyles();
  const { getTwofa, enableTwofa, setLoading } = useAuth();
  const [data, setData] = useState<{ key: string; dataUrl: string }>();
  const [authKey, setAuthKey] = useState<string>("");
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  let enableResponse: any;

  useEffect(() => {
    setLoading(true);
    async function fetchTwoFactorData() {
      const response = await getTwofa();
      setData(response);
    }

    fetchTwoFactorData();
    setLoading(false);
  }, [getTwofa, setLoading]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClick = () => {
    async function fetchEnableTwofa() {
      enableResponse = await enableTwofa(authKey);

      if (enableResponse.response && enableResponse.response.status === 400) {
        setOpen(true);
        setError(true);
        setSnackbarMessage("Authentication code is not correct.");
      } else {
        setOpen(true);
        setError(false);
        setOpenDialog(true);
        setBackupCodes(enableResponse);
        setSnackbarMessage("2FA has been successfully enabled.");
      }
    }

    fetchEnableTwofa();
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            background: "#6D7A8D",
            borderRadius: "23px",
            
            width: {
              xs: "85vw",
              sm: "75vw",
              md: "39.1vw",
            },
          },
        }}
        open={openDialog}
      >
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            <Typography variant="subtitle">
              2FA enabled successfully. Please store these backup codes
              securely, you will need them in case you lose access to your
              device.
            </Typography>
            <Grid container spacing={4} justifyContent="center" mt={3}>
              {backupCodes.map((code, index) => (
                <Grid item key={index} xs={4}>
                  <Typography variant="subtitle" align="center">
                    {code}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={() => {
              handleCloseDialog();
              setTwofa(false);
            }}
            className={classes.buttonBlackDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              I Understand
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.twofa}>
        <Box className={classes.flex}>
          <Typography variant="h2" className={classes.twofaTitle}>
            Enable Two-Factor Authentication
          </Typography>
          <Typography variant="subtitle">
            Please scan the below QR code with your authenticator app and enter
            the code generated by the app below.
          </Typography>
          {data && (
            <Box className={classes.qrBox}>
              <QRCode value={data.dataUrl} size={250} />
              <Typography variant="subtitle">{data.key}</Typography>
            </Box>
          )}
          <Box className={classes.textfield}>
            <Typography className={classes.label} variant="subtitle">
              Authentication Code
            </Typography>
            <TextField
              className={classes.input}
              variant="standard"
              type="text"
              onChange={(e) => setAuthKey(e.target.value)}
              value={authKey}
            ></TextField>
          </Box>
        </Box>
        <Box className={classes.buttonBox}>
          <Button className={classes.button} onClick={() => setTwofa(false)}>
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Back
            </Typography>
          </Button>
          <Button className={classes.button} onClick={() => onClick()}>
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Verify
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};
