import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { CurrentService } from "../../../../utils/types";
import { customFetch } from "../../../../utils/axios";
import { ServiceState } from "../../Configurator";

const useStyles = makeStyles()((theme) => ({
  serviceTypeSelection: {
    margin: "0 7.3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    flex: 1,
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 0",
    },
  },
  inputProps: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.dark,
  },
  textField: {
    width: "41.7vw",
  },
  title: {
    marginBottom: "2.3vh",
  },
  outerServiceTypeBox: {
    display: "flex",
    gap: "2.1vw",
    marginTop: "8.3vh",
    overflow: "auto",
    paddingBottom: "1.9vh",
    [`@media screen and (max-width: 1224px)`]: {
      overflow: "visible",
      flexWrap: "wrap",
      margin: "5vh 6vw 0",
      gap: "2vh",
      justifyContent: "space-between"
    },
  },
  innerServiceTypeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": { cursor: "pointer" },
  },
  fieldLabel: {
    margin: "10px 0",
  },
  imgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.9vh",
    width: "10.4vw",
    height: "10.4vw",
    margin: "0 0.5vw",
    border: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (max-width: 1224px)`]: {
      width: "35vw",
      height: "35vw",
      gap: "1vh",
    },
  },
  selectedImgBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.9vh",
    width: "9.5vw",
    height: "9.5vw",
    margin: "0 0.5vw",
    border: `0.5vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (max-width: 1224px)`]: {
      width: "calc(35vw - 10px)",
      height: "calc(35vw - 10px)",
      border: `6px solid ${theme.palette.background2}`,
      gap: "1vh",
    },
  },
  serviceTypeImg: {
    height: "100px",
    [`@media screen and (max-width: 1224px)`]: {
      height: "9vh",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3.7vh",
  },
  loading: {
    position: "relative",
    top: "50%",
    left: "50%",
  },
  buttonBack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface ServiceTypeSelectionProps {
  onBack: (step?: number) => void;
  onNext: (step: number) => void;
  currentService: CurrentService;
  setCurrentService: React.Dispatch<React.SetStateAction<CurrentService>>;
  serviceState: ServiceState;
}

export const ServiceTypeSelection: React.FC<ServiceTypeSelectionProps> = ({
  onBack,
  onNext,
  currentService,
  setCurrentService,
  serviceState,
}) => {
  const { classes } = useStyles();
  const [serviceTypeList, setServiceTypeList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedServiceType, setSelectedServiceType] = useState<string>(
    currentService.name ?? ""
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("configurator/service-types");

      const transformedList = response.data.map((serviceType: any) => {
        return {
          id: serviceType.id,
          name: serviceType.name,
          label: serviceType.label,
        };
      });

      setServiceTypeList(transformedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const selectServiceType = (serviceTypeName: string) => {
    serviceTypeList.map((serviceType: any) => {
      if (serviceType.name === serviceTypeName) {
        let service = {
          id: 0,
          name: "",
        };
        if (serviceTypeName === "External Adapter") {
          service = {
            id: 13,
            name: "External Adapter",
          };
        }
        if (serviceTypeName === "Chainlink Node") {
          service = {
            id: 10,
            name: "Chainlink Node",
          };
        }

        setCurrentService({
          ...currentService,
          ...serviceType,
          service: {
            ...currentService,
            ...service,
          },
        });
      }
      return null;
    });

    setSelectedServiceType(serviceTypeName);
  };

  const validate = () => {
    const isAnyServiceTypeSelected = selectedServiceType !== "";

    if (isAnyServiceTypeSelected) {
      if (
        selectedServiceType === "Chainlink Node" ||
        selectedServiceType === "External Adapter"
      ) {
        onNext(4);
      } else {
        onNext(3);
      }
    }
  };

  return (
    <Box className={classes.serviceTypeSelection}>
      <Box>
        <Typography variant="h2" className={classes.title}>
          Select a service type
        </Typography>
        <Typography variant="subtitle">
          Now select the service type running on this specific machine that you
          want to monitor. After configuring it you can repeat this step in case
          several services are running on the same machine.
        </Typography>
        <Box className={classes.outerServiceTypeBox}>
          {loading && <CircularProgress className={classes.loading} />}
          {!loading &&
            serviceTypeList
              .sort((a: any, b: any) => a.id - b.id)
              .map((serviceType: any) => {
                return (
                  <Box
                    key={serviceType.name}
                    className={classes.innerServiceTypeBox}
                  >
                    <Box
                      className={
                        serviceType.name === selectedServiceType
                          ? classes.selectedImgBox
                          : classes.imgBox
                      }
                      onClick={() => selectServiceType(serviceType.name)}
                    >
                      <img
                        className={classes.serviceTypeImg}
                        src={`/images/service-type-${serviceType.label}.png`}
                        alt={`${serviceType.label}`}
                      />
                      <Typography variant="subtitle">
                        {serviceType.name}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
        </Box>
      </Box>
      <Box className={classes.buttonBox}>
        {(serviceState === ServiceState.NONE ||
          serviceState === ServiceState.ADD) && (
          <Button
            className={classes.buttonBack}
            onClick={() =>
              serviceState === ServiceState.NONE ? onBack() : onBack(5)
            }
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Back
            </Typography>
          </Button>
        )}
        <Button className={classes.buttonNext} onClick={validate}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
