import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Alert } from "../AlertStore";
import { customFetch } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles()((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "18vw",
    border: `0.1vh solid ${theme.palette.background2}`,
    borderRadius: "23px",
  },
  title: {
    margin: "2.3vh 2.1vw",
    display: "block",
    fontWeight: "bolder",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1.5vh 3.3vw",
    },
  },
  description: {
    margin: "2.3vh 2.1vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1.5vh 3.3vw",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.8vh 2.1vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1vh 3.3vw",
    },
  },
  button: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    height: "3.7vh",
    border: `1px solid ${theme.palette.background2}`,
    "&:hover": {
      color: "white",
      border: "none",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  install: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    height: "3.7vh",
    background: theme.palette.button.main,
    color: theme.palette.button.mainText,
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  isInstalled: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    height: "3.7vh",
    background: theme.palette.button.main,
    backgroundColor: "#DFE0EA",
    color: "grey",
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
}));

interface AlertItemProps {
  alert: Alert;
  fetchData: () => void;
}

export const AlertItem: React.FC<AlertItemProps> = ({ alert, fetchData }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setInstallLoading] = useState<boolean>(false);

  const install = async (id: number) => {
    try {
      setInstallLoading(true);
      await customFetch.post(`installAlerts?groupId=${id}`);

      fetchData();
      setInstallLoading(false);
    } catch (error) {}
  };

  const openDetails = async (id: number) => {
    navigate(`/alert-store/${id}`);
  };

  return (
    <Box className={classes.item}>
      <div>
        <Typography variant="subtitle" className={classes.title}>
          {alert.name}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {alert.description}
        </Typography>
      </div>
      <Box className={classes.buttonBox}>
        <Button
          className={classes.button}
          onClick={() => openDetails(alert.id)}
        >
          Details
        </Button>
        <Button
          disabled={alert.isInstalled}
          className={alert.isInstalled ? classes.isInstalled : classes.install}
          onClick={() => install(alert.id)}
        >
          {loading && (
            <CircularProgress size="23px" className={classes.loading} />
          )}
          {!loading && (alert.isInstalled ? "Installed" : "Install")}
        </Button>
      </Box>
    </Box>
  );
};
