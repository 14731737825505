import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";
import { Service, CurrentService } from "../../../../utils/types";
import { customFetch } from "../../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  serviceSelection: {
    margin: "0 7.3vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    flex: 1,
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 0",
    },
  },
  title: {
    marginBottom: "2.3vh",
  },
  serviceBox: {
    border: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "6.5vh",
    margin: "0.9vh 0",
    "&:hover": { cursor: "pointer" },
    [`@media screen and (max-width: 1224px)`]: {
      height: "5vh",
    },
  },
  selectedServiceBox: {
    border: `0.48vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "4.8vh",
    margin: "0.9vh 0",
    "&:hover": { cursor: "pointer" },
    [`@media screen and (max-width: 1224px)`]: {
      height: "calc(5vh - 6px)",
      border: `4px solid ${theme.palette.background2}`,
      fontSize: "15px",
    },
  },
  grid: {
    marginTop: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      marginTop: "1vh",
    },
  },
  loading: {
    display: "block",
    margin: "100px auto 0",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3.7vh",
  },
  buttonBack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "18%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "18%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface ServiceSelectionProps {
  onBack: () => void;
  onNext: () => void;
  currentService: CurrentService;
  setCurrentService: React.Dispatch<React.SetStateAction<CurrentService>>;
}

export const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  onBack,
  onNext,
  currentService,
  setCurrentService,
}) => {
  const { classes } = useStyles();
  const [serviceList, setServiceList] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedService, setSelectedService] = useState<string | undefined>(
    currentService.service.name ?? undefined
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await customFetch.get(
        `configurator/services?id=${currentService.id}`
      );

      const transformedList = response.data.map((node: any) => {
        return {
          id: node.id,
          name: node.name,
        };
      });

      setServiceList(transformedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const selectService = (serviceName: string) => {
    const selectedService = serviceList.find(
      (service) => service.name === serviceName
    );

    if (selectedService) {
      setCurrentService({
        id: currentService.id || 0,
        name: currentService.name || "",
        label: currentService.label || "",
        service: {
          id: selectedService.id || 0,
          name: selectedService.name || "",
          label: selectedService.label || "",
          serviceName: selectedService.serviceName || "",
          ip: selectedService.ip || "",
          port: selectedService.port || 0,
          path: selectedService.path || "",
          protocol: selectedService.protocol || "",
          network: selectedService.network || "",
        },
      });
    }
    setSelectedService(serviceName);
  };

  const chunkArray = (arr: string[], chunkSize: number) => {
    const results = [];
    while (arr.length) {
      results.push(arr.splice(0, chunkSize));
    }

    return results;
  };

  const columns = chunkArray(
    [
      ...serviceList.map((service) => {
        return service.name ?? "";
      }),
    ],
    5
  );

  const validate = () => {
    const isAnyServiceSelected = selectedService !== "";

    if (isAnyServiceSelected) {
      onNext();
    }
  };

  return (
    <Box className={classes.serviceSelection}>
      <Box>
        <Typography variant="h2" className={classes.title}>
          Select a service
        </Typography>
        <Typography variant="subtitle">
          {currentService.name === "RPC Node"
            ? "Please choose the type of RPC node you want to monitor from the list below."
            : "Please choose the type of service you want to monitor from the list below."}
        </Typography>
        {loading && <CircularProgress className={classes.loading} />}
        {!loading && (
          <Grid className={classes.grid} container spacing={2}>
            {columns.map((colNames, colIndex) => (
              <Grid item xs={serviceList.length < 5 ? 12 : serviceList.length > 10 ? 4 : 6} key={colIndex}>
                {colNames.map((name, rowIndex) => (
                  <Typography
                    variant="subtitle"
                    className={
                      name === selectedService
                        ? classes.selectedServiceBox
                        : classes.serviceBox
                    }
                    key={rowIndex}
                    onClick={() => selectService(name)}
                  >
                    {name}
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Box className={classes.buttonBox}>
        <Button className={classes.buttonBack} onClick={() => onBack()}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Back
          </Typography>
        </Button>
        <Button className={classes.buttonNext} onClick={validate}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
