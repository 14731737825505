import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography } from "@mui/material";
import { CodeBlock } from "../code-block/CodeBlock";

const useStyles = makeStyles()((theme) => ({
  stepTwo: {},
  title: {
    margin: "0 0 2.8vh",
  },
}));

interface StepTwoProps {}

export const StepTwo: React.FC<StepTwoProps> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.stepTwo}>
      <Typography variant="h2" className={classes.title}>
        Step 2
      </Typography>
      <Typography variant="subtitle">
        Copy, paste, and run the following command to create and open a new file
        named “nms-config.json”:
      </Typography>
      <CodeBlock code="touch ~/nms-config.json && nano ~/nms-config.json" />
      <br></br>
      <Typography variant="subtitle">
        If the file already exists from a previous deployment, the command will
        open it, but the old content will be displayed. In the next step, you'll
        replace the existing content with the new configuration.
      </Typography>
    </Box>
  );
};
