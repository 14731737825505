import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useAuth } from "../../../core/auth/hooks/useAuth";
import { customFetch } from "../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    margin: "0 7.3vw 2.3vh",
    width: "32.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "0 5vw 2.3vh",
      fontWeight: "1.5"
    },
  },
  textfield: {
    margin: "0 7.3vw 1.9vh",
    display: "flex",
    width: "32.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      marginBottom: "2.3vh",
      width: "85vw",
    },
  },
  label: {
    width: "9.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "30vw",
    },
  },
  icons: {
    display: "flex",
    gap: "1vw",
    margin: "0 1vw",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    width: "23.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "55vw",
    },
  },
}));

interface ApiCredentialsProps {
  setOpen: (open: boolean) => void;
  setIsLoading: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
}

export const ApiCredentials: React.FC<ApiCredentialsProps> = ({
  setSnackbarMessage,
  setOpen,
  setIsLoading,
}) => {
  const { classes } = useStyles();
  const { setLoading } = useAuth();

  const [showUser, setShowUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [apiCredentials, setApiCredentials] = useState<{
    user: string;
    password: string;
  }>({ user: "", password: "" });

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await customFetch.get("configurator/connection-data");
      setApiCredentials({
        user: response.data.apiUser,
        password: response.data.apiPassword,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Typography variant="h2" className={classes.subtitle}>
        Your API credentials
      </Typography>
      <Box className={classes.textfield}>
        <Typography className={classes.label} variant="subtitle">
          Username
        </Typography>
        <TextField
          className={classes.input}
          variant="standard"
          type={showUser ? "text" : "password"}
          value={apiCredentials.user}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.icons} position="end">
                {showUser ? (
                  <img
                    src="/images/textfield-visible.png"
                    onClick={() => setShowUser(!showUser)}
                    alt="visible"
                    width="20px"
                  />
                ) : (
                  <img
                    src="/images/textfield-invisible.png"
                    onClick={() => setShowUser(!showUser)}
                    alt="invisible"
                    width="20px"
                  />
                )}
                <img
                  src="/images/textfield-copy.png"
                  alt="copy"
                  width="20px"
                  onClick={() => {
                    copyToClipboard(apiCredentials.user);
                    setSnackbarMessage("Copied to clipboard.");
                    setOpen(true);
                  }}
                />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
      <Box className={classes.textfield}>
        <Typography className={classes.label} variant="subtitle">
          Password
        </Typography>
        <TextField
          className={classes.input}
          variant="standard"
          type={showPassword ? "text" : "password"}
          value={apiCredentials.password}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.icons} position="end">
                {showPassword ? (
                  <img
                    src="/images/textfield-visible.png"
                    onClick={() => setShowPassword(!showPassword)}
                    alt="visible"
                    width="20px"
                  />
                ) : (
                  <img
                    src="/images/textfield-invisible.png"
                    onClick={() => setShowPassword(!showPassword)}
                    alt="invisible"
                    width="20px"
                  />
                )}
                <img
                  src="/images/textfield-copy.png"
                  alt="copy"
                  width="20px"
                  onClick={() => {
                    copyToClipboard(apiCredentials.password);
                    setSnackbarMessage("Copied to clipboard.");
                    setOpen(true);
                  }}
                />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
    </>
  );
};
