import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../core/auth/hooks/useAuth";
import MediaQuery from "react-responsive";

const useStyles = makeStyles()((theme) => ({
  textfield: {
    margin: "0 7.3vw 20px",
    display: "flex",
    [`@media screen and (max-width: 1224px)`]: {
      marginBottom: "2.3vh",
      width: "85vw",
    },
  },
  icons: {
    display: "flex",
    gap: "1vw",
    margin: "0 1vw",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    width: "23.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "45vw",
    },
  },
  changePassword: {
    margin: "2.2vh 7.3vw 3.7vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "0 5vw 2.3vh",
      fontWeight: "1.5",
    },
  },
  changePasswordLabel: {
    width: "15.6vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  logo: {
    paddingRight: "0.8vw",
    width: "0.8vw",
    height: "0.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "2vw",
      height: "2vw",
      paddingRight: "1.5vw",
    },
  },
  list: {
    padding: "2.2vh 7.3vw 4.4vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    [`@media screen and (max-width: 1224px)`]: {
      padding: "0 7vw 2vh",
    },
  },
  listItemBox: {
    display: "flex",
    justifyContent: "space-around",
  },
  changePasswordButton: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    marginLeft: "2.6vw",
    minWidth: "13vw",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      marginLeft: "60vw",
    },
  },
  changePasswordButtonDisabled: {
    background: theme.palette.button.main,
    backgroundColor: "#DFE0EA",
    color: "grey",
    borderRadius: "15px",
    marginLeft: "2.6vw",
    minWidth: "13vw",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      marginLeft: "45vw",
      minWidth: "40vw",
    },
  },
  buttonBlackText: {
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  textField: {
    ...theme.typography.subtitle,
  },
  textfieldConfirm: {
    ...theme.typography.subtitle,
    margin: "0 7.3vw",
    display: "flex",
  },
}));

interface ChangePasswordProps {
  setOpen: (open: boolean) => void;
  setError: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
  setSnackbarMessage,
  setOpen,
  setError,
}) => {
  const { classes } = useStyles();
  const { changePassword } = useAuth();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const isValidLength = newPassword.length >= 8;
  const hasLowerCase = /[a-z]/.test(newPassword);
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const hasNumber = /[0-9]/.test(newPassword);
  const hasSymbol = /[!§$%&/()=?`´*'+~#_\-;:,.<>|\\{}\[\]]/.test(newPassword);

  const allValid =
    isValidLength &&
    hasLowerCase &&
    hasUpperCase &&
    hasNumber &&
    hasSymbol &&
    newPassword === confirmPassword;

  const onChangePassword = async () => {
    const response: any = await changePassword(
      oldPassword,
      newPassword,
      confirmPassword
    );

    if (response.status === 200) {
      setSnackbarMessage("Password has been successfully changed.");
      setError(false);
    } else if (response.message === "Request failed with status code 400") {
      setSnackbarMessage("Current Password was incorrect.");
      setError(true);
    } else {
      setSnackbarMessage("Something went wrong, please try again later.");
      setError(true);
    }
    setOpen(true);
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <Typography variant="h2" className={classes.changePassword}>
          Change Password
        </Typography>
        <Box className={classes.textfield}>
          <Typography
            className={classes.changePasswordLabel}
            variant="subtitle"
          >
            Current Password
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type={showOldPassword ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showOldPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      alt="visible"
                      width="20px"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      alt="invisible"
                      width="20px"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Box className={classes.textfield}>
          <Typography
            className={classes.changePasswordLabel}
            variant="subtitle"
          >
            New Password
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type={showNewPassword ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showNewPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      alt="visible"
                      width="20px"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      alt="invisible"
                      width="20px"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Box className={classes.textfieldConfirm}>
          <Box sx={{ display: "flex" }}>
            <Typography
              className={classes.changePasswordLabel}
              variant="subtitle"
              component="div"
            >
              Confirm New Password
            </Typography>
            <TextField
              className={classes.input}
              variant="standard"
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              error={newPassword !== confirmPassword}
              helperText={
                newPassword !== confirmPassword
                  ? "* The passwords do not match"
                  : ""
              }
              InputProps={{
                classes: { input: classes.textField },
                endAdornment: (
                  <InputAdornment className={classes.icons} position="end">
                    {showConfirmPassword ? (
                      <img
                        src="/images/textfield-visible.png"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        alt="visible"
                        width="20px"
                      />
                    ) : (
                      <img
                        src="/images/textfield-invisible.png"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        alt="invisible"
                        width="20px"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
          <Button
            className={
              allValid
                ? classes.changePasswordButton
                : classes.changePasswordButtonDisabled
            }
            disabled={!allValid}
            onClick={onChangePassword}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Change Password
            </Typography>
          </Button>
        </Box>
        <List className={classes.list}>
          <Box className={classes.listItemBox}>
            <ListItem
              style={{
                color: isValidLength ? "#1D426D" : "#6D7A8D",
                padding: "0 0.8vw",
                width: "20.8vw",
              }}
            >
              {isValidLength ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              <Typography variant="subtitle">at least 8 characters</Typography>
            </ListItem>
            <ListItem
              style={{
                color: hasUpperCase ? "#1D426D" : "#6D7A8D",
                padding: "0 0.8vw",
                width: "20.8vw",
              }}
            >
              {hasUpperCase ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              <Typography variant="subtitle">
                at least one uppercase letter
              </Typography>
            </ListItem>
          </Box>
          <Box className={classes.listItemBox}>
            <ListItem
              style={{
                color: hasLowerCase ? "#1D426D" : "#6D7A8D",
                padding: "0 0.8vw",
                width: "20.8vw",
              }}
            >
              {hasLowerCase ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="unchecked"
                />
              )}
              <Typography variant="subtitle">
                at least one lowercase letter
              </Typography>
            </ListItem>
            <ListItem
              style={{
                color: hasNumber && hasSymbol ? "#1D426D" : "#6D7A8D",
                padding: "0 0.8vw",
                width: "20.8vw",
              }}
            >
              {hasNumber && hasSymbol ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              <Typography variant="subtitle">
                at least one number and symbol
              </Typography>
            </ListItem>
          </Box>
        </List>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Typography variant="h2" className={classes.changePassword}>
          Change Password
        </Typography>
        <Box className={classes.textfield}>
          <Typography
            className={classes.changePasswordLabel}
            variant="subtitle"
          >
            Current Password
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type={showOldPassword ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showOldPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      alt="visible"
                      width="20px"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      alt="invisible"
                      width="20px"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Box className={classes.textfield}>
          <Typography
            className={classes.changePasswordLabel}
            variant="subtitle"
          >
            New Password
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type={showNewPassword ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showNewPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      alt="visible"
                      width="20px"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      alt="invisible"
                      width="20px"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Box className={classes.textfield}>
          <Typography
            className={classes.changePasswordLabel}
            variant="subtitle"
            component="div"
          >
            Confirm New Password
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            error={newPassword !== confirmPassword}
            helperText={
              newPassword !== confirmPassword
                ? "* The passwords do not match"
                : ""
            }
            InputProps={{
              classes: { input: classes.textField },
              endAdornment: (
                <InputAdornment className={classes.icons} position="end">
                  {showConfirmPassword ? (
                    <img
                      src="/images/textfield-visible.png"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      alt="visible"
                      width="20px"
                    />
                  ) : (
                    <img
                      src="/images/textfield-invisible.png"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      alt="invisible"
                      width="20px"
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <List className={classes.list}>
          <ListItem
            style={{
              color: isValidLength ? "#1D426D" : "#6D7A8D",
              padding: "0 0.8vw",
              width: "90vw",
            }}
          >
            {isValidLength ? (
              <img
                className={classes.logo}
                src="/images/rule-checked.png"
                alt="rule-checked"
              />
            ) : (
              <img
                className={classes.logo}
                src="/images/rule-unchecked.png"
                alt="rule-unchecked"
              />
            )}
            <Typography variant="subtitle">at least 8 characters</Typography>
          </ListItem>
          <ListItem
            style={{
              color: hasUpperCase ? "#1D426D" : "#6D7A8D",
              padding: "0 0.8vw",
              width: "90vw",
            }}
          >
            {hasUpperCase ? (
              <img
                className={classes.logo}
                src="/images/rule-checked.png"
                alt="rule-checked"
              />
            ) : (
              <img
                className={classes.logo}
                src="/images/rule-unchecked.png"
                alt="rule-unchecked"
              />
            )}
            <Typography variant="subtitle">
              at least one uppercase letter
            </Typography>
          </ListItem>
          <ListItem
            style={{
              color: hasLowerCase ? "#1D426D" : "#6D7A8D",
              padding: "0 0.8vw",
              width: "90vw",
            }}
          >
            {hasLowerCase ? (
              <img
                className={classes.logo}
                src="/images/rule-checked.png"
                alt="rule-checked"
              />
            ) : (
              <img
                className={classes.logo}
                src="/images/rule-unchecked.png"
                alt="unchecked"
              />
            )}
            <Typography variant="subtitle">
              at least one lowercase letter
            </Typography>
          </ListItem>
          <ListItem
            style={{
              color: hasNumber && hasSymbol ? "#1D426D" : "#6D7A8D",
              padding: "0 0.8vw",
              width: "90vw",
            }}
          >
            {hasNumber && hasSymbol ? (
              <img
                className={classes.logo}
                src="/images/rule-checked.png"
                alt="rule-checked"
              />
            ) : (
              <img
                className={classes.logo}
                src="/images/rule-unchecked.png"
                alt="rule-unchecked"
              />
            )}
            <Typography variant="subtitle">
              at least one number and symbol
            </Typography>
          </ListItem>
        </List>
        <Box className={classes.textfield}>
          <Button
            className={
              allValid
                ? classes.changePasswordButton
                : classes.changePasswordButtonDisabled
            }
            disabled={!allValid}
            onClick={onChangePassword}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Change Password
            </Typography>
          </Button>
        </Box>
      </MediaQuery>
    </>
  );
};
