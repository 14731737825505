import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Footer } from "../footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";
import { useSidebar } from "../../shared/context/SidebarContext";
import { FooterState } from "../../utils/enums";
import {
  StackState,
  useStackState,
} from "../../shared/context/StackStateContext";
import Cookies from "js-cookie";
import MediaQuery from "react-responsive";

const useStyles = makeStyles()((theme) => ({
  drawer: {
    ...theme.typography.subtitle,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      flexDirection: "row",
    },
  },
  logo: {
    padding: "4.6vh 0",
    display: "flex",
    justifyContent: "center",
    "&:hover": { cursor: "pointer" },
    width: "10vw",
    [`@media screen and (max-width: 1600px)`]: {
      width: "75%",
    },
  },
  logoSmall: {
    padding: "4.6vh 0",
    display: "flex",
    justifyContent: "center",
    "&:hover": { cursor: "pointer" },
    width: "3.2vw",
  },
  selected: {
    backgroundColor: theme.palette.background2,
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.background2,
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "1.8vh",
  },
  listItem: {
    gap: "3.2vh",
    height: "4.1vh",
    color: theme.palette.primary.light,
    borderRadius: "2.6vh",
    //paddingBottom: "0.9vh",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E8E9F341",
      color: theme.palette.primary.dark,
    },
    [`@media screen and (max-width: 1600px)`]: {
      maxWidth: "240px",
    },
  },
  close: {
    top: "2.8vh",
    left: "22%",
    position: "fixed",
    zIndex: "1300",
    height: "135px",
    "&:hover": { cursor: "pointer" },
  },
  open: {
    top: "2.8vh",
    left: "5%",
    position: "fixed",
    zIndex: "1300",
    height: "12.5vh",
    "&:hover": { cursor: "pointer" },
  },
  footer: {
    padding: "30px 60px 30px",
  },
  closedFooter: {
    height: "11.5vh",
  },
  divider: {
    width: "80%",
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  stayButton: {
    ...theme.typography.body1,
    border: "1px solid #000000",
    width: "5.2vw",
    height: "2.8vh",
    "&:hover": {
      color: "white",
    },
  },
  leaveButton: {
    ...theme.typography.body1,
    border: "1px solid #000000",
    width: "5.2vw",
    height: "2.8vh",
    color: theme.palette.button.mainText,
    background: theme.palette.button.main,
    textTransform: "none",
  },
}));

interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { logout, isLoggedIn } = useAuth();
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const { stackState } = useStackState();

  const [open, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | null>(null);

  const role = Cookies.get("role");

  const handleStay = () => {
    setOpen(false);
    setNextLocation(null);
  };

  const handleLeave = () => {
    setOpen(false);
    if (nextLocation) {
      navigate(nextLocation);
    }
  };

  const topListAdmin = [
    {
      text: "Home",
      icon: (
        <img
          src="/images/sidebar-home.png"
          alt="home"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/home",
    },
    {
      text: "Host Configurator",
      icon: (
        <img
          src="/images/sidebar-configurator.png"
          alt="configurator"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/configurator",
    },
    {
      text: "Dashboard Store",
      icon: (
        <img
          src="/images/sidebar-dashboard-store.png"
          alt="dashboard-store"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/dashboard-store",
    },
    {
      text: "Alert Store",
      icon: (
        <img
          src="/images/sidebar-alert-store.png"
          alt="alert-store"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/alert-store",
    },
  ];
  const topListViewer = [
    {
      text: "Home",
      icon: (
        <img
          src="/images/sidebar-home.png"
          alt="home"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/home",
    },
  ];

  const bottomListAdmin = [
    {
      text: "User Management",
      icon: (
        <img
          src="/images/sidebar-user-management.png"
          alt="settings"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/user-management",
    },
    {
      text: "Plan & Billing",
      icon: (
        <img
          src="/images/sidebar-plan-and-billing.png"
          alt="home"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/plan-and-billing",
    },
    {
      text: "Settings",
      icon: (
        <img
          src="/images/sidebar-settings.png"
          alt="settings"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/settings",
    },
    {
      text: "Documentation",
      icon: (
        <img
          src="/images/sidebar-documentation.png"
          alt="documentation"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/docs/introduction",
    },
    {
      text: "Support",
      icon: (
        <img
          src="/images/sidebar-support.png"
          alt="support"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/support",
    },
  ];
  const bottomListUser = [
    {
      text: "Settings",
      icon: (
        <img
          src="/images/sidebar-settings.png"
          alt="settings"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/settings",
    },
    {
      text: "Documentation",
      icon: (
        <img
          src="/images/sidebar-documentation.png"
          alt="documentation"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/docs/introduction",
    },
    {
      text: "Support",
      icon: (
        <img
          src="/images/sidebar-support.png"
          alt="support"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/support",
    },
  ];
  const bottomListLogout = [
    {
      text: "Logout",
      icon: (
        <img
          src="/images/sidebar-account.png"
          alt="account"
          style={{ width: "1.3vw" }}
        />
      ),
      link: "/login",
    },
  ];

  const bottomList =
    role === "CustomerOwner" || role === "CustomerAdmin"
      ? bottomListAdmin
      : bottomListUser;
  const topList = role === "CustomerViewer" ? topListViewer : topListAdmin;

  const determineSelectedIndex = () => {
    let foundItemIndex;

    const allLists = [...topList, ...bottomList, ...bottomListLogout];

    if (/^\/dashboard-store\/\d+$/.test(location.pathname)) {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === "/dashboard-store"
      );
    } else if (/^\/alert-store\/\d+$/.test(location.pathname)) {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === "/alert-store"
      );
    } else {
      foundItemIndex = allLists.findIndex(
        (item) => item.link === location.pathname
      );
    }

    return foundItemIndex !== -1 ? foundItemIndex : null;
  };

  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    determineSelectedIndex
  );

  useEffect(() => {
    setSelectedIndex(determineSelectedIndex());
  }, [location.pathname]);

  const handleListItemClick = async (index: number, link: string) => {
    if (link !== "/docs/introduction" && link !== "/support") {
      setSelectedIndex(index);
    }

    if (link === "/docs/introduction") {
      window.open(link, "_blank");
      return;
    }

    if (link === "/support") {
      window.open("https://support-nms.slack.com/", "_blank");
      return;
    }

    if (
      location.pathname === "/configurator" &&
      link !== "/configurator" &&
      stackState !== StackState.NONE &&
      stackState !== StackState.FINISHED
    ) {
      setNextLocation(link);
      setSelectedIndex(1);
      setOpen(true);
      return;
    }

    if (link === "/login") {
      await logout();
    }

    navigate(link);
  };

  const renderListItems = (
    list: {
      text: string;
      icon: JSX.Element;
      link: string;
    }[],
    offset = 0
  ) => (
    <List className={classes.list}>
      {list.map((item, index) => (
        <ListItem
          key={index}
          className={`${classes.listItem} ${
            selectedIndex === index + offset ? classes.selected : ""
          }`}
          style={{
            width: isSidebarOpen ? "15.6vw" : "100%",
            justifyContent: isSidebarOpen ? "start" : "center",
          }}
          onClick={() => handleListItemClick(index + offset, item.link)}
        >
          {isSidebarOpen ? (
            <>
              {item.icon}
              {item.text}
            </>
          ) : (
            item.icon
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {isLoggedIn() && !location.pathname.includes("docs") && (
        <>
          <MediaQuery minWidth={1224}>
            {isSidebarOpen ? (
              <img
                onClick={toggleSidebar}
                className={classes.close}
                src="/images/sidebar-close.png"
                alt="close"
              />
            ) : (
              <img
                onClick={toggleSidebar}
                className={classes.open}
                src="/images/sidebar-open.png"
                alt="close"
              />
            )}
            <Drawer
              className={classes.drawer}
              variant="persistent"
              open={true}
              anchor="left"
              PaperProps={{
                style: {
                  width: isSidebarOpen ? "22%" : "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
            >
              <div className={classes.box}>
                {isSidebarOpen ? (
                  <img
                    className={classes.logo}
                    src="/images/sidebar-logo-big.png"
                    onClick={() => navigate("/home")}
                    alt="logo"
                  />
                ) : (
                  <img
                    className={classes.logoSmall}
                    src="/images/sidebar-logo-small.png"
                    onClick={() => navigate("/home")}
                    alt="logo"
                  />
                )}
                {renderListItems(topList)}
              </div>
              <div className={classes.box}>
                <Divider className={classes.divider} />
                {renderListItems(bottomList, topList.length)}
                <Divider className={classes.divider} />
                {renderListItems(
                  bottomListLogout,
                  topList.length + bottomList.length
                )}
                {isSidebarOpen ? (
                  <div className={classes.footer}>
                    <Footer footerState={FooterState.Sidebar} />
                  </div>
                ) : (
                  <div className={classes.closedFooter} />
                )}
              </div>
            </Drawer>
            <Dialog open={open} onClose={handleStay}>
              <DialogTitle>Leave Configurator?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You have an unsaved configuration. Are you sure you want to
                  leave?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.stayButton}
                  onClick={handleStay}
                  color="primary"
                >
                  Stay
                </Button>
                <Button
                  className={classes.leaveButton}
                  onClick={handleLeave}
                  color="secondary"
                  autoFocus
                >
                  Leave
                </Button>
              </DialogActions>
            </Dialog>
          </MediaQuery>
        </>
      )}
    </>
  );
};
