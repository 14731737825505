import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Typography } from "@mui/material";
import MediaQuery from "react-responsive";

const useStyles = makeStyles()((theme) => ({
  emptyStackBox: {
    padding: "2.8vh 2.1vw",
    margin: "4.6vh 5.2vw 0 5.2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    border: `1px solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (min-width: 901px) and (max-width: 1224px)`]: {
      margin: "16vh 30vw 0",
      height: "54vh",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
    [`@media screen and (max-width: 1224px)`]: {
      margin: "12vh 5.2vw 0 5.2vw",
      textAlign: "center",
      alignItems: "center",
    },
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    width: "25%",
    height: "4.6vh",
    marginTop: "2.8vh",
    [`@media screen and (min-width: 901px) and (max-width: 1224px)`]: {
      width: "90%",
      marginTop: "26vh",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "80%",
      textAlign: "center",
    },
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  subtitle: {
    paddingBottom: "2.8vh",
    width: "100%",
    [`@media screen and (min-width: 901px) and (max-width: 1224px)`]: {
      paddingBottom: "5vh",
      width: "90%",
    },
  },
  description: {
    alignSelf: "start",
    [`@media screen and (min-width: 901px) and (max-width: 1224px)`]: {
      margin: "0 3vw",
    },
  },
  notification: {
    position: "absolute",
    top: "3.7vh",
    right: "2.1vw",
    display: "flex",
    alignItems: "center",
    width: "39.1vw",
    borderRadius: "23px",
    background: theme.palette.primary.light,
  },
  notificationText: {
    padding: "2.3vh 2.1vw 2.3vh 0.8vw",
    color: theme.palette.primary.contrastText,
  },
  img: {
    width: "2.1vw",
    padding: "1.9vh 0.5vw 1.9vh 1.6vw",
    [`@media screen and (max-width: 1600px)`]: {
      width: "35px",
    },
  },
  link: {
    color: "white",
    "&:visited": {
      color: "white",
    },
  },
  demoLink: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
}));

interface EmptyStackProps {
  onClick: () => void;
}

export const EmptyStack: React.FC<EmptyStackProps> = ({ onClick }) => {
  const { classes } = useStyles();

  return (
    <>
      <MediaQuery minWidth={1224}>
        <Box className={classes.emptyStackBox}>
          <Box className={classes.notification}>
            <img
              className={classes.img}
              src="/images/notification-2fa.png"
              alt="notification-2faogo"
            />
            <Typography variant="subtitle" className={classes.notificationText}>
              If you are already running data collection tools and simply want
              to connect them to benefit from NMS dashboards, please refer to
              the{" "}
              <a
                href={`${window.location.origin}/docs/manual-configuration`}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                documentation
              </a>
              .
            </Typography>
          </Box>
          <Typography variant="h2" className={classes.subtitle}>
            Configure your monitoring stack now
          </Typography>
          <Typography variant="subtitle" className={classes.description}>
            Click the button below to begin setting up your first host. For a
            detailed overview of how the Host Configurator works, please refer
            to the{" "}
            <a
              href={process.env.PUBLIC_URL + "/docs/host-configurator"}
              target="_blank"
              rel="noreferrer"
              className={classes.demoLink}
            >
              documentation
            </a>{" "}
            .
          </Typography>
          <Button className={classes.buttonBlack} onClick={onClick}>
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              New Host Configuration
            </Typography>
          </Button>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Box className={classes.emptyStackBox}>
          <Typography variant="h2" className={classes.subtitle}>
            Configure your monitoring stack now
          </Typography>
          <Typography variant="subtitle" className={classes.description}>
            Click the button below to begin setting up your first host. For a
            detailed overview of how the Host Configurator works, please refer
            to the{" "}
            <a
              href={process.env.PUBLIC_URL + "/docs/host-configurator"}
              target="_blank"
              rel="noreferrer"
              className={classes.demoLink}
            >
              documentation
            </a>{" "}
            .
          </Typography>
          <Button className={classes.buttonBlack} onClick={onClick}>
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              New Host Configuration
            </Typography>
          </Button>
        </Box>
      </MediaQuery>
    </>
  );
};
