import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { customFetch } from "../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  createUserBox: {
    margin: "0 5.2vw",
    display: "flex",
    flexDirection: "column",
    gap: "4.6vh",
    justifyContent: "space-between",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5.2vw 0",
    },
  },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "1.9vh",
    width: "36.5vw",
    margin: "4.6vh auto 0",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1vh 1vw",
      width: "85vw",
    },
  },
  inputBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  inputProps: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.dark,
    marginLeft: "2.1vw",
  },
  textField: {
    width: "26vw",
    margin: 0,
    "& .MuiFormHelperText-root": {
      marginLeft: "2.1vw",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "45vw",
    },
  },
  select: {
    ...theme.typography.subtitle,
    width: "26vw",
    height: "3.2vh",
    borderRadius: "23px",
    paddingLeft: "1.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "45vw",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "20%",
    borderRadius: "15px",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  link: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
  icons: {
    display: "flex",
    gap: "1vw",
    margin: "0 1vw",
    "&:hover": {
      cursor: "pointer",
    },
  },
  toggle: {
    "@media screen and (min-width: 2000px) and (max-width: 2800px)": {
      transform: "scale(1.33)",
      marginRight: "0.5vw",
    },
    "@media screen and (min-width: 2801px)": {
      transform: "scale(2)",
      marginRight: "0.5vw",
    },
  },
  toggleBox: {
    marginTop: "1vh",
  },
}));

interface CreateUserProps {
  setState: (state: "none" | "create" | "edit") => void;
  setLoading: (loading: boolean) => void;
  fetchData: () => void;
  setOpen: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
  setError: (open: boolean) => void;
}

export const CreateUser: React.FC<CreateUserProps> = ({
  setState,
  setLoading,
  fetchData,
  setOpen,
  setSnackbarMessage,
  setError,
}) => {
  const { classes } = useStyles();

  const [toggleOn, setToggleOn] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [role, setRole] = useState("CustomerViewer");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) return "Email is required";
    if (!emailRegex.test(email)) return "Please enter a valid email address";
    return "";
  };

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]).{8,}$/;
    if (!password) return "Password is required";
    if (!passwordRegex.test(password))
      return "Password must be at least 8 characters long, include an upper and lower case letter, a number, and a symbol";
    return "";
  };

  const validateVerifyPassword = (password: string, verifyPassword: string) => {
    if (!verifyPassword) return "Please confirm your password";
    if (password !== verifyPassword) return "Passwords do not match";
    return "";
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    setEmailError(validateEmail(e.currentTarget.value));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setPasswordError(validatePassword(e.currentTarget.value));
    setVerifyPasswordError(
      validateVerifyPassword(e.currentTarget.value, verifyPassword)
    );
  };

  const handleVerifyPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerifyPassword(e.currentTarget.value);
    setVerifyPasswordError(
      validateVerifyPassword(password, e.currentTarget.value)
    );
  };

  const handleSubmit = () => {
    const emailValidationError = validateEmail(email);

    if (!toggleOn) {
      const passwordValidationError = validatePassword(password);
      const verifyPasswordValidationError = validateVerifyPassword(
        password,
        verifyPassword
      );

      if (passwordValidationError || verifyPasswordValidationError) {
        setPasswordError(passwordValidationError);
        setVerifyPasswordError(verifyPasswordValidationError);
        return;
      }
    }

    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    createUser();
    setState("none");
  };

  const createUser = async () => {
    try {
      setLoading(true);
      const response = await customFetch.post(
        `grafana/subUserManagement/create`,
        {
          Email: email,
          Password: password,
          VerifyPassword: verifyPassword,
          Role: role,
          InviteEmail: toggleOn,
        }
      );

      if (response.status === 200) {
        setOpen(true);
        setError(false);
        setSnackbarMessage("User was created successfully.");
      }
    } catch (error: any) {
      let errorMessage = "An error occurred while creating the user.";

      if (error.response && error.response.data) {
        errorMessage = error.response.data;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setOpen(true);
      setError(true);
      setSnackbarMessage("Error creating user: " + errorMessage);

      console.error("Error creating user", error);
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  const handleToggleChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setToggleOn(event.target.checked);

    if (event.target.checked) {
      setPassword("");
      setVerifyPassword("");
    }
  };

  return (
    <Box className={classes.createUserBox}>
      <Typography variant="subtitle">
        Please proceed to add the details of your new user. You can always
        modify the user at a later time. Please refer to the{" "}
        <a
          href="https://app.nodemonitoring.io/docs/user-management"
          target="blank"
          className={classes.link}
        >
          documentation
        </a>{" "}
        for the exact permissions of the different user types.
      </Typography>
      <Box className={classes.detailsBox}>
        <Box className={classes.inputBox}>
          <Box>
            <Typography variant="subtitle">E-mail</Typography>
          </Box>
          <TextField
            className={classes.textField}
            InputProps={{ classes: { input: classes.inputProps } }}
            variant="standard"
            margin="normal"
            fullWidth
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
          />
        </Box>
        <Box className={classes.inputBox}>
          <Box>
            <Typography variant="subtitle">Role</Typography>
          </Box>
          <Select
            id="role-select"
            className={classes.select}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            fullWidth
          >
            <MenuItem value="CustomerAdmin">Admin</MenuItem>
            <MenuItem value="CustomerEditor">Editor</MenuItem>
            <MenuItem value="CustomerViewer">Viewer</MenuItem>
          </Select>
        </Box>
        <Box className={classes.toggleBox}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 4,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                className={classes.toggle}
                checked={toggleOn}
                onChange={handleToggleChange}
                name="passwordToggle"
                color="primary"
              />
            }
            label={
              toggleOn
                ? "Send user an email to set the password"
                : "Manually set a password for the user"
            }
          />
        </Box>
        {!toggleOn && (
          <>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Password</Typography>
              </Box>
              <TextField
                className={classes.textField}
                variant="standard"
                margin="normal"
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  classes: { input: classes.inputProps },
                  endAdornment: (
                    <InputAdornment className={classes.icons} position="end">
                      {showPassword ? (
                        <img
                          src="/images/textfield-visible.png"
                          onClick={() => setShowPassword(!showPassword)}
                          alt="visible"
                        />
                      ) : (
                        <img
                          src="/images/textfield-invisible.png"
                          onClick={() => setShowPassword(!showPassword)}
                          alt="invisible"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Verify Password</Typography>
              </Box>
              <TextField
                className={classes.textField}
                variant="standard"
                margin="normal"
                fullWidth
                name="verifyPassword"
                type={showVerifyPassword ? "text" : "password"}
                id="verifyPassword"
                value={verifyPassword}
                onChange={handleVerifyPasswordChange}
                error={!!verifyPasswordError}
                helperText={verifyPasswordError}
                InputProps={{
                  classes: { input: classes.inputProps },
                  endAdornment: (
                    <InputAdornment className={classes.icons} position="end">
                      {showVerifyPassword ? (
                        <img
                          src="/images/textfield-visible.png"
                          onClick={() =>
                            setShowVerifyPassword(!showVerifyPassword)
                          }
                          alt="visible"
                        />
                      ) : (
                        <img
                          src="/images/textfield-invisible.png"
                          onClick={() =>
                            setShowVerifyPassword(!showVerifyPassword)
                          }
                          alt="invisible"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => setState("none")}
        >
          <Typography variant="subtitle" className={classes.buttonText}>
            Cancel
          </Typography>
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          <Typography variant="subtitle" className={classes.buttonText}>
            Create
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
