import Cookies from "js-cookie";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { customFetch } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface AuthContextProps {
  temporaryKey: string | undefined;
  errorMessage: string | undefined;
  changedPassword: string | undefined;
  loading: boolean;
  email: string;
  isLoggedIn: () => boolean;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  hasTwofa: () => Promise<boolean>;
  changePassword: (
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) => Promise<void>;
  resetPassword: (
    email: string,
    password: string,
    confirmPassword: string,
    token: string,
    sendSuccessMail: boolean
  ) => Promise<unknown>;
  forgotPassword: (email: string) => Promise<unknown>;
  getTwofa: () => Promise<any>;
  setLoading: (loading: boolean) => void;
  enableTwofa: (code: string) => Promise<any>;
  disableTwofa: (code: string) => Promise<any>;
  loginTwofa: (code: string) => Promise<void>;
  loginRecoveryCode: (code: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [temporaryKey, setTemporaryKey] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [email, setEmail] = useState<string>("");
  const [changedPassword, setChangedPassword] = useState<string | undefined>();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const login = async (credentials: { email: string; password: string }) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/login", {
        email: credentials.email,
        password: credentials.password,
      });

      setErrorMessage("");

      const data = await response.data;

      if (data.requiresTwoFactor) {
        setTemporaryKey(data.temporaryKey);
        navigate("/2fa");
      }

      if (data.role) {
        Cookies.set("role", data.role);
      }

      if (data.tokens) {
        setEmail(data.email);
        Cookies.set("accessToken", data.tokens.accessToken);
        Cookies.set("refreshToken", data.tokens.refreshToken);
        navigate("/home");
      }
    } catch (error: any) {
      setErrorMessage("The user name and password don't match");
      console.error("Error logging in:", error);
    }

    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    Cookies.remove("accessToken");

    try {
      await axios.delete("auth/logout", {
        data: { RefreshToken: Cookies.get("refreshToken") },
      });
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("role");
    } catch (error) {
      console.error("Error logging out:", error);
    }

    setLoading(false);
  };

  const changePassword: any = async (
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) => {
    setLoading(true);
    let response;
    try {
      response = await customFetch.post("auth/change-password", {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
    } catch (error) {
      response = error;
      console.error("Error logging out:", error);
    }

    setLoading(false);
    return response;
  };

  const resetPassword = async (
    email: string,
    password: string,
    confirmPassword: string,
    token: string,
    sendSuccessMail: boolean
  ) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/reset-password", {
        password: password,
        confirmPassword: confirmPassword,
        email: email,
        token: token,
        sendSuccessMail: sendSuccessMail,
      });

      setErrorMessage("");
      setLoading(false);
      setChangedPassword(password);
      return response;
    } catch (error) {
      setErrorMessage("There was an error. Please try again later");
      setLoading(false);
      return error;
    }
  };

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/forgot-password", {
        email: email,
      });

      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const getTwofa = async () => {
    setLoading(true);
    try {
      const response = await customFetch.get("auth/authenticator-key");

      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error logging out:", error);
    }
  };

  const hasTwofa = async () => {
    setLoading(true);
    try {
      const response = await customFetch.get("auth/has-2fa");

      setLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error logging out:", error);
    }

    setLoading(false);
  };

  const enableTwofa = async (code: string) => {
    try {
      const response = await customFetch.post("auth/enable-2fa", {
        code: code,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  };

  const disableTwofa = async (code: string) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/disable-2fa", {
        code: code,
      });

      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const loginTwofa = async (code: string) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/login-2fa", {
        code: code,
        temporaryKey: temporaryKey,
      });

      if (response.data.error) {
        setErrorMessage(response.data.error);
        setLoading(false);
        return;
      }

      setErrorMessage("");
      const data = response.data.data;

      if (data.tokens) {
        setEmail(data.email);
        Cookies.set("accessToken", data.tokens.accessToken);
        Cookies.set("refreshToken", data.tokens.refreshToken);
        Cookies.set("role", data.role);

        navigate("/home");
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.error);
      console.error("Error logging out:", error);
    }

    setLoading(false);
  };

  const loginRecoveryCode = async (code: string) => {
    setLoading(true);
    try {
      const response = await customFetch.post("auth/login-recovery-codes", {
        code: code,
        temporaryKey: temporaryKey,
      });

      if (response.data.error) {
        setErrorMessage(response.data.error);
        setLoading(false);
        return;
      }

      setErrorMessage("");
      const data = response.data;

      if (data.tokens) {
        setEmail(data.email);
        Cookies.set("accessToken", data.tokens.accessToken);
        Cookies.set("refreshToken", data.tokens.refreshToken);
        Cookies.set("role", data.role);

        navigate("/home");
      }
    } catch (error: any) {
      setErrorMessage(error.response.error);
      console.error("Error logging out:", error);
    }

    setLoading(false);
  };

  const isLoggedIn = (): boolean => {
    return !!Cookies.get("accessToken");
  };

  return (
    <AuthContext.Provider
      value={{
        temporaryKey,
        errorMessage,
        changedPassword,
        loading,
        email,
        setLoading,
        isLoggedIn,
        login,
        logout,
        changePassword,
        resetPassword,
        forgotPassword,
        getTwofa,
        enableTwofa,
        loginTwofa,
        loginRecoveryCode,
        hasTwofa,
        disableTwofa,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
