import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
  infoBox: {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: "450px",
    background: theme.palette.primary.light,
    borderRadius: "23px",
    color: theme.palette.primary.contrastText,
    zIndex: 300,

    [`@media screen and (max-width: 1244px)`]: {
      width: "40%",
      right: "5%",
      top: "10%",
    },

    [`@media screen and (max-width: 600px)`]: {
      width: "90%",
    },
  },
  icon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    height: "50px",
    width: "50px",
  },
  title: {
    margin: "100px 65px 25px",
  },
  divider: {
    margin: "0 65px",
    borderBottom: `4px solid ${theme.palette.background2}`,
  },
  info: {
    margin: "40px 65px 80px",
  },
}));

interface InfoBoxProps {
  showInfo: boolean;
  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children?: string | JSX.Element;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  showInfo,
  setShowInfo,
  title,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.infoBox}>
      <img
        src="/images/info-box-close.png"
        alt="close"
        onClick={() => setShowInfo(!showInfo)}
        className={classes.icon}
      />
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body1" className={classes.info}>
        {children}
      </Typography>
    </Box>
  );
};
