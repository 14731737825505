import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Dashboard } from "../DashboardStore";
import { customFetch } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  item: {
    minWidth: "23.4vw",
    border: `0.1vw solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (max-width: 1224px)`]: {
      minWidth: "80vw"
    },
  },
  title: {
    margin: "2.3vh 2.1vw",
    display: "block",
    fontWeight: "bolder",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1.5vh 3.3vw",
    },
  },
  description: {
    margin: "0 2.1vw 2.3vh",
    display: "block",
  },
  img: {
    padding: "0 2.1vw",
    width: "19.3vw",
    borderRadius: "16%",
    [`@media screen and (max-width: 1224px)`]: {
      width: "92%",
      borderRadius: "8%",
      padding: "0 3.3vw"
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.8vh 2.1vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1vh 3.3vw",
    },
  },
  button: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    border: `1px solid ${theme.palette.background2}`,
    "&:hover": {
      //background: "#E8E9F3 !important",
      //background: "#E8E9F3 !important",
      color: "white",
      border: "none",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  uninstall: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    height: "3.7vh",
    background: theme.palette.button.blue,
    color: theme.palette.button.blueText,
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  install: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "45%",
    height: "3.7vh",
    background: theme.palette.button.main,
    color: theme.palette.button.mainText,
    [`@media screen and (max-width: 1224px)`]: {
      width: "48%",
      height: "5vh",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    color: "white",
  },
}));

interface DashboardItemProps {
  dashboard: Dashboard;
  fetchData: () => void;
  setLoading: (loading: boolean) => void;
}

export const DashboardItem: React.FC<DashboardItemProps> = ({
  dashboard,
  fetchData,
  setLoading,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setInstallLoading] = useState<boolean>(false);

  const install = async (id: number) => {
    try {
      setInstallLoading(true);
      await customFetch.post(`grafana/dashboards?dashboardId=${id}`);

      fetchData();
      setInstallLoading(false);
    } catch (error) {}
  };

  const uninstall = async (id: number) => {
    try {
      setInstallLoading(true);
      await customFetch.delete(`grafana/dashboards?dashboardId=${id}`, {});

      fetchData();
      setInstallLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openDetails = async (id: number) => {
    navigate(`/dashboard-store/${id}`);
  };

  return (
    <Box className={classes.item}>
      <Typography variant="subtitle" className={classes.title}>
        {dashboard.name}
      </Typography>
      <img
        className={classes.img}
        onLoad={() => setLoading(false)}
        src={
          process.env.PUBLIC_URL +
          `/markdown/dashboards/${dashboard.id}/${dashboard.id}_preview.webp`
        }
        alt="dashboard"
      />
      <Box className={classes.buttonBox}>
        <Button
          className={classes.button}
          onClick={() => openDetails(dashboard.id)}
        >
          Details
        </Button>
        <Button
          className={
            dashboard.isInstalled ? classes.uninstall : classes.install
          }
          onClick={() =>
            dashboard.isInstalled
              ? uninstall(dashboard.id)
              : install(dashboard.id)
          }
        >
          {loading && (
            <CircularProgress size="23px" className={classes.loading} />
          )}
          {!loading && (dashboard.isInstalled ? "Uninstall" : "Install")}
        </Button>
      </Box>
    </Box>
  );
};
