import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Footer } from "../../core/footer/Footer";
import { FooterState } from "../../utils/enums";
import MediaQuery from "react-responsive";

const useStyles = makeStyles()((theme) => ({
  main: {
    width: "28%",
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
    },
  },
  toc: {
    ...theme.typography.subtitle,
    background: "#E8E9F3",
    borderRadius: "15px",
    color: "#25497E",
    margin: "5.6vh 1vw 0 0",
    padding: "1.9vh 1.3vw",
    display: "flex",
    flexDirection: "column",
    gap: "0.9vh",
    width: "18%",
    position: "fixed",
    top: "9.3vh",
    [`@media screen and (max-width: 1224px)`]: {
      position: "static",
      width: "95%",
      margin: "0",
      padding: "1.5vh 2vw"
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
    [`@media screen and (max-width: 1224px)`]: {
      padding: "0 3vw 0 2vw"
    },
  },
  logo: {
    position: "fixed",
    top: "4.6vh",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "17%",
    marginLeft: "0.9vw",
    [`@media screen and (max-width: 1224px)`]: {
      position: "static",
      width: "210px",
      marginBottom: "2.5vh",
      marginLeft: "-1vw",
    },
  },
  footer: {
    padding: "0 0 1.9vh 5%",
    position: "fixed",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
  },
}));

interface TableOfContentsProps {
  setId: (id: string) => void;
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ setId }) => {
  const { classes } = useStyles();
  const [toc, setToc] = useState<{ level: number; title: string }[]>([]);
  const [expandedH1s, setExpandedH1s] = useState<boolean[]>([]);
  const [visibleChildHeaders, setVisibleChildHeaders] = useState<number[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("../markdown/docs/docs-toc.md")
      .then((response) => response.text())
      .then((text) => {
        generateToc(text);
      })
      .catch((error) => {
        console.error("Error loading Markdown file:", error);
      });
  }, []);

  const generateToc = (markdownContent: string) => {
    const headerPattern = /^(#{1,2})\s(.+)$/gm;
    const tocItems: { level: number; title: string }[] = [];
    const expandedH1s: boolean[] = [];
    let match;

    while ((match = headerPattern.exec(markdownContent)) !== null) {
      const level = match[1].length;
      const title = match[2];
      tocItems.push({ level, title });

      if (level === 1) {
        expandedH1s.push(false);
      }
    }

    setToc(tocItems);
    setExpandedH1s(expandedH1s);
  };

  const navigateTo = (title: string, id?: string) => {
    const convertedString = title
      .replace(/\s+/g, "-")
      .toLowerCase()
      .replace(/&/g, "and");

    if (id) {
      setId(id.toLowerCase().replace(/\W/g, "-").replace(/&/g, "and"));
    }

    navigate(`/docs/${convertedString}`);
  };

  const toggleH1 = (index: number) => {
    const updatedExpandedH1s = [...expandedH1s];
    updatedExpandedH1s[index] = !updatedExpandedH1s[index];
    setExpandedH1s(updatedExpandedH1s);

    if (updatedExpandedH1s[index]) {
      const nextH1Index = toc.findIndex(
        (header, j) => j > index && header.level === 1
      );
      const endIndex = nextH1Index !== -1 ? nextH1Index : toc.length;
      const childIndices = Array.from(
        { length: endIndex - (index + 1) },
        (_, i) => index + 1 + i
      );
      setVisibleChildHeaders(childIndices);
    } else {
      const nextH1Index = toc.findIndex(
        (header, j) => j > index && header.level === 1
      );
      const endIndex = nextH1Index !== -1 ? nextH1Index : toc.length;
      const childIndicesToRemove = Array.from(
        { length: endIndex - (index + 1) },
        (_, i) => index + 1 + i
      );
      const updatedVisibleChildHeaders = visibleChildHeaders.filter(
        (childIndex) => !childIndicesToRemove.includes(childIndex)
      );
      setVisibleChildHeaders(updatedVisibleChildHeaders);
    }
  };

  const isSelected = (title: string) => {
    const pathname = window.location.pathname;
    const pathParts = pathname.split("/");
    const pathTitle = pathParts[pathParts.length - 1];

    const convertedTitle = title
      .replace(/\s+/g, "-")
      .toLowerCase()
      .replace(/&/g, "and");

    return convertedTitle === pathTitle

  }

  const renderToc = () => {
    const renderedToc: JSX.Element[] = [];

    for (let i = 0; i < toc.length; i++) {
      const item = toc[i];
      const isH1 = item.level === 1;

      if (isH1) {
        const isExpanded = expandedH1s[i];
        renderedToc.push(
          <Box className={classes.listItem}>
            <li
              onClick={() => navigateTo(item.title)}
              key={i}
              style={{
                cursor: "pointer",
                marginLeft: `${16 * (item.level - 1)}px`,
                fontWeight: isSelected(item.title) ? "bold" : "normal",
              }}
            >
              {item.title}
            </li>
            {!isExpanded ? (
              <img
                src="/images/toc-down.png"
                onClick={() => toggleH1(i)}
                alt="down"
                style={{ cursor: "pointer", height: "10px" }}
              />
            ) : (
              <img
                src="/images/toc-up.png"
                onClick={() => toggleH1(i)}
                alt="down"
                style={{ cursor: "pointer", height: "10px" }}
              />
            )}
          </Box>
        );

        if (isExpanded) {
          const nextH1Index = toc.findIndex(
            (header, j) => j > i && header.level === 1
          );
          const endIndex = nextH1Index !== -1 ? nextH1Index : toc.length;

          for (let j = i + 1; j < endIndex; j++) {
            if (visibleChildHeaders.includes(j)) {
              const childItem = toc[j];
              renderedToc.push(
                <li
                  onClick={() => navigateTo(item.title, childItem.title)}
                  key={childItem.title + j}
                  style={{
                    marginLeft: `${16 * (childItem.level - 1)}px`,
                    cursor: "pointer",
                    fontSize: "90%",
                  }}
                >
                  &#x2022; {childItem.title}
                </li>
              );
            }
          }
        }
      }
    }

    return renderedToc;
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <Box className={classes.main}>
          <img
            className={classes.logo}
            src="/images/docs-logo.png"
            alt="logo"
            onClick={() => navigate("/docs/introduction")}
          />
          <ul className={classes.toc} style={{ listStyleType: "none" }}>
            {renderToc()}
          </ul>
          <div className={classes.footer}>
            <Footer footerState={FooterState.Docs} />
          </div>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Box className={classes.main}>
        <img
            className={classes.logo}
            src="/images/docs-logo.png"
            alt="logo"
            onClick={() => navigate("/docs/introduction")}
          />
          <ul className={classes.toc} style={{ listStyleType: "none" }}>
            {renderToc()}
          </ul>
        </Box>
      </MediaQuery>
    </>
  );
};

export default TableOfContents;
