import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../../../core/mobile-header/MobileHeader";

const useStyles = makeStyles()((theme) => ({
  finish: {
    margin: "0 140px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    flex: 1,
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 2vh",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: "1.6vw",
    marginTop: "2.8vh",
    [`@media screen and (max-width: 1600px)`]: {
      marginTop: "15px",
    },
  },
  desc: {
    display: "block",
    marginTop: "2.8vh",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  buttonWhite: {
    background: theme.palette.button.light,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    ...theme.typography.body1,
    border: `1px solid ${theme.palette.button.lightText}`,
    "&:hover": {
      border: "none",
      "& span": {
        color: "white",
      },
    },
    [`@media screen and (min-width: 900px) and (max-width: 1600px)`]: {
      width: "26%",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonWhiteText: {
    ...theme.typography.body1,
    color: theme.palette.button.lightText,
    textTransform: "none",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  textLink: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
  startIcon: {
    [`@media screen and (max-width: 1600px)`]: {
      height: "20px",
      width: "20px",
    },
  },
  icon: {
    height: "3.2vh",
    color: "green",
    fontSize: "3vh",
    [`@media screen and (max-width: 1224px)`]: {
      height: "64px",
    },
  },
  h2: { margin: 0 },
}));

interface FinishProps {
  setStep: (number: number) => void;
}

export const Finish: React.FC<FinishProps> = ({ setStep }) => {
  const { classes } = useStyles();

  return (
    <>
      <MediaQuery maxWidth={1224}>
        <MobileHeader title="Host Configurator"></MobileHeader>
      </MediaQuery>
      <Box className={classes.finish}>
        <Box>
          <Box className={classes.title}>
            <CheckBoxIcon className={classes.icon} />
            <Typography variant="h2" className={classes.h2}>
              Host configuration complete! Start monitoring now.
            </Typography>
          </Box>
          <Typography variant="subtitle" className={classes.desc}>
            You can now log into your team's{" "}
            <a
              href={process.env.REACT_APP_GRAFANA}
              className={classes.textLink}
              target="_blank"
            >
              Dashboard Platform
            </a>{" "}
            instance using the credentials of your NMS account. To verify that
            the host configuration was successful and the tools have started
            streaming data to NMS, check the following panel:<br></br>
            <br></br>
            <strong>
              home dashboard/data collection metric stats/overview of scraped
              endpoints
            </strong>
            <br></br>
            <br></br>Browse the Dashboard Store to install the appropriate
            dashboards to monitor all your configured services.
          </Typography>
        </Box>
        <Box className={classes.buttonBox}>
          <Button className={classes.buttonWhite} onClick={() => setStep(0)}>
            <Typography variant="subtitle" className={classes.buttonWhiteText}>
              Configure More Services
            </Typography>
          </Button>
          <Button
            startIcon={
              <img
                src="/images/button-visit.png"
                className={classes.startIcon}
                alt="add"
              />
            }
            className={classes.buttonNext}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              <a
                className={classes.link}
                href={process.env.REACT_APP_GRAFANA}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Dashboards
              </a>
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};
