import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { customFetch } from "../../../utils/axios";
import { Sidebar } from "../../../core/sidebar/Sidebar";
import { useSidebar } from "../../../shared/context/SidebarContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import gfm from "remark-gfm";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../../core/mobile-header/MobileHeader";
import { Navbar } from "../../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: "7.4vh 0 3.7vh 5.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      fontSize: "18px",
      padding: "0 5vw 3vh",
      marginTop: "10vh",
    },
  },
  selection: {
    display: "flex",
    margin: "3.7vh 0",
    gap: "0.9vh",
  },
  scrollBox: {
    display: "flex",
    gap: "1.9vh",
    overflowX: "auto",
    width: "100%",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  markdown: {
    ...theme.typography.body1,
    margin: "0 9.4vw 3.7vh 5.2vw",
    "& code": {
      backgroundColor: "#6D7A8D",
      color: "white",
      padding: "0.1vh 0.3vw",
      fontSize: "95%",
    },
    "& pre": {
      backgroundColor: "#6D7A8D",
      padding: "1.1vh 0.9vw",
      border: "0.1vh solid #E8E9F3",
      borderRadius: "15px",
      borderColor: "grey",
      overflow: "scroll",
    },
    "& a": {
      color: "#1D426D",
    },
    "& a:visited": {
      color: "#1D426D",
    },
    "& table": {
      borderCollapse: "collapse",
      width: "100%",
      display: "table",
      borderSpacing: 0,
      marginBottom: "1rem",
      borderRadius: "20px",
      justifyContent: "center",

      "& th": {
        padding: "8px 12px",
        lineHeight: 1.5,
        verticalAlign: "top",
        fontWeight: "bold",
        backgroundColor: "#6D7A8D",
        color: "white",
        textAlign: "center",
      },

      "& td": {
        padding: "8px 12px",
        lineHeight: 1.5,
        verticalAlign: "top",
        textAlign: "center",
      },

      "& tr:nth-child(even)": {
        backgroundColor: "#E8E9F3",
      },
    },
    [`@media screen and (max-width: 1224px)`]: {
      overflow: "auto",
      margin: "0 5vw",
      "& pre": {
        padding: "1.1vh 4vw",
      },
    },
  },
  carouselBox: {
    margin: "0 9.4vw 3.7vh 5.2vw",
    display: "flex",
    justifyContent: "center",
  },
  detailsImage: {
    width: "100%",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    //margin: "0 140px 40px",
    backgroundColor: "white",
    background:
      "linear-gradient(to bottom, rgba(255,255,255,0.9), rgba(255,255,255,1))",
    position: "sticky",
    bottom: 0,
    right: 0,
    left: "0%",
    zIndex: 1000,
    padding: "1.7vh 9.4vw",
    marginLeft: "-5%",
    [`@media screen and (max-width: 1224px)`]: {
      padding: "1.7vh 6vw",
      marginLeft: "0",
    },
  },
  button: {
    ...theme.typography.subtitle,
    backgroundColor: "white",
    borderRadius: "23px",
    width: "20%",
    height: "3.7vh",
    padding: "0.5vh 1vw",
    border: `0.1vh solid ${theme.palette.background2}`,
    "&:hover": {
      color: "white",
      border: "none",
    },
    [`@media screen and (min-width: 1224px) and (max-width: 1600px)`]: {
      width: "25%",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  install: {
    ...theme.typography.subtitle,
    borderRadius: "23px",
    width: "20%",
    height: "3.7vh",
    background: theme.palette.button.main,
    color: theme.palette.button.mainText,
    "&:hover": {
      border: "none",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  isInstalled: {
    ...theme.typography.subtitle,
    borderRadius: "15px",
    width: "20%",
    height: "3.7vh",
    background: theme.palette.button.main,
    backgroundColor: "#DFE0EA",
    color: "grey",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  hidden: {
    display: "none",
  },
}));

export type Alert = {
  id: number;
  name: string;
  isInstalled: boolean;
};

interface AlertDetailsProps {}

export const AlertDetails: React.FC<AlertDetailsProps> = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isSidebarOpen } = useSidebar();
  const [markdownContent, setMarkdownContent] = useState<string>("");
  const [mdLoading, setMdLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentAlert, setCurrentAlert] = useState<Alert>({
    id: 0,
    name: "",
    isInstalled: false,
  });

  const fetchData = async () => {
    try {
      if (!id) {
        setMdLoading(false);
        return;
      }

      const response = await customFetch.get("grafana/alerts");

      setCurrentAlert(
        response.data.find((alert: any) => alert.alertId === parseInt(id))
      );

      setMdLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const markdownPath = `../markdown/alerts/${id}/${id}.md`;

    fetch(markdownPath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Could not fetch markdown from ${markdownPath}`);
        }
        return response.text();
      })
      .then((content) => {
        setMarkdownContent(content);
      })
      .catch((error) => {
        console.error("Error fetching markdown:", error);
      });
  }, [id]);

  const install = async (id: number) => {
    try {
      setLoading(true);
      await customFetch.post(`installAlerts?groupId=${id}`);

      fetchData();
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "25%" : "5%",
          }}
        >
          <Sidebar></Sidebar>
          <Typography variant="h1" className={classes.title}>
            {currentAlert.name}
          </Typography>
          {mdLoading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!mdLoading ? "" : classes.hidden}>
            <ReactMarkdown
              remarkPlugins={[gfm]}
              className={classes.markdown}
              children={markdownContent}
            />
            <Box className={classes.buttonBox}>
              <Button
                className={classes.button}
                onClick={() => navigate("/alert-store")}
              >
                Go Back
              </Button>
              <Button
                disabled={currentAlert.isInstalled || loading}
                className={
                  currentAlert.isInstalled
                    ? classes.isInstalled
                    : classes.install
                }
                onClick={() => {
                  if (id) {
                    install(parseInt(id));
                  }
                }}
              >
                {loading && (
                  <CircularProgress size="23px" className={classes.loading} />
                )}
                {!loading && currentAlert.isInstalled ? "Installed" : "Install"}
              </Button>
            </Box>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
            marginBottom: "56px",
          }}
        >
          <MobileHeader title="Dashboard Store"></MobileHeader>
          <Typography variant="h2" className={classes.title}>
            {currentAlert.name}
          </Typography>
          {mdLoading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!mdLoading ? "" : classes.hidden}>
            <ReactMarkdown
              remarkPlugins={[gfm]}
              className={classes.markdown}
              children={markdownContent}
            />
            <Box className={classes.buttonBox}>
              <Button
                className={classes.button}
                onClick={() => navigate("/alert-store")}
              >
                Go Back
              </Button>
              <Button
                disabled={currentAlert.isInstalled || loading}
                className={
                  currentAlert.isInstalled
                    ? classes.isInstalled
                    : classes.install
                }
                onClick={() => {
                  if (id) {
                    install(parseInt(id));
                  }
                }}
              >
                {loading && (
                  <CircularProgress size="23px" className={classes.loading} />
                )}
                {!loading && currentAlert.isInstalled ? "Installed" : "Install"}
              </Button>
            </Box>
          </div>
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
