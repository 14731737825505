import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography, Button } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  homeCard: {
    color: theme.palette.background1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `0.1vh solid ${theme.palette.background2}`,
    borderRadius: "15px",
    width: "100%",
    height: "37vh",
    [`@media screen and (min-width: 901px) and (max-width: 1224px)`]: {
      height: "52vh",
      width: "auto",
      margin: "0 5vw 2vh",
    },
    [`@media screen and (max-width: 1224px)`]: {
      height: "auto",
      width: "auto",
      margin: "0 5vw 2vh",
    },
  },
  titleBox: {
    display: "flex",
    margin: "0.9vh 0",
    padding: "2.8vh 0 2.8vh 1.9vw",
    [`@media screen and (max-width: 1224px)`]: {
      alignContent: "center",
      justifyContent: "center",
      padding: "2.8vh 0 2vh 0",
    },
  },
  titleText: {
    ...theme.typography.h2,
    color: theme.palette.primary.main,
  },
  descriptionBox: {
    display: "flex",
    justifyContent: "center",
    [`@media screen and (max-width: 1224px)`]: {
      alignContent: "center",
      justifyContent: "center",
    },
  },
  descriptionText: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.light,
    width: "80%",
    [`@media screen and (max-width: 1224px)`]: {
      textAlign: "center"
    },
  },
  buttonBox: {
    display: "flex",
    margin: "2.8vh 0",
    alignContent: "center",
    justifyContent: "center",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "80%",
    height: "4.6vh",
  },
  buttonBlackIcon: {
    background: theme.palette.button.main,
    display: "flex",
    borderRadius: "15px",
    width: "80%",
    height: "4.6vh",
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  startIcon: {
    height: "2.3vh",
  },
}));

interface HomeCardProps {
  title: string;
  description: ReactNode;
  buttonText: string;
  onClick: () => void;
  buttonIcon?: string;
}

export const HomeCard: React.FC<HomeCardProps> = ({
  title,
  description,
  buttonText,
  onClick,
  buttonIcon,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.homeCard}>
      <Box className={classes.contentBox}>
        <Box className={classes.titleBox}>
          <Typography className={classes.titleText}>{title}</Typography>
        </Box>
        <Box className={classes.descriptionBox}>
          <Typography className={classes.descriptionText}>
            {description}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.buttonBox}>
        {buttonIcon ? (
          <Button
            startIcon={
              <img src={buttonIcon} className={classes.startIcon} alt="icon" />
            }
            className={classes.buttonBlackIcon}
            onClick={onClick}
          >
            <Typography className={classes.buttonBlackText}>
              {buttonText}
            </Typography>
          </Button>
        ) : (
          <Button className={classes.buttonBlack} onClick={onClick}>
            <Typography className={classes.buttonBlackText}>
              {buttonText}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};
