import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CommonLayout } from "../../components/common-layout/CommonLayout";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  textField: {
    ...theme.typography.subtitle,
  },
  link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.light,
    margin: "20px auto 35px",
  },
  button: {
    ...theme.typography.body1,
    marginBottom: "5.5vh",
    borderRadius: "15px",
  },
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
}));

interface ForgotPasswordProps {}

export const ForgotPasswordPage: React.FC<ForgotPasswordProps> = () => {
  const { classes } = useStyles();
  const { forgotPassword, loading } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    if (e.target.value) {
      setEmailError(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email) {
      setEmailError(false);
      const response: any = await forgotPassword(email);

      if (response.status === 500) {
        setSnackbarMessage("Something went wrong, please try again later.");
        setError(true);
      } else {
        setSnackbarMessage(`A reset link was sent to ${email}.`);
        setError(false);
      }
      setOpen(true);
    } else {
      setEmailError(true);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={() => setOpen(!open)}
      >
        <Box className={classes.snackbar}>
          {!error && <img src="/images/snackbar.png" alt="snackbar" width="35px"></img>}
          {snackbarMessage}
        </Box>
      </Snackbar>
      <CommonLayout
        title="Forgot Password"
        desc="If you have forgotten your password, please enter your email and we will send you instructions on how to reset it."
      >
        <form onSubmit={handleSubmit}>
          <TextField
            InputProps={{ classes: { input: classes.textField } }}
            variant="standard"
            margin="normal"
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? "A valid email is required" : ""}
          />
          <Typography
            className={classes.link}
            component="a"
            variant="body1"
            href="/login"
          >
            Back to Log In
          </Typography>
          <Button
            className={classes.button}
            type="submit"
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
          >
            {loading && <CircularProgress size="23px" />}
            {!loading && "Send"}
          </Button>
        </form>
      </CommonLayout>
    </>
  );
};
