import { createTheme } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import type { ThemeOptions } from "@mui/material/";

const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemiBold = 600;

const palette: PaletteOptions = {
  primary: {
    light: "#6D7A8D",
    main: "#212121",
    contrastText: "#FFF",
  },
  button: {
    main: "#212121",
    mainText: "#FFF",
    light: "#FFFF",
    lightText: "#212121",
    blue: "#25497E",
    blueText: "#FFF",
  },
  shadow: "#6D7A8D1A",
  background1: "#FFF",
  background2: "#E8E9F3",
};

const maxScreenWidth1224 = `@media screen and (max-width: 1224px)`;
const maxScreenWidth1600 = `@media screen and (max-width: 1600px)`;
const between2000and2K = `@media screen and (min-width: 2000px) and (max-width: 2800px)`;
const minScreenWidth2K = `@media screen and (min-width: 2801px)`;

const typography: TypographyOptions = {
  fontFamily: '"Poppins", Roboto, Arial, sans-serif',
  h1: {
    fontSize: "28px",
    fontWeight: fontWeightSemiBold,
    [minScreenWidth2K]: {
      fontSize: "56px",
    },
    [between2000and2K]: {
      fontSize: "37px",
    },
    [maxScreenWidth1600]: {
      fontSize: "26px",
    },
    [maxScreenWidth1224]: {
      fontSize: "22px",
    },
  } as any,
  h2: {
    fontSize: "23px",
    fontWeight: fontWeightSemiBold,
    [minScreenWidth2K]: {
      fontSize: "46px",
    },
    [between2000and2K]: {
      fontSize: "30.59px",
    },
    [maxScreenWidth1600]: {
      fontSize: "20px",
    },
  } as any,
  subtitle: {
    fontSize: "16px",
    fontWeight: fontWeightMedium,
    [minScreenWidth2K]: {
      fontSize: "32px",
    },
    [between2000and2K]: {
      fontSize: "21.28px",
    },
    [maxScreenWidth1600]: {
      fontSize: "14px",
    },
  } as any,
  body1: {
    fontSize: "14px",
    fontWeight: fontWeightRegular,
    [minScreenWidth2K]: {
      fontSize: "28px",
    },
    [between2000and2K]: {
      fontSize: "18.62px",
    },
    [maxScreenWidth1600]: {
      fontSize: "13px",
    },
  } as any,
  caption1: {
    fontSize: "11px",
    fontWeight: fontWeightRegular,
    [minScreenWidth2K]: {
      fontSize: "22px",
    },
    [between2000and2K]: {
      fontSize: "14.63px",
    },
    [maxScreenWidth1600]: {
      fontSize: "10px",
    },
  } as any,
  caption2: {
    fontSize: "10px",
    fontWeight: fontWeightRegular,
    [minScreenWidth2K]: {
      fontSize: "20px",
    },
    [between2000and2K]: {
      fontSize: "13.3px",
    },
    [maxScreenWidth1600]: {
      fontSize: "11px",
    },
  } as any,
  callOut: {
    fontSize: "9px",
    fontWeight: fontWeightRegular,
    [minScreenWidth2K]: {
      fontSize: "18px",
    },
    [between2000and2K]: {
      fontSize: "11.97px",
    },
    [maxScreenWidth1600]: {
      fontSize: "8px",
    },
  } as any,
  code: {
    fontSize: "14px",
    fontWeight: fontWeightRegular,
    [minScreenWidth2K]: {
      fontSize: "28px",
    },
    [between2000and2K]: {
      fontSize: "18.62px",
    },
    [maxScreenWidth1600]: {
      fontSize: "10.5px",
    },
  } as any,
};


const themeConfig: ThemeOptions = {
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          transition: "background-color 0s",
          "&:hover": {
            background: "linear-gradient(90deg , #25497E, #212121)",
            transition: "background-color 0.3s",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: typography.caption1?.fontSize,
          background: palette.background2,
          borderRadius: "23px",
          padding: "20px",
          marginLeft: "-20px",
          color: "black",
        },
        arrow: {
          marginLeft: "10px",
          color: palette.background2,
        },
      },
    },
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
    callOut: React.CSSProperties;
    subtitle: React.CSSProperties;
    body1: React.CSSProperties;
    code: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
    callOut?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    body1?: React.CSSProperties;
    code?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    caption1: true;
    caption2: true;
    callOut: true;
    subtitle: true;
    body1: true;
    code: true;
  }
}

interface Button {
  main: string;
  mainText: string;
  light: string;
  lightText: string;
  blue: string;
  blueText: string;
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    button: Button;
    shadow: string;
    background1: string;
    background2: string;
  }

  interface Palette {
    button: Button;
    shadow: string;
    background1: string;
    background2: string;
  }
}

export const lrTheme = createTheme(themeConfig);
