import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { customFetch } from "../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  editUserBox: {
    margin: "0 100px",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    justifyContent: "space-between",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5.2vw 0",
    },
  },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "700px",
    margin: "50px auto 0",
    [`@media screen and (max-height: 850px)`]: {
      margin: "30px auto 0",
    },
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1vh 1vw",
      width: "85vw",
    },
  },
  inputBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  select: {
    ...theme.typography.subtitle,
    width: "500px",
    height: "35px",
    borderRadius: "23px",
    paddingLeft: "26px",
    [`@media screen and (max-width: 1224px)`]: {
      width: "45vw",
    },
  },
  link: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "20%",
    borderRadius: "15px",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },

  textField: {
    width: "500px",
    margin: 0,
    [`@media screen and (max-width: 1224px)`]: {
      width: "45vw",
    },
  },
}));

interface EditUserProps {
  setState: (state: "none" | "create" | "edit") => void;
  selectedUser: { user: string; role: string; twofa: boolean };
  setSelectedUser: (selectedUser: {
    user: string;
    role: string;
    twofa: boolean;
  }) => void;
  setLoading: (loading: boolean) => void;
  fetchData: () => void;
  setOpen: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
  setError: (open: boolean) => void;
}

export const EditUser: React.FC<EditUserProps> = ({
  setState,
  selectedUser,
  setSelectedUser,
  setLoading,
  fetchData,
  setOpen,
  setSnackbarMessage,
  setError,
}) => {
  const { classes } = useStyles();

  const handleSubmit = () => {
    editUser();
    setState("none");
  };

  const setRole = (role: string) => {
    setSelectedUser({ ...selectedUser, role: role });
  };

  const editUser = async () => {
    try {
      setLoading(true);
      const response = await customFetch.put(`grafana/subUserManagement/edit`, {
        Email: selectedUser.user,
        Role: selectedUser.role,
      });

      if (response.status === 200) {
        setOpen(true);
        setError(false);
        setSnackbarMessage("Role updated successfully.");
      }
    } catch (error: any) {
      let errorMessage = "An error occurred while editing the user.";

      if (error.response && error.response.data) {
        errorMessage = error.response.data;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setOpen(true);
      setError(true);
      setSnackbarMessage("Error creating user: " + errorMessage);

      console.error("Error creating user", error);
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  return (
    <Box className={classes.editUserBox}>
      <Typography variant="subtitle">
        Please proceed editing the selected user. You can always modify the user
        at a later time. Please refer to the{" "}
        <a
          href="https://app.nodemonitoring.io/docs/user-management"
          target="blank"
          className={classes.link}
        >
          documentation
        </a>{" "}
        for the exact permissions of the different user types.
      </Typography>
      <Box className={classes.detailsBox}>
        <Box className={classes.inputBox}>
          <Box>
            <Typography variant="subtitle">E-mail</Typography>
          </Box>
          <Typography className={classes.textField}>
            {selectedUser.user}
          </Typography>
        </Box>
        <Box className={classes.inputBox}>
          <Box>
            <Typography variant="subtitle">Role</Typography>
          </Box>
          <Select
            id="role-select"
            className={classes.select}
            value={
              selectedUser.role.includes("Customer")
                ? selectedUser.role
                : "Customer" + selectedUser.role
            }
            onChange={(e) => setRole(e.target.value)}
            fullWidth
          >
            <MenuItem value="CustomerAdmin">Admin</MenuItem>
            <MenuItem value="CustomerEditor">Editor</MenuItem>
            <MenuItem value="CustomerViewer">Viewer</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => setState("none")}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};
