import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography } from "@mui/material";
import { CodeBlock } from "../code-block/CodeBlock";
import { StackItem } from "../../../../../utils/types";
import { customFetch } from "../../../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  stepThree: {
    margin: "0 0 32px",
    [`@media screen and (max-width: 1600px)`]: {
      margin: "0 0 20px",
    },
  },
  title: {
    margin: "0 0 2.8vh",
  },
}));

interface StepThreeProps {
  currentStack: StackItem;
}

export const StepThree: React.FC<StepThreeProps> = ({ currentStack }) => {
  const { classes } = useStyles();
  const [json, setJson] = useState<any>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [codeBlockHeight, setCodeBlockHeight] = useState<any>("44vh");

  useEffect(() => {
    fetchData();
    const updateHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1800) {
        setCodeBlockHeight("44vh");
      } else if (screenWidth >= 900) {
        setCodeBlockHeight("40vh");
      } else {
        setCodeBlockHeight("44vh");
      }
    };

    window.addEventListener("resize", updateHeight);
    updateHeight();

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("configurator/connection-data");

      setJson({
        ...json,
        hostname: currentStack.hostName,
        connection_config: response.data,
        stack_config: currentStack.tools,
        service_config: currentStack.services,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box className={classes.stepThree}>
      <Typography variant="h2" className={classes.title}>
        Step 3
      </Typography>
      <Typography variant="subtitle">
        Copy and paste the following JSON content into the "nms-config.json"
        file, overwriting any existing content, then save the changes:
      </Typography>
      {!loading && (
        <CodeBlock
          code={JSON.stringify(json, null, 2)}
          height={codeBlockHeight}
        />
      )}
    </Box>
  );
};
