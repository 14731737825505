import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CommonLayout } from "../../components/common-layout/CommonLayout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  textField: {
    ...theme.typography.subtitle,
  },
  link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    margin: "20px auto 35px",
    textDecoration: "underline",
  },
  button: {
    marginBottom: "60px",
    borderRadius: "15px",
  },
}));

interface TwoFactorAuthProps {}

export const TwoFactorAuthPage: React.FC<TwoFactorAuthProps> = () => {
  const { classes } = useStyles();
  const { isLoggedIn, loading, loginTwofa, temporaryKey, errorMessage } =
    useAuth();

  const [code, setCode] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (!temporaryKey && !isLoggedIn()) {
      navigate("/login");
    } else if (isLoggedIn()) {
      navigate("/home");
    }
  }, [temporaryKey, loading, navigate, isLoggedIn]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    loginTwofa(code);
  };

  return (
    <CommonLayout
      title="Two-Factor Authentication"
      desc="Two-Factor Authentication is enabled for this account. Please enter your 2FA Code"
    >
      <form onSubmit={handleSubmit}>
        <TextField
          InputProps={{ classes: { input: classes.textField } }}
          variant="standard"
          margin="normal"
          fullWidth
          id="2fa"
          label="2FA Code"
          name="2fa"
          autoFocus
          value={code}
          onChange={handleCodeChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
        <Typography
          className={classes.link}
          component="a"
          variant="body1"
          onClick={() => navigate("/backup-code")}
        >
          I don't have access to my authentication code
        </Typography>
        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Verify
        </Button>
      </form>
    </CommonLayout>
  );
};
