import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { EnableTwofa } from "./twofa/enable-twofa/EnableTwofa";
import { DisableTwofa } from "./twofa/disable-twofa/DisableTwofa";
import { ApiCredentials } from "./api-credentials/ApiCredentials";
import { ChangePassword } from "./change-password/ChangePassword";
import { Endpoints } from "./endpoint/Endpoints";
import { TwoFA } from "./twofa/TwoFA";
import { useAuth } from "../../core/auth/hooks/useAuth";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { useSidebar } from "../../shared/context/SidebarContext";
import Cookies from "js-cookie";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: "7.4vh 0 5.1vh 5.2vw",
  },
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
  contentBox: {
    display: "flex",
  },
  leftSide: {
    width: "47.4vw",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  viewer: {
    margin: "0 0 50px 100px",
    width: "26vw",
  },
}));

interface SettingsPageProps {}

export const SettingsPage: React.FC<SettingsPageProps> = () => {
  const { classes } = useStyles();
  const { loading } = useAuth();

  const [twofa, setTwofa] = useState(false);
  const [disableTwofa, setDisableTwofa] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { isSidebarOpen } = useSidebar();
  const [isLoading, setIsLoading] = useState(false);

  const role = Cookies.get("role");

  useEffect(() => {
    document.title = "Settings | NMS";
  }, []);

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "21%" : "5%",
          }}
        >
          <Sidebar></Sidebar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            <Box className={classes.snackbar}>
              {!error && (
                <img
                  src="/images/snackbar.png"
                  alt="snackbar"
                  width="35px"
                ></img>
              )}
              {error && (
                <img src="/images/error.png" alt="snackbar" width="35px"></img>
              )}
              {snackbarMessage}
            </Box>
          </Snackbar>
          <Typography variant="h1" className={classes.title}>
            Settings
          </Typography>
          {(loading || isLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {!twofa && !disableTwofa && !loading && !isLoading && (
            <>
              {role !== "CustomerViewer" && (
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <Grid container direction="column" spacing={3}>
                      <Grid item>
                        <ApiCredentials
                          setOpen={setOpenSnackbar}
                          setSnackbarMessage={setSnackbarMessage}
                          setIsLoading={setIsLoading}
                        />
                      </Grid>

                      <Grid item>
                        <Endpoints
                          setOpen={setOpenSnackbar}
                          setSnackbarMessage={setSnackbarMessage}
                          setIsLoading={setIsLoading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <TwoFA
                      setTwofa={setTwofa}
                      setDisableTwofa={setDisableTwofa}
                    />
                  </Grid>
                </Grid>
              )}
              <ChangePassword
                setOpen={setOpenSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                setError={setError}
              ></ChangePassword>
              {role === "CustomerViewer" && (
                <Grid item xs={4} className={classes.viewer}>
                  <TwoFA
                    setTwofa={setTwofa}
                    setDisableTwofa={setDisableTwofa}
                  />
                </Grid>
              )}
            </>
          )}
          {twofa && !loading && (
            <EnableTwofa
              setTwofa={setTwofa}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></EnableTwofa>
          )}
          {disableTwofa && !loading && (
            <DisableTwofa
              disable2fa={setDisableTwofa}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></DisableTwofa>
          )}
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
            marginTop: "9vh",
            height: "100%",
            marginBottom: "calc(56px + 2vh)",
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            <Box className={classes.snackbar}>
              {!error && (
                <img
                  src="/images/snackbar.png"
                  alt="snackbar"
                  width="35px"
                ></img>
              )}
              {error && (
                <img src="/images/error.png" alt="snackbar" width="35px"></img>
              )}
              {snackbarMessage}
            </Box>
          </Snackbar>
          <MobileHeader title="Settings"></MobileHeader>
          {(loading || isLoading) && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          {!twofa && !disableTwofa && !loading && !isLoading && (
            <>
              {role !== "CustomerViewer" && (
                <>
                  <ApiCredentials
                    setOpen={setOpenSnackbar}
                    setSnackbarMessage={setSnackbarMessage}
                    setIsLoading={setIsLoading}
                  />
                  <Endpoints
                    setOpen={setOpenSnackbar}
                    setSnackbarMessage={setSnackbarMessage}
                    setIsLoading={setIsLoading}
                  />
                </>
              )}
              <ChangePassword
                setOpen={setOpenSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                setError={setError}
              ></ChangePassword>
              <TwoFA setTwofa={setTwofa} setDisableTwofa={setDisableTwofa} />
            </>
          )}
          {twofa && !loading && (
            <EnableTwofa
              setTwofa={setTwofa}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></EnableTwofa>
          )}
          {disableTwofa && !loading && (
            <DisableTwofa
              disable2fa={setDisableTwofa}
              setOpen={setOpenSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              setError={setError}
            ></DisableTwofa>
          )}
        </div>
      </MediaQuery>
      <Navbar></Navbar>
    </>
  );
};
