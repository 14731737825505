import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import TableOfContents from "./TableOfContents";

const useStyles = makeStyles()((theme) => ({
  main: {
    display: "flex",
    margin: "80px 60px",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "2vh 5vw 0",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  content: {
    ...theme.typography.body1,
    width: "65%",
    "& code": {
      backgroundColor: "#6D7A8D",
      color: "white",
      padding: "0.1vh 0.1vw",
      fontSize: "110%",
    },
    "& pre": {
      backgroundColor: "#6D7A8D",
      padding: "1.1vh 0.9vw",
      border: "0.1vh solid #E8E9F3",
      borderRadius: "15px",
      borderColor: "grey",
    },
    "& blockquote": {
      backgroundColor: "#E8E9F3",
      padding: "0.2vh 0.6vw",
      fontSize: "95%",
      borderLeft: "0.3vw solid #6D7A8D",
      marginLeft: "0",
      marginRight: "0",
    },
    "& b": {
      color: "#1D426D",
    },
    "& h1": {
      color: "#1D426D",
    },
    "& h2": {
      color: "#1D426D",
    },
    "& h3": {
      color: "#1D426D",
    },
    "& h4": {
      color: "#1D426D",
    },
    "& a": {
      color: "#1D426D",
    },
    "& a:visited": {
      color: "#1D426D",
    },
    "& table": {
      borderCollapse: "collapse",
      width: "100%",
      display: "table",
      borderSpacing: 0,
      marginBottom: "1rem",
      borderRadius: "20px",
      justifyContent: "center",

      "& th": {
        padding: "0.7vh 0.6vw",
        lineHeight: 1.5,
        verticalAlign: "top",
        fontWeight: "bold",
        backgroundColor: "#6D7A8D",
        color: "white",
        textAlign: "center",
      },

      "& td": {
        padding: "0.7vh 0.6vw",
        lineHeight: 1.5,
        verticalAlign: "top",
        textAlign: "center",
      },

      "& tr:nth-child(even)": {
        backgroundColor: "#E8E9F3",
      },
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
    },
  },
}));

type MarkdownRendererProps = React.ComponentProps<"h2">;
type MarkdownLinkRendererProps = React.ComponentProps<"a">;

const DynamicDocs: React.FC = () => {
  const { classes } = useStyles();
  const { docId } = useParams<{ docId: string }>();
  const [markdownContent, setMarkdownContent] = useState<string>();
  const [id, setId] = useState<string>("");

  const renderers = {
    h2: ({ children, ...props }: MarkdownRendererProps) => {
      let id = children
        ?.toString()
        .toLowerCase()
        .replace(/\W/g, "-")
        .replace(/&/g, "and");
      return (
        <h2 id={id} {...props}>
          {children}
        </h2>
      );
    },
    a: ({ children, ...props }: MarkdownLinkRendererProps) => {
      return (
        <a href={props.href} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    },
  };

  useEffect(() => {
    document.title = "NMS | Docs";
    fetch(`../markdown/docs/${docId}.md`)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownContent(text);
      })
      .catch((error) => {
        console.error("Error loading Markdown file:", error);
      });

    if (id) {
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [docId]);

  const changeId = (id: string) => {
    setId(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className={classes.main}>
        <TableOfContents setId={changeId}></TableOfContents>
        <Box className={classes.content}>
          <ReactMarkdown
            remarkPlugins={[gfm]}
            components={renderers}
            children={markdownContent}
          ></ReactMarkdown>
        </Box>
      </div>
    </>
  );
};

export default DynamicDocs;
