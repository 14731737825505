import React, { useState } from "react";
import {
    Typography,
    IconButton,
    Button,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useAuth } from "../auth/hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
    header: {
        display: "flex",
        color: theme.palette.primary.light,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        padding: "1.5vh 0",
        height: "5vh",
        backgroundColor: "white",
        zIndex: 1000,
    },
    logo: {
        marginLeft: "4vw",
        display: "flex",
        justifyContent: "center",
        width: "120px",
    },
    title: {
        ...theme.typography.h1,
        marginLeft: "5vw",
        display: "block",
        justifyContent: "start",
        color: "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "70vw",
    },
    account: {
        padding: "1vh",
        marginRight: "5vw",
        display: "flex",
        justifyContent: "end",
        width: "26px",
        backgroundColor: theme.palette.background2,
        borderRadius: "50%",
    },
    extendedMenu: {
        position: "absolute",
        top: "7vh",
        right: "2vw",
        width: "auto",
        backgroundColor: "#ffffff",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
    },
    buttonLogout: {
        background: theme.palette.button.main,
        borderRadius: "12px",
        minWidth: "30vw",
        height: "4vh",
        margin: "1vh 2vw"
    },
    buttonLogoutText: {
        ...theme.typography.body1,
        color: theme.palette.button.mainText,
        textTransform: "none",
    },
    email: {
        ...theme.typography.subtitle,
        color: theme.palette.primary.light,
        padding: "1vh 2vw",
        textAlign: "center",
        borderBottom: "1px solid #C1C7D0"
    },
}));

interface MobileHeaderProps {
    title?: string;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ title }) => {
    const { classes } = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { logout, email } = useAuth();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = async () => {
        await logout();
        navigate("/login");
    };

    return (
        <>
            <div className={classes.header}>
                {title ?
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                    :
                    <img
                        className={classes.logo}
                        src="/images/sidebar-logo-big.png"
                        alt="logo"
                    />
                }
                <IconButton
                    color="inherit"
                    onClick={() => toggleMenu()}
                >
                    <img
                        className={classes.account}
                        src="/images/sidebar-account.png"
                        alt="account"
                    />
                </IconButton>
                {isMenuOpen && (
                    <div className={classes.extendedMenu}>
                        <Typography className={classes.email}>
                            {email}
                        </Typography>
                        <Button className={classes.buttonLogout} onClick={handleLogout}>
                            <Typography variant="subtitle" className={classes.buttonLogoutText}>
                                Logout
                            </Typography>
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};
