import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography } from "@mui/material";
import { CodeBlock } from "../code-block/CodeBlock";

const useStyles = makeStyles()((theme) => ({
  stepOne: {},
  title: {
    margin: "0 0 2.8vh",
  },
}));

interface StepFourProps {}

export const StepFour: React.FC<StepFourProps> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.stepOne}>
      <Typography variant="h2" className={classes.title}>
        Step 4
      </Typography>
      <Typography variant="subtitle">
        Copy, paste, and run the following command to start the installation
        script:
      </Typography>
      <CodeBlock code="/bin/bash ~/nms-install.sh" />
      <br></br>
      <Typography variant="subtitle">
        When the script runs, you will see a menu with the following options:
        <br></br>
        <br></br>
        {"  "}• Deploy NMS<br></br>
        {"  "}• Apply new configuration<br></br>
        {"  "}• Uninstall NMS<br></br>
        <br></br>
        From here, you can choose the appropriate option based on your needs,
        whether you are deploying an instance of NMS for the first time,
        applying a new host configuration, or uninstalling it.
      </Typography>
    </Box>
  );
};
