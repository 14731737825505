import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { customFetch } from "../../utils/axios";
import { AlertItem } from "./alert-item/AlertItem";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { useSidebar } from "../../shared/context/SidebarContext";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: "7.4vh 0 5.6vh 2.3vw",
  },
  content: {
    margin: "0 0 3.7vh 4.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 7.3vw 0",
    },
  },
  text: {
    margin: "0 5.2vw 3.7vh 0",
    [`@media screen and (max-width: 1224px)`]: {
      color: theme.palette.primary.light,
      margin: "0 0 3vh 0",
      fontSize: "13px",
    },
  },
  selection: {
    display: "flex",
    margin: "3.7vh 0",
    gap: "0.5vw",
  },
  scrollBox: {
    display: "flex",
    gap: "1vw",
    overflowX: "auto",
    width: "100%",
    paddingBottom: "1.5vh",
    [`@media screen and (max-width: 1224px)`]: {
      flexDirection: "column",
      gap: "2vh",
    },
  },
  categoryButton: {
    ...theme.typography.subtitle,
    borderRadius: "23px",
    padding: "0.5vh 1vw",
    color: theme.palette.primary.light,
    "&:hover": {
      cursor: "pointer",
      background: "#E8E9F341 !important",
      color: theme.palette.primary.dark,
    },
  },
  selectedCategoryButton: {
    ...theme.typography.subtitle,
    borderRadius: "23px",
    padding: "0.5vh 1vw",
    background: "#E8E9F3",
    "&:hover": {
      cursor: "pointer",
      background: "#E8E9F3 !important",
      color: theme.palette.primary.dark,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  link: {
    color: "#1D426D",
    "&:visited": {
      color: "#1D426D",
    },
  },
  hidden: {
    display: "none",
  },
}));

enum Categories {
  CHAINLINK = "chainlink",
  RPC = "rpc",
  EXTERNAL = "external-adapter",
  OTHERS = "others",
  ALL = "all",
}

export type Alert = {
  id: number;
  name: string;
  category: Categories;
  description: string;
  isInstalled: boolean;
};

interface AlertStorePageProps {}

export const AlertStorePage: React.FC<AlertStorePageProps> = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [visibleAlerts, setVisibleAlerts] = useState<Alert[]>();
  const { isSidebarOpen } = useSidebar();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.title = "Alert Store | NMS";
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("grafana/alerts");

      setVisibleAlerts(
        response.data
          .map((alert: any) => {
            return {
              id: alert.alertId,
              name: alert.name,
              description: alert.description,
              isInstalled: alert.isInstalled,
            };
          })
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
      );

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "24%" : "8%",
          }}
        >
          <Sidebar></Sidebar>
          <Typography variant="h1" className={classes.title}>
            Alert Store
          </Typography>

          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!loading ? "" : classes.hidden}>
            <Box className={classes.content}>
              <Box className={classes.text}>
                <Typography variant="subtitle">
                  NMS alerts have been optimized by LinkRiver over several years
                  with a focus on Chainlink node key performance indicators.
                  Installed alert bundles are automatically added to the{" "}
                  <a
                    href={process.env.REACT_APP_GRAFANA + "alerting"}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    alerting section
                  </a>{" "}
                  of the Dashboard Platform, where you can further configure
                  them. Each alert bundle consists of several specific alerts
                  and forms an evaluation group. If you want to make use of the
                  NMS OnCall solution, our team will implement it for you. Read
                  more about NMS alerting in our{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/docs/alert-store"}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    docs
                  </a>
                  .
                </Typography>
              </Box>
              <Box className={classes.scrollBox} ref={scrollRef}>
                {visibleAlerts?.map((alert) => {
                  return (
                    <AlertItem
                      key={alert.id}
                      alert={alert}
                      fetchData={fetchData}
                    ></AlertItem>
                  );
                })}
              </Box>
            </Box>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
            marginBottom: "56px",
          }}
        >
          <MobileHeader title="Alert Store"></MobileHeader>

          {loading && (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          )}
          <div className={!loading ? "" : classes.hidden}>
            <Box className={classes.content}>
              <Box className={classes.text}>
                <Typography variant="subtitle">
                  NMS alerts have been optimized by LinkRiver over several years
                  with a focus on Chainlink node key performance indicators.
                  Installed alert bundles are automatically added to the{" "}
                  <a
                    href={process.env.REACT_APP_GRAFANA + "alerting"}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    alerting section
                  </a>{" "}
                  of the Dashboard Platform, where you can further configure
                  them. Each alert bundle consists of several specific alerts
                  and forms an evaluation group. If you want to make use of the
                  NMS OnCall solution, our team will implement it for you. Read
                  more about NMS alerting in our{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/docs/alert-store"}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    docs
                  </a>
                  .
                </Typography>
              </Box>
              <Box className={classes.scrollBox} ref={scrollRef}>
                {visibleAlerts?.map((alert) => {
                  return (
                    <AlertItem
                      key={alert.id}
                      alert={alert}
                      fetchData={fetchData}
                    ></AlertItem>
                  );
                })}
              </Box>
            </Box>
          </div>
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
