import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  CurrentEditService,
  CurrentService,
  EditService,
  Service,
  StackItem,
} from "../../../../utils/types";
import { customFetch } from "../../../../utils/axios";
import { ServiceState } from "../../Configurator";
import { StackState } from "../../../../shared/context/StackStateContext";
import { InfoBox } from "../../../../shared/InfoBox";

const useStyles = makeStyles()((theme) => ({
  nodeDetails: {
    margin: "0 7.3vw 3.7vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    flex: 1,
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 5vw 0",
    },
  },
  detailsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "3.2vh",
    width: "36.5vw",
    margin: "4.6vh auto 0",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "4vh 0 0",
      width: "100%",
    },
  },
  inputBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  inputProps: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.dark,
    marginLeft: "2.1vw",
  },
  textField: {
    width: "26vw",
    margin: 0,
    "& .MuiFormHelperText-root": {
      marginLeft: "2.1vw",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "55vw",
    },
  },
  loading: {
    display: "flex",
    position: "relative",
    top: "9.3vh",
    left: "50%",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "26vw",
    "& label .MuiTypography-root": {
      ...theme.typography.subtitle,
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "55vw",
    },
  },
  title: {
    marginBottom: "2.3vh",
  },
  textFieldLabel: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    ...theme.typography.subtitle,
    width: "26vw",
    height: "3.2vh",
    borderRadius: "23px",
    paddingLeft: "1.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "55vw",
    },
  },
  buttonBox: {
    marginTop: "1.9vh",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3.7vh",
  },
  buttonBoxEnd: {
    marginTop: "1.9vh",
    display: "flex",
    justifyContent: "end",
    marginBottom: "3.7vh",
  },
  buttonBack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  icon: {
    height: "1.1vw",
    width: "1.1vw",
    marginLeft: "0.5vw",
    [`@media screen and (max-width: 1224px)`]: {
      height: "3vw",
      width: "3vw",
    },
  },
}));

interface ServiceDetailsProps {
  onBack: (number: number) => void;
  onNext: () => void;
  currentStack: StackItem;
  setCurrentStack: React.Dispatch<React.SetStateAction<StackItem>>;
  currentService: CurrentService;
  setCurrentService: React.Dispatch<React.SetStateAction<CurrentService>>;
  stackState: StackState;
  serviceState: ServiceState;
  setServiceState: React.Dispatch<React.SetStateAction<ServiceState>>;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  onBack,
  onNext,
  currentStack,
  setCurrentStack,
  currentService,
  setCurrentService,
  stackState,
  serviceState,
  setServiceState,
}) => {
  const { classes } = useStyles();
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [serviceNameError, setServiceNameError] = useState<boolean>(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [ipError, setIpError] = useState<boolean>(false);
  const [portError, setPortError] = useState<boolean>(false);
  const [portNumberError, setPortNumberError] = useState<boolean>(false);
  const [pathError, setPathError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNetwork, setShowNetwork] = useState<boolean>(
    currentService.label === "rpc-node"
  );
  const [editServiceName, setEditServiceName] = useState<string>("");
  const [showNameInfo, setShowNameInfo] = useState<boolean>(false);
  const [showLabelInfo, setShowLabelInfo] = useState<boolean>(false);
  const [showNetworkInfo, setShowNetworkInfo] = useState<boolean>(false);
  const [showIpInfo, setShowIpInfo] = useState<boolean>(false);
  const [showPortInfo, setShowPortInfo] = useState<boolean>(false);
  const [showPathInfo, setShowPathInfo] = useState<boolean>(false);
  const [showProtocolInfo, setShowProtocolInfo] = useState<boolean>(false);
  const [currentEditService, setCurrentEditService] =
    useState<CurrentEditService>({
      id: 0,
      name: "",
      label: "",
      service: {
        id: 0,
        name: "",
        label: "",
        serviceName: "",
        ip: "",
        port: "",
        path: "",
        protocol: "",
        network: "",
      },
    });

  useEffect(() => {
    if (serviceState === ServiceState.NONE || serviceState === ServiceState.ADD)
      fetchData(
        currentService.service.id || 0,
        currentService.service.name || "",
        currentService.service.serviceName || ""
      );
    if (
      (serviceState === ServiceState.EDIT ||
        serviceState === ServiceState.DUPLICATE) &&
      currentService.service.serviceName
    ) {
      setCurrentEditService({
        id: currentService.id,
        name: currentService.name,
        label: currentService.label,
        service: {
          ...currentService.service,
          port: currentService.service.port
            ? String(currentService.service.port)
            : undefined,
        },
      });

      if (serviceState === ServiceState.EDIT) {
        setEditServiceName(currentService.service.serviceName);
      }
    }

    setLoading(false);
  }, []);

  const fetchData = async (id: number, name: string, serviceName: string) => {
    try {
      const response = await customFetch.get(
        `configurator/service-details?id=${id}`
      );

      setCurrentEditService({
        ...currentService,
        service: {
          id: id,
          name: name,
          serviceName: serviceName,
          label: response.data.label,
          ip: response.data.ip,
          port: response.data.port,
          path: response.data.path,
          protocol: response.data.protocol,
          network: "",
        },
      });

      setCurrentService({
        ...currentService,
        service: {
          id: id,
          name: name,
          serviceName: serviceName,
          label: response.data.label,
          ip: response.data.ip,
          port: response.data.port,
          path: response.data.path,
          protocol: response.data.protocol,
          network: "",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const changeServiceName = (serviceName: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          serviceName: serviceName,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          serviceName: serviceName,
        },
      };
      return updatedService;
    });
  };

  const changeLabel = (label: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          label: label,
        },
      };
      if (label === "rpc-node") {
        setShowNetwork(true);
      } else {
        setShowNetwork(false);
      }
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          label: label,
        },
      };
      if (label === "rpc-node") {
        setShowNetwork(true);
      } else {
        setShowNetwork(false);
      }
      return updatedService;
    });
  };

  const changeNetwork = (network: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          network: network,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          network: network,
        },
      };
      return updatedService;
    });
  };

  const changeIp = (ip: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          ip: ip,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          ip: ip,
        },
      };
      return updatedService;
    });
  };

  const changePort = (port: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          port: port,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          port: isNaN(Number(port)) ? 0 : Number(port),
        },
      };
      return updatedService;
    });
  };

  const changePath = (path: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          path: path,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          path: path,
        },
      };
      return updatedService;
    });
  };

  const changeProtocol = (protocol: string) => {
    setCurrentEditService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          protocol: protocol,
        },
      };
      return updatedService;
    });

    setCurrentService((prevService) => {
      const updatedService = {
        ...prevService,
        service: {
          ...prevService.service,
          protocol: protocol,
        },
      };
      return updatedService;
    });
  };

  const validate = (service: EditService) => {
    const validations = {
      isNotDuplicate:
        !currentStack.services.some(
          (serv) => serv.serviceName === service.serviceName
        ) || service.serviceName === editServiceName,
      hasServiceName:
        service?.serviceName !== undefined && service?.serviceName !== "",
      hasNetwork: service.label === "rpc-node" ? service?.network !== "" : true,
      hasIp: service?.ip !== "",
      hasPort: service?.port !== "",
      isPortNumber: !isNaN(Number(service.port)),
      hasPath: service?.path !== "",
    };

    setDuplicateError(!validations.isNotDuplicate);
    setServiceNameError(!validations.hasServiceName);
    setNetworkError(!validations.hasNetwork);
    setIpError(!validations.hasIp);
    setPortError(!validations.hasPort);
    setPortNumberError(!validations.isPortNumber);
    setPathError(!validations.hasPath);

    return Object.values(validations).every(Boolean);
  };

  const onNextClick = () => {
    if (!validate(currentEditService.service)) {
      return;
    }

    if (
      stackState === StackState.CREATE &&
      serviceState === ServiceState.NONE
    ) {
      setCurrentStack({
        ...currentStack,
        services: [{ ...currentService.service }],
      });
    }
    if (serviceState === ServiceState.DUPLICATE && currentService) {
      setCurrentStack({
        ...currentStack,
        services: [...currentStack.services, currentService.service],
      });
    }
    if (serviceState === ServiceState.EDIT && currentService) {
      const updatedServices = currentStack.services.map((service) => {
        if (service.serviceName === editServiceName) {
          return currentService.service;
        }
        return service;
      });

      setCurrentStack({
        ...currentStack,
        services: updatedServices,
      });
    }
    if (serviceState === ServiceState.ADD) {
      setCurrentStack({
        ...currentStack,
        services: [...currentStack.services, { ...currentService.service }],
      });
    }

    setServiceState(ServiceState.NONE);
    onNext();
  };

  const onBackClick = () => {
    if (
      currentService.name === "External Adapter" ||
      currentService.name === "Chainlink Node"
    ) {
      onBack(2);
    } else {
      onBack(3);
    }
  };

  return (
    <Box className={classes.nodeDetails}>
      {showNameInfo && (
        <InfoBox
          showInfo={showNameInfo}
          setShowInfo={setShowNameInfo}
          title="Service Name"
        >
          <>
            This is where you specify a unique name for the service you are
            adding. This name will be displayed on your dashboards for easy
            identification and filtering. For a better overview, it is advisable
            to use the service's container name.
          </>
        </InfoBox>
      )}
      {showLabelInfo && (
        <InfoBox
          showInfo={showLabelInfo}
          setShowInfo={setShowLabelInfo}
          title="Label"
        >
          <>
            Labels are used to categorize and group services within your
            monitoring organization. These labels facilitate organized viewing
            and allocate services to dashboards.
          </>
        </InfoBox>
      )}
      {showNetworkInfo && (
        <InfoBox
          showInfo={showNetworkInfo}
          setShowInfo={setShowNetworkInfo}
          title="Network"
        >
          <>
            Please specify the blockchain network of this RPC node. This allows
            you to group it with others on the same network within the Dashboard
            Platform.
          </>
        </InfoBox>
      )}
      {showIpInfo && (
        <InfoBox showInfo={showIpInfo} setShowInfo={setShowIpInfo} title="Ip">
          <>
            Indicate the IP address where the service is hosted. In a normal
            setup you should go with the default value of 127.0.0.1 (localhost).
          </>
        </InfoBox>
      )}
      {showPathInfo && (
        <InfoBox
          showInfo={showPathInfo}
          setShowInfo={setShowPathInfo}
          title="Path"
        >
          <>
            Identify the endpoint path where the metrics are collected. By
            default, this is set to /metrics, but you can modify it according to
            your service configuration to point to the appropriate metrics
            gathering endpoint.
          </>
        </InfoBox>
      )}
      {showPortInfo && (
        <InfoBox
          showInfo={showPortInfo}
          setShowInfo={setShowPortInfo}
          title="Port"
        >
          <>
            Specify the port number on which the service is running. This
            information is vital for establishing a successful connection
            between the data collection stack and the service.
          </>
        </InfoBox>
      )}
      {showProtocolInfo && (
        <InfoBox
          showInfo={showProtocolInfo}
          setShowInfo={setShowProtocolInfo}
          title="Protocol"
        >
          <>
            Indicate the protocol the service uses for communication. You can
            choose between http or https, depending on the service's setup and
            your security requirements. Choosing https ensures encrypted and
            secure data transfer but may require additional configuration on
            your host. By default, this is set to http because the data is
            retrieved from the local host.
          </>
        </InfoBox>
      )}
      <Box>
        <Typography variant="h2" className={classes.title}>
          Service configuration
        </Typography>
        <Typography variant="subtitle">
          Please proceed to add or modify the service details. This will enable
          the local scraper tools from step 1 to access and gather your metrics.
          If you have multiple services running on the same host, you can add
          additional ones on the next screen.
        </Typography>
        {loading && <CircularProgress className={classes.loading} />}
        {!loading && (
          <Box className={classes.detailsBox}>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Service Name</Typography>
                <Tooltip
                  arrow
                  title="This is where you specify a unique name for the service you are adding. This name will be displayed on your dashboards for easy identification and filtering. For a better overview, it is advisable to use the service's container name."
                  placement="top-start"
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowNameInfo(!showNameInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <TextField
                className={classes.textField}
                InputProps={{ classes: { input: classes.inputProps } }}
                inputProps={{ maxLength: 30 }}
                variant="standard"
                margin="normal"
                fullWidth
                name="job-name"
                type="text"
                id="job-name"
                value={currentEditService?.service.serviceName || ""}
                onChange={(e) => changeServiceName(e.currentTarget.value)}
                error={serviceNameError || duplicateError}
                helperText={
                  serviceNameError
                    ? "Service name is required."
                    : duplicateError
                    ? "Service names should be unique."
                    : null
                }
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Label</Typography>
                <Tooltip
                  arrow
                  title="Labels are used to categorize and group services within your monitoring organization. These labels facilitate organized viewing and allocate services to dashboards."
                  placement="top-start"
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowLabelInfo(!showLabelInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <Select
                id="label-select"
                className={classes.select}
                value={currentEditService?.service.label || ""}
                onChange={(e) => changeLabel(e.target.value)}
                fullWidth
              >
                <MenuItem value="rpc-node">RPC Node</MenuItem>
                <MenuItem value="chainlink-node">Chainlink Node</MenuItem>
                <MenuItem value="external-adapter">External Adapter</MenuItem>
                <MenuItem value="others">Others</MenuItem>
              </Select>
            </Box>
            {showNetwork && (
              <Box className={classes.inputBox}>
                <Box>
                  <Typography variant="subtitle">Network</Typography>
                  <Tooltip
                    arrow
                    title="Please specify the blockchain network of this RPC node. This allows you to group it with others on the same network within the Dashboard Platform."
                    placement="top-start"
                  >
                    <img
                      src="/images/details-info.png"
                      alt="info"
                      className={classes.icon}
                      onClick={() => setShowNetworkInfo(!showNetworkInfo)}
                    ></img>
                  </Tooltip>
                </Box>
                <TextField
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.inputProps } }}
                  inputProps={{ maxLength: 30 }}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  name="network"
                  type="text"
                  id="network"
                  value={currentEditService?.service.network}
                  onChange={(e) => changeNetwork(e.currentTarget.value)}
                  error={networkError}
                  helperText={networkError ? "Network is required." : ""}
                />
              </Box>
            )}
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">IP</Typography>
                <Tooltip
                  arrow
                  title="Indicate the IP address where the service is hosted. In a normal setup you should go with the default value of 127.0.0.1 (localhost)."
                  placement="top-start"
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowIpInfo(!showIpInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <TextField
                className={classes.textField}
                InputProps={{ classes: { input: classes.inputProps } }}
                inputProps={{ maxLength: 15 }}
                variant="standard"
                margin="normal"
                fullWidth
                name="ip"
                type="text"
                id="ip"
                value={currentEditService?.service.ip || ""}
                onChange={(e) => changeIp(e.currentTarget.value)}
                error={ipError}
                helperText={ipError ? "IP is required." : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Port</Typography>
                <Tooltip
                  arrow
                  title="Specify the port number on which the service is running. This information is vital for establishing a successful connection between the data collection stack and the service."
                  placement="top-start"
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowPortInfo(!showPortInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <TextField
                className={classes.textField}
                InputProps={{ classes: { input: classes.inputProps } }}
                inputProps={{ maxLength: 5 }}
                variant="standard"
                margin="normal"
                fullWidth
                name="port"
                type="text"
                id="port"
                value={currentEditService?.service.port}
                onChange={(e) => changePort(e.currentTarget.value)}
                error={portError || portNumberError}
                helperText={
                  portError
                    ? "Port is required."
                    : portNumberError
                    ? "Port should be a number."
                    : ""
                }
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Path</Typography>
                <Tooltip
                  arrow
                  title="Identify the endpoint path where the metrics are collected. By default, this is set to /metrics, but you can modify it according to your service configuration to point to the appropriate metrics gathering endpoint."
                  placement="top-start"
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowPathInfo(!showPathInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <TextField
                className={classes.textField}
                InputProps={{ classes: { input: classes.inputProps } }}
                inputProps={{ maxLength: 30 }}
                variant="standard"
                margin="normal"
                fullWidth
                name="path"
                type="string"
                id="path"
                value={currentEditService?.service.path || ""}
                onChange={(e) => changePath(e.currentTarget.value)}
                error={pathError}
                helperText={pathError ? "Path is required." : ""}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Box>
                <Typography variant="subtitle">Protocol</Typography>
                <Tooltip
                  arrow
                  title="Indicate the protocol the service uses for communication. You can choose between http or https, depending on the service's setup and your security requirements. Choosing https ensures encrypted and secure data transfer but may require additional configuration on your host. By default, this is set to http because the data is retrieved from the local host."
                  placement="top-start"
                  sx={{
                    padding: "30px",
                  }}
                >
                  <img
                    src="/images/details-info.png"
                    alt="info"
                    className={classes.icon}
                    onClick={() => setShowProtocolInfo(!showProtocolInfo)}
                  ></img>
                </Tooltip>
              </Box>
              <RadioGroup
                row
                name="protocol"
                value={currentEditService?.service.protocol || "http"}
                onChange={(e) => changeProtocol(e.target.value)}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="http"
                  control={<Radio />}
                  label="HTTP"
                />
                <FormControlLabel
                  value="https"
                  control={<Radio />}
                  label="HTTPS"
                />
              </RadioGroup>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        className={
          !(
            serviceState === ServiceState.ADD ||
            serviceState === ServiceState.NONE
          )
            ? classes.buttonBoxEnd
            : classes.buttonBox
        }
      >
        {(serviceState === ServiceState.ADD ||
          serviceState === ServiceState.NONE) && (
          <Button className={classes.buttonBack} onClick={() => onBackClick()}>
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Back
            </Typography>
          </Button>
        )}
        <Button className={classes.buttonNext} onClick={onNextClick}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
