import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Typography } from "@mui/material";
import { CodeBlock } from "../code-block/CodeBlock";

const useStyles = makeStyles()((theme) => ({
  stepOne: {},
  title: {
    margin: "0 0 2.8vh",
  },
  code: {
    ...theme.typography.subtitle,
  },
}));

interface StepOneProps {}

export const StepOne: React.FC<StepOneProps> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.stepOne}>
      <Typography variant="h2" className={classes.title}>
        Step 1
      </Typography>
      <Typography variant="subtitle">
        Access the machine for which this configuration was created, then copy
        and paste the command into the terminal. Running the command will
        download the deployment script to your home directory. If the script
        already exists, it will be overwritten with the latest version.
      </Typography>
      <CodeBlock code="cd ~ && wget -O nms-install.sh https://raw.githubusercontent.com/NodeMonitoringService/nms-deployment-script/main/nms-install.sh" />
    </Box>
  );
};
