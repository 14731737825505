import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { customFetch } from "../../../utils/axios";

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    margin: "0 7.3vw 2.8vh",
    width: "32.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "90vw",
      margin: "0 5vw 2.3vh",
      fontWeight: "1.5"
    },
  },
  textfield: {
    margin: "0 7.3vw 1.9vh",
    display: "flex",
    width: "32.8vw",
    [`@media screen and (max-width: 1224px)`]: {
      marginBottom: "2.3vh",
      width: "85vw",
    },
  },
  label: {
    width: "9.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "20vw",
    },
  },
  icons: {
    display: "flex",
    gap: "1vw",
    margin: "0 1vw",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    width: "23.4vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "65vw",
    },
  },
}));

interface EndpointsProps {
  setOpen: (open: boolean) => void;
  setIsLoading: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
}

export const Endpoints: React.FC<EndpointsProps> = ({
  setSnackbarMessage,
  setOpen,
  setIsLoading,
}) => {
  const { classes } = useStyles();

  const [prometheus, setPrometheus] = useState<string>("");
  const [loki, setLoki] = useState<string>("");

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, []);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("configurator/connection-data");
      setLoki(`https://${response.data.logsUrl}/${response.data.orgName}/`);
      setPrometheus(
        `https://${response.data.metricsUrl}/${response.data.orgName}/`
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <Typography variant="h2" className={classes.subtitle}>
        Your Endpoints
      </Typography>
      <Box className={classes.textfield}>
        <Typography className={classes.label} variant="subtitle">
          Metrics
        </Typography>
        <TextField
          className={classes.input}
          variant="standard"
          type={"text"}
          value={prometheus}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.icons} position="end">
                <img
                  src="/images/textfield-copy.png"
                  alt="copy"
                  width="20px"
                  onClick={() => {
                    copyToClipboard(prometheus);
                    setSnackbarMessage("Copied to clipboard.");
                    setOpen(true);
                  }}
                />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
      <Box className={classes.textfield}>
        <Typography className={classes.label} variant="subtitle">
          Logs
        </Typography>
        <TextField
          className={classes.input}
          variant="standard"
          type={"text"}
          value={loki}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.icons} position="end">
                <img
                  src="/images/textfield-copy.png"
                  alt="copy"
                  width="20px"
                  onClick={() => {
                    copyToClipboard(loki);
                    setSnackbarMessage("Copied to clipboard.");
                    setOpen(true);
                  }}
                />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
    </>
  );
};
