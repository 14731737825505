import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CommonLayout } from "../../components/common-layout/CommonLayout";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  textField: {
    ...theme.typography.subtitle,
  },
  link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.light,
    margin: "20px auto 35px",
    cursor: "pointer",
    textDecoration: "underline",
  },
  button: {
    marginBottom: "60px",
    borderRadius: "15px",
  },
}));

interface BackupCodeProps {}

export const BackupCodePage: React.FC<BackupCodeProps> = () => {
  const { classes } = useStyles();
  const [code, setCode] = useState<string>("");
  const { temporaryKey, loading, loginRecoveryCode, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (isLoggedIn()) {
      navigate("/home");
    }
  }, [loading, navigate, isLoggedIn, temporaryKey]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    loginRecoveryCode(code);
  };

  return (
    <CommonLayout
      title="Log In with Backup code"
      desc="Please enter one of your 2FA backup codes. If you don't have access to your backup codes, please contact our support"
    >
      <form onSubmit={handleSubmit}>
        <TextField
          InputProps={{ classes: { input: classes.textField } }}
          variant="standard"
          margin="normal"
          fullWidth
          label="Backup code"
          autoFocus
          value={code}
          onChange={handleCodeChange}
        />
        <Typography
          className={classes.link}
          component="a"
          variant="body1"
          onClick={() => navigate("/2fa")}
        >
          Back to 2FA
        </Typography>
        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Verify
        </Button>
      </form>
    </CommonLayout>
  );
};
