import React from "react";
import { makeStyles } from "tss-react/mui";
import { Alert, Box, Button, Typography } from "@mui/material";
import { CurrentService, StackItem } from "../../../../utils/types";
import ServiceBox from "./service-box/ServiceBox";
import { customFetch } from "../../../../utils/axios";
import { ServiceState } from "../../Configurator";
import { useNavigate } from "react-router-dom";
import { StackState } from "../../../../shared/context/StackStateContext";

const useStyles = makeStyles()((theme) => ({
  serviceTable: {
    margin: "0 7.3vw 3.7vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    flex: 1,
    [`@media screen and (max-width: 1224px)`]: {
      margin: "1vh 5vw 0",
    },
  },
  title: {
    marginBottom: "2.3vh",
  },
  description: {
    display: "flex",
    marginBottom: "3.7vh",
    color: theme.palette.primary.light,
    [`@media screen and (max-width: 1224px)`]: {
      marginBottom: "3vh",
    },
  },
  serviceBox: {
    display: "flex",
    flexDirection: "column",
    gap: "1.9vh",
    height: "100%",
    marginBottom: "1.9vh",
    overflowY: "auto",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    [`@media screen and (max-width: 1224px)`]: {
      marginBottom: "3.7vh",
    },
  },
  buttonBack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface ServiceTableProps {
  onBack: (stepNumber?: number) => void;
  onNext: (stepNumber?: number) => void;
  fetchData: () => void;
  currentStack: StackItem;
  currentService: CurrentService;
  setCurrentService: React.Dispatch<React.SetStateAction<CurrentService>>;
  saved: boolean;
  setSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStack: React.Dispatch<React.SetStateAction<StackItem>>;
  stackState: StackState;
  setServiceState: React.Dispatch<React.SetStateAction<ServiceState>>;
  stackId?: string;
}

export const ServiceTable: React.FC<ServiceTableProps> = ({
  onBack,
  onNext,
  currentStack,
  currentService,
  setCurrentStack,
  setCurrentService,
  stackState,
  setServiceState,
  stackId,
  fetchData,
  saved,
  setSaved,
}) => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const onBackClick = () => {
    if (!currentStack.services.length) {
      setCurrentStack({
        ...currentStack,
        services: [
          {
            id: 0,
            name: "",
            label: "",
            serviceName: "",
            ip: "",
            port: 0,
            path: "",
            protocol: "",
            network: "",
          },
        ],
      });
    }
    onBack(1);
  };

  const onEdit = (serviceName: string) => {
    onNext(4);
    const service = currentStack.services.find(
      (service) => service.serviceName === serviceName
    );
    if (service) {
      setCurrentService({ ...currentService, service: service });
    }
    setServiceState(ServiceState.EDIT);
  };

  const onDuplicate = (serviceName: string) => {
    onNext(4);
    const service = currentStack.services.find(
      (service) => service.serviceName === serviceName
    );
    if (service) {
      setCurrentService({
        id: 0,
        name: "",
        label: "",
        service: {
          id: service.id,
          name: service.name,
          label: service.label,
          serviceName: service.serviceName,
          ip: service.ip,
          port: service.port,
          path: service.path,
          protocol: service.protocol,
          network: service.network,
        },
      });
    }
    setServiceState(ServiceState.DUPLICATE);
  };

  const onDelete = (serviceName: string) => {
    if (currentStack.services.length <= 1 && stackState === StackState.CREATE) {
      navigate(0);
    }

    setCurrentStack({
      ...currentStack,
      services: [
        ...currentStack.services.filter(
          (service) => service.serviceName !== serviceName
        ),
      ],
    });
  };

  const onSave = () => {
    if (currentStack.services.length < 1) {
      return;
    }

    setCurrentStack({ ...currentStack, lastModified: new Date() });

    if (stackState === StackState.EDIT || saved === true) {
      updateData();
      setCurrentService({
        id: 0,
        name: "",
        label: "",
        service: {
          id: 0,
          name: "",
          label: "",
          serviceName: "",
          ip: "",
          port: 0,
          path: "",
          protocol: "",
          network: "",
        },
      });
      onNext(6);
    } else {
      saveData();
      setCurrentService({
        id: 0,
        name: "",
        label: "",
        service: {
          id: 0,
          name: "",
          label: "",
          serviceName: "",
          ip: "",
          port: 0,
          path: "",
          protocol: "",
          network: "",
        },
      });
      setSaved(true);
      onNext(6);
    }
  };

  const saveData = async () => {
    try {
      await customFetch.post("configurator/create", {
        jsonName: currentStack.hostName,
        jsonContent: JSON.stringify(
          {
            hostname: currentStack.hostName,
            tools: currentStack.tools,
            services: currentStack.services,
          },
          null,
          2
        ),
      });

      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      await customFetch.post(`configurator/create/`, {
        jsonId: stackId,
        jsonName: currentStack.hostName,
        jsonContent: JSON.stringify(
          {
            hostname: currentStack.hostName,
            tools: currentStack.tools,
            services: currentStack.services,
          },
          null,
          2
        ),
      });

      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box className={classes.serviceTable}>
      <Box>
        <Typography variant="subtitle" className={classes.description}>
          Please review your configured services. If you would like to add
          another service to this host, you can do so by simply clicking on the
          “Add Service” button or duplicating an already existing service.
        </Typography>{" "}
        {currentStack.services.length < 1 && (
          <Alert severity="warning">You need at least one service.</Alert>
        )}
        <Box className={classes.serviceBox}>
          {currentStack.services.map((service, index) => (
            <ServiceBox
              key={service.id! + index}
              service={service}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
              onDelete={onDelete}
            />
          ))}
        </Box>
      </Box>
      <Box className={classes.buttonBox}>
        <Button className={classes.buttonBack} onClick={() => onBackClick()}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Back
          </Typography>
        </Button>
        <Button className={classes.buttonNext} onClick={() => onSave()}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Save and Deploy
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
