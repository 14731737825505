import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Typography } from "@mui/material";
import { useAuth } from "../../../core/auth/hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  twofaBox: {
    background: theme.palette.background2,
    borderRadius: "23px",
    display: "flex",
    marginRight: "3.1vw",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    [`@media screen and (max-width: 1224px)`]: {
      justifyContent: "normal",
      margin: "0 5vw 2vh",
      height: "auto",
    },
  },
  text: {
    padding: "2.6vh 1.7vw",
    display: "flex",
    flexDirection: "column",
    gap: "1.3vw",
    [`@media screen and (max-width: 1224px)`]: {
      padding: "2vh 3vw",
    },
  },
  buttonBlack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    height: "4.6vh",
    margin: "1.3vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "0 2vw 2vh",
    },
  },
  buttonBlackText: {
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface TwoFAProps {
  setTwofa: (open: boolean) => void;
  setDisableTwofa: (open: boolean) => void;
}

export const TwoFA: React.FC<TwoFAProps> = ({ setTwofa, setDisableTwofa }) => {
  const { classes } = useStyles();
  const { setLoading, hasTwofa } = useAuth();
  const [has2fa, setHas2fa] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchTwoFactorData() {
      const response = await hasTwofa();
      setHas2fa(response);
    }

    fetchTwoFactorData();
    setLoading(false);
  }, [hasTwofa, setLoading]);

  return (
    <Box className={classes.twofaBox}>
      <Box className={classes.text}>
        <Typography variant="h2">Two-Factor Authentication</Typography>
        <Typography variant="subtitle">
          Add an extra layer of security to your account. This feature is
          optional, but highly recommended.
        </Typography>
      </Box>
      {has2fa ? (
        <Button
          className={classes.buttonBlack}
          onClick={() => setDisableTwofa(true)}
        >
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Disable 2FA
          </Typography>
        </Button>
      ) : (
        <Button className={classes.buttonBlack} onClick={() => setTwofa(true)}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Set Up 2FA
          </Typography>
        </Button>
      )}
    </Box>
  );
};
