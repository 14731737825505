import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useAuth } from "../../../../core/auth/hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  twofa: {
    margin: "0 7.3vw 3.7vh",
    display: "flex",
    flexDirection: "column",

  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  twofaTitle: {
    marginBottom: "25px",
  },
  qrBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-evenly",
    gap: "20px",
    marginTop: "50px",
    border: `1px solid ${theme.palette.background2}`,
    borderRadius: "23px",
    height: "450px",
    width: "30%",
  },
  textfield: {
    display: "flex",
    margin: "50px 0",
  },
  label: {
    width: "13vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  input: {
    width: "26vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBox: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface DisableTwofaProps {
  disable2fa: (disableTwofa: boolean) => void;
  setOpen: (open: boolean) => void;
  setSnackbarMessage: (message: string) => void;
  setError: (open: boolean) => void;
}

export const DisableTwofa: React.FC<DisableTwofaProps> = ({
  disable2fa,
  setOpen,
  setSnackbarMessage,
  setError,
}) => {
  const { classes } = useStyles();
  const { disableTwofa } = useAuth();
  const [authKey, setAuthKey] = useState<string>("");

  let enableResponse: any;

  const onClick = () => {
    async function fetchEnableTwofa() {
      enableResponse = await disableTwofa(authKey);

      if (enableResponse.response && enableResponse.response.status === 400) {
        setOpen(true);
        setError(true);
        setSnackbarMessage("Incorrect code.");
      } else {
        disable2fa(false);
        setError(false);
        setOpen(true);
        setSnackbarMessage("2FA has been successfully disabled.");
      }
    }

    fetchEnableTwofa();
  };

  return (
    <Box className={classes.twofa}>
      <Box className={classes.flex}>
        <Typography variant="h2" className={classes.twofaTitle}>
          Disable Two-Factor Authentication
        </Typography>
        <Typography variant="subtitle">
          Please enter the code generated by your authentication app to disable
          2FA.
        </Typography>

        <Box className={classes.textfield}>
          <Typography className={classes.label} variant="subtitle">
            Authentication Code
          </Typography>
          <TextField
            className={classes.input}
            variant="standard"
            type="text"
            onChange={(e) => setAuthKey(e.target.value)}
            value={authKey}
          ></TextField>
        </Box>
      </Box>
      <Box className={classes.buttonBox}>
        <Button className={classes.button} onClick={() => disable2fa(false)}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Back
          </Typography>
        </Button>
        <Button className={classes.button} onClick={() => onClick()}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Verify
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
