import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { customFetch } from "../../utils/axios";
import { Sidebar } from "../../core/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useSidebar } from "../../shared/context/SidebarContext";
import MediaQuery from "react-responsive";
import { MobileHeader } from "../../core/mobile-header/MobileHeader";
import { Navbar } from "../../core/navbar/Navbar";

const useStyles = makeStyles()((theme) => ({
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
  billing: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    [`@media screen and (max-width: 1224px)`]: {
      height: "100%",
      marginTop: "8vh",
    },
  },
  title: {
    padding: "7.4vh 0 5.6vh 5.2vw",
  },
  subtitle: {
    margin: "0 7.3vw 3.2vh",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "0 7.3vw 3.2vh 5vw",
      fontWeight: "1.5",
    },
  },
  description: {
    color: theme.palette.primary.light,
    display: "block",
    margin: "0 7.3vw 3.2vh",
    [`@media screen and (max-width: 1224px)`]: {
      fontSize: "13px",
      margin: "0 7.3vw 2.5vh",
    },
  },
  state: {
    display: "flex",
    margin: "0 7.3vw 3.2vh",
    justifyContent: "space-between",
    border: `0.1vh solid ${theme.palette.background2}`,
    borderRadius: "23px",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "0 5vw 3vh 5vw",
      borderRadius: "9px",
    },
  },
  grid: {
    padding: "0 7.3vw",
  },
  gridItem: {
    paddingTop: "3vh !important",
    [`@media screen and (max-width: 1224px)`]: {
      paddingTop: "1.5vh !important",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "18.5vh",
  },
  planType: {
    margin: "0.9vh 2.1vw",
  },
  enddate: {
    margin: "0.9vh 2.1vw",
  },
  buttonBox: {
    margin: "0 2.6vw 3.7vh",
    display: "flex",
    justifyContent: "end",
    gap: "2.1vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "3.5vh 5vw 2vh",
      justifyContent: "space-between",
      gap: "0",
    },
  },
  button: {
    ...theme.typography.subtitle,
    width: "15.6vw",
    height: "4.6vh",
    borderRadius: "15px",
    marginRight: "4.2vw",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonWhite: {
    ...theme.typography.subtitle,
    background: "white",
    color: "#212121",
    width: "15.6vw",
    height: "4.6vh",
    borderRadius: "15px",
    "&:hover": { color: "white" },
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  textField: {
    ...theme.typography.subtitle,
  },
  spaceBetween: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface AddressFormData {
  companyName: string;
  vatNumber?: string;
  address: string;
  additionalInformation?: string;
  postCode: string;
  city: string;
  country: string;
  // email: string;
}

interface AddressFormErrors {
  companyName?: string;
  vatNumber?: string;
  address?: string;
  additionalInformation?: string;
  postCode?: string;
  city?: string;
  country?: string;
  // email?: string;
}

interface PlanAndBillingPageProps {}

export const PlanAndBillingPage: React.FC<PlanAndBillingPageProps> = () => {
  const { classes } = useStyles();
  const [subscriberInfo, setSubscriberInfo] = useState({
    planType: "",
    enddate: "",
  });
  const [formData, setFormData] = useState<AddressFormData>({
    companyName: "",
    vatNumber: "",
    address: "",
    additionalInformation: "",
    postCode: "",
    city: "",
    country: "",
    // email: "",
  });
  const [formErrors, setFormErrors] = useState<AddressFormErrors>({});
  const [hasAddress, setHasAddress] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { isSidebarOpen } = useSidebar();

  useEffect(() => {
    document.title = "Plan & Billing | NMS";
    if (!subscriberInfo.planType) {
      fetchData();
    }
    if (!formData.companyName) {
      getAddress();
    }
  }, [formData.companyName, subscriberInfo.planType]);

  const fetchData = async () => {
    try {
      const response = await customFetch.get("billing/subscriber-info");

      setSubscriberInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAddress = async () => {
    try {
      const response = await customFetch.get("company-information");

      setFormData({
        additionalInformation: response.data.additionalInformation || "",
        address: response.data.address || "",
        city: response.data.city || "",
        companyName: response.data.companyName || "",
        postCode: response.data.postCode || "",
        vatNumber: response.data.vatNumber || "",
        country: response.data.country || "",
        // email: response.data.email || "",
      });
      setHasAddress(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const saveAddress = async () => {
    setSnackbarMessage("Information updated.");
    if (hasAddress) {
      try {
        await customFetch.put("company-information", {
          additionalInformation: formData.additionalInformation,
          address: formData.address,
          city: formData.city,
          companyName: formData.companyName,
          postCode: formData.postCode,
          vatNumber: formData.vatNumber,
          country: formData.country,
          // email: formData.email,
        });

        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        await customFetch.post("company-information", {
          additionalInformation: formData.additionalInformation,
          address: formData.address,
          city: formData.city,
          companyName: formData.companyName,
          postCode: formData.postCode,
          vatNumber: formData.vatNumber,
          country: formData.country,
          // email: formData.email,
        });

        setOpenSnackbar(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const formatDate = (dateString: string) => {
    const parsedDate = new Date(dateString);

    const day = String(parsedDate.getUTCDate()).padStart(2, "0");
    const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0");
    const year = parsedDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const validateField = (name: string, value: string) => {
    let errors = { ...formErrors };

    switch (name) {
      case "companyName":
        errors.companyName = value.trim() ? "" : "Company Name is required!";
        break;
      case "address":
        errors.address = value.trim() ? "" : "address is required!";
        break;
      case "postCode":
        errors.postCode = value.trim() ? "" : "PostCode is required!";
        break;
      case "city":
        errors.city = value.trim() ? "" : "City is required!";
        break;
      case "country":
        errors.country = value.trim() ? "" : "Country is required!";
        break;
      case "email":
        errors.country = value.trim() ? "" : "Email is required!";
        break;
    }

    setFormErrors(errors);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validateAllFields = () => {
    let errors: AddressFormErrors = {};

    errors.companyName = formData.companyName.trim()
      ? ""
      : "Company Name is required!";
    errors.address = formData.address.trim() ? "" : "Address is required!";
    errors.postCode = formData.postCode.trim() ? "" : "PostCode is required!";
    errors.city = formData.city.trim() ? "" : "City is required!";
    errors.country = formData.country.trim() ? "" : "Country is required!";

    return errors;
  };

  const handleSave = () => {
    const errors = validateAllFields();
    const hasErrors = Object.values(errors).some((error) => error);

    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    saveAddress();
  };

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div
          style={{
            width: "inherit",
            marginLeft: isSidebarOpen ? "21%" : "5%",
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            <Box className={classes.snackbar}>
              <img src="/images/snackbar.png" alt="snackbar" width="35px"></img>
              {snackbarMessage}
            </Box>
          </Snackbar>
          <Sidebar></Sidebar>
          <Box className={classes.billing}>
            <Typography variant="h1" className={classes.title}>
              Plan & Billing
            </Typography>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}

            {!loading && (
              <div className={classes.spaceBetween}>
                <div>
                  <Typography variant="h2" className={classes.subtitle}>
                    Your Current Plan
                  </Typography>
                  <Box className={classes.state}>
                    <Typography variant="subtitle" className={classes.planType}>
                      {subscriberInfo?.planType === "FreeTrial"
                        ? "Free Trial"
                        : "Pro Plan"}
                    </Typography>
                    <Typography variant="subtitle" className={classes.enddate}>
                      {new Date(subscriberInfo.enddate) < new Date()
                        ? `Expired on: ${formatDate(subscriberInfo.enddate)}`
                        : `Expiration date: ${formatDate(
                            subscriberInfo.enddate
                          )}`}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle"
                    className={classes.description}
                  >
                    To continue to have full access to NMS after the free trial
                    ends, please subscribe to our Pro plan. Since we want to
                    offer you the best possible price-performance ratio, we will
                    create an individual quote for your team, please click the
                    “Contact Us” button if you would like to upgrade to Pro. You
                    can already add your business details here for future
                    invoices.
                  </Typography>

                  <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Company Name"
                        name="companyName"
                        variant="standard"
                        value={formData.companyName}
                        onChange={handleChange}
                        error={Boolean(formErrors.companyName)}
                        helperText={formErrors.companyName}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="VAT Number (Optional)"
                        name="vatNumber"
                        variant="standard"
                        value={formData.vatNumber}
                        onChange={handleChange}
                        error={Boolean(formErrors.vatNumber)}
                        helperText={formErrors.vatNumber}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        label="Street and Number"
                        name="address"
                        variant="standard"
                        value={formData.address}
                        onChange={handleChange}
                        error={Boolean(formErrors.address)}
                        helperText={formErrors.address}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Additional Information (Optional)"
                        name="additionalInformation"
                        variant="standard"
                        value={formData.additionalInformation}
                        onChange={handleChange}
                        error={Boolean(formErrors.additionalInformation)}
                        helperText={formErrors.additionalInformation}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        label="Zip Code"
                        name="postCode"
                        variant="standard"
                        value={formData.postCode}
                        onChange={handleChange}
                        error={Boolean(formErrors.postCode)}
                        helperText={formErrors.postCode}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="City"
                        name="city"
                        variant="standard"
                        value={formData.city}
                        onChange={handleChange}
                        error={Boolean(formErrors.city)}
                        helperText={formErrors.city}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Country"
                        name="country"
                        variant="standard"
                        value={formData.country}
                        onChange={handleChange}
                        error={Boolean(formErrors.country)}
                        helperText={formErrors.country}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Box className={classes.buttonBox}>
                  <Box>
                    <Button
                      className={classes.buttonWhite}
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      {hasAddress ? "Update Information" : "Save Information"}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="https://calendly.com/nodemonitoring/30"
                      target="_blank"
                    >
                      Contact Us
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div
          style={{
            width: "100%",
            marginBottom: "56px",
          }}
        >
          <Box className={classes.billing}>
            <MobileHeader title="Plan & Billing"></MobileHeader>
            {loading && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}

            {!loading && (
              <div>
                <div>
                  <Typography variant="h2" className={classes.subtitle}>
                    Your Current Plan
                  </Typography>
                  <Box className={classes.state}>
                    <Typography variant="subtitle" className={classes.planType}>
                      {subscriberInfo?.planType === "FreeTrial"
                        ? "Free Trial"
                        : "Pro Plan"}
                    </Typography>
                    <Typography variant="subtitle" className={classes.enddate}>
                      {new Date(subscriberInfo.enddate) < new Date()
                        ? `Expired on: ${formatDate(subscriberInfo.enddate)}`
                        : `Expiration date: ${formatDate(
                            subscriberInfo.enddate
                          )}`}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle"
                    className={classes.description}
                  >
                    To continue to have full access to NMS after the free trial
                    ends, please subscribe to our Pro plan. Since we want to
                    offer you the best possible price-performance ratio, we will
                    create an individual quote for your team, please click the
                    “Contact Us” button if you would like to upgrade to Pro. You
                    can already add your business details here for future
                    invoices.
                  </Typography>

                  <Grid container spacing={4} className={classes.grid}>
                    <Grid item xs={12} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Company Name"
                        name="companyName"
                        variant="standard"
                        value={formData.companyName}
                        onChange={handleChange}
                        error={Boolean(formErrors.companyName)}
                        helperText={formErrors.companyName}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="VAT Number (Optional)"
                        name="vatNumber"
                        variant="standard"
                        value={formData.vatNumber}
                        onChange={handleChange}
                        error={Boolean(formErrors.vatNumber)}
                        helperText={formErrors.vatNumber}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        label="Street and Number"
                        name="address"
                        variant="standard"
                        value={formData.address}
                        onChange={handleChange}
                        error={Boolean(formErrors.address)}
                        helperText={formErrors.address}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Additional Information (Optional)"
                        name="additionalInformation"
                        variant="standard"
                        value={formData.additionalInformation}
                        onChange={handleChange}
                        error={Boolean(formErrors.additionalInformation)}
                        helperText={formErrors.additionalInformation}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        label="Zip Code"
                        name="postCode"
                        variant="standard"
                        value={formData.postCode}
                        onChange={handleChange}
                        error={Boolean(formErrors.postCode)}
                        helperText={formErrors.postCode}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="City"
                        name="city"
                        variant="standard"
                        value={formData.city}
                        onChange={handleChange}
                        error={Boolean(formErrors.city)}
                        helperText={formErrors.city}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                      <TextField
                        InputProps={{ classes: { input: classes.textField } }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        label="Country"
                        name="country"
                        variant="standard"
                        value={formData.country}
                        onChange={handleChange}
                        error={Boolean(formErrors.country)}
                        helperText={formErrors.country}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Box className={classes.buttonBox}>
                  <Box>
                    <Button
                      className={classes.buttonWhite}
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      {hasAddress ? "Update Information" : "Save Information"}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="https://calendly.com/nodemonitoring/30"
                      target="_blank"
                    >
                      Contact Us
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </Box>
        </div>
        <Navbar></Navbar>
      </MediaQuery>
    </>
  );
};
