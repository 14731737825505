import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  separator: {
    borderBottom: `4px solid ${theme.palette.primary.light}`,
    margin: "15px auto 25px",
    width: "100px",
  },
  title: {
    textAlign: "center",
  },
  desc: {
    color: theme.palette.primary.light,
    textAlign: "center",
  },
}));

interface PageTitleProps {
  title: string;
  desc: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, desc }) => {
  const { classes } = useStyles();

  return (
    <Box>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.desc} variant="subtitle">
        {desc}
      </Typography>
    </Box>
  );
};
