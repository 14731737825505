import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  IconButton,
  MenuItem,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  Button,
  TableRow,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../../../core/auth/hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    border: "none",
    boxShadow: "none",
    margin: "0 6.3vw 0 7.3vw",
    width: "auto",
    overflow: "unset",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "8vh 5vw 0",
      width: "90%",
    },
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 1.5vh",
    width: "94%",
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
    },
  },
  headerLeft: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    borderRadius: "2.1vh 0 0 2.1vh",
    [`@media screen and (max-width: 1224px)`]: {
      padding: 0,

    },
  },
  headerMid: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    [`@media screen and (max-width: 1224px)`]: {
      padding: 0,
      lineHeight: 1.1,
    },
  },
  headerRight: {
    ...theme.typography.subtitle,
    fontWeight: "bold",
    background: theme.palette.background2,
    border: 0,
    borderRadius: "0 2.1vh 2.1vh 0",
    [`@media screen and (max-width: 1224px)`]: {
      padding: 0,
    },
  },
  cellLeft: {
    ...theme.typography.body1,
    borderTop: `1px solid ${theme.palette.background2}`,
    borderLeft: `1px solid ${theme.palette.background2}`,
    borderBottom: `1px solid ${theme.palette.background2}`,
    borderRadius: "2.1vh 0 0 2.1vh",
    padding: "0 16px",
    [`@media screen and (max-width: 1224px)`]: {
      borderRadius: "2.1vh 0 0 2.1vh",
      maxWidth: "30vw",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      padding: "0 0 0 3vw",
    },
  },
  cellMid: {
    ...theme.typography.body1,
    borderTop: `1px solid ${theme.palette.background2}`,
    borderBottom: `1px solid ${theme.palette.background2}`,
    padding: "0 0.8vw",
  },
  cellRight: {
    ...theme.typography.body1,
    borderTop: `1px solid ${theme.palette.background2}`,
    borderRight: `1px solid ${theme.palette.background2}`,
    borderBottom: `1px solid ${theme.palette.background2}`,
    borderRadius: "0 2.1vh 2.1vh 0",
    padding: "0 0.8vw",
  },
  tableRow: {
    height: "5.1vh",
    [`@media screen and (max-width: 1600px)`]: {
      height: "40px",
    },
  },
  menu: {
    backgroundColor: "#E8E9F3D",
  },
  buttonBlack: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "3.9vh",
    width: "100%",
  },
  buttonBlackText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
  buttonWhite: {
    background: theme.palette.button.light,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "3.9vh",
    border: `1px solid ${theme.palette.button.lightText}`,
    width: "100%",
    "&:hover": {
      border: "none",
      "& span": {
        color: "white",
      },
      "& span svg path": {
        stroke: "white !important",
      },
    },
    "& span svg path": {
      stroke: "black !important",
    },
  },
  buttonWhiteText: {
    ...theme.typography.subtitle,
    color: theme.palette.button.lightText,
    textTransform: "none",

    "&:hover": {
      stroke: "white !important",
    },
  },
  buttonOutlined: {
    border: "1px solid white",
    width: "30%",
    height: "4.6vh",
    borderRadius: "15px",
    margin: "20px",
    "&:hover": {
      border: "none",
    },
  },
  buttonBlackDialog: {
    background: theme.palette.button.main,
    display: "flex",
    textAlign: "end",
    borderRadius: "15px",
    height: "4.6vh",
    width: "30%",
    margin: "20px",
  },
  dialogText: {
    background: theme.palette.primary.light,
    color: "white !important",
    margin: "4.6vh 2.6vw",
    textAlign: "center",
  },
  dialogContent: {
    overflow: "hidden",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.primary.light,
    color: "white !important",
  },
  icon: {
    position: "absolute",
    top: "15px",
    right: "15px",
    height: "2.6vw",
    width: "2.6vw",
  },
  logo: {
    verticalAlign: "bottom",
  },
}));

interface UserTableProps {
  user: any[];
  onEdit: (selectedUser: {
    user: string;
    role: string;
    twofa: boolean;
  }) => void;
  onDelete: (selectedUser: {
    user: string;
    role: string;
    twofa: boolean;
  }) => void;
}

export const UserTable: React.FC<UserTableProps> = ({
  user,
  onEdit,
  onDelete,
}) => {
  const { email } = useAuth();
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<{
    user: string;
    role: string;
    twofa: boolean;
  }>({ user: "", role: "", twofa: false });
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    user: { user: string; role: string; twofa: boolean }
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Dialog
        PaperProps={{
          style: {
            background: "#6D7A8D",
            borderRadius: "23px",
            height: "fit-content",
            width: "42vw",
            maxWidth: "42vw",
          },
        }}
        open={open}
        onClose={handleCloseDialog}
      >
        <img
          src="/images/info-box-close.png"
          alt="close"
          onClick={handleCloseDialog}
          className={classes.icon}
        />
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogText}>
            <Typography variant="subtitle">
              Are you sure that you want to delete this user?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonOutlined}
            variant="outlined"
            onClick={handleCloseDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={() => {
              onDelete(selectedUser);
              handleCloseDialog();
            }}
            className={classes.buttonBlackDialog}
          >
            <Typography variant="subtitle" className={classes.buttonBlackText}>
              Delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell align="center" className={classes.headerLeft}>
              User Name
            </TableCell>
            <TableCell align="center" className={classes.headerMid}>
              Role
            </TableCell>
            <TableCell align="center" className={classes.headerMid}>
              2FA Status
            </TableCell>
            <TableCell className={classes.headerRight} />
          </TableRow>
        </TableHead>
        <TableBody>
          {user.map((user, index) => (
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.cellLeft} align="center">
                {user.user}
              </TableCell>
              <TableCell className={classes.cellMid} align="center">
                {user.role}
              </TableCell>
              <TableCell className={classes.cellMid} align="center">
                {user.twofa ? (
                  <img
                    className={classes.logo}
                    src="/images/rule-checked.png"
                    alt="logo"
                  />
                ) : (
                  <img
                    className={classes.logo}
                    src="/images/rule-unchecked.png"
                    alt="logo"
                  />
                )}
              </TableCell>
              <TableCell className={classes.cellRight} align="center">
                {user.user !== email && (
                  <>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, user)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={classes.menu}
                      slotProps={{
                        paper: {
                          style: {
                            width: "260px",
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "#E8E9F3",
                            borderRadius: "23px",
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <Button
                          className={classes.buttonBlack}
                          startIcon={
                            <img src="/images/button-edit.png" alt="add" />
                          }
                          onClick={() => {
                            onEdit(selectedUser);
                          }}
                        >
                          <Typography
                            variant="subtitle"
                            className={classes.buttonBlackText}
                          >
                            Edit
                          </Typography>
                        </Button>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Button
                          className={classes.buttonWhite}
                          startIcon={
                            <svg
                              width="23"
                              height="25"
                              viewBox="0 0 23 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 6.14307H21.5714"
                                stroke="white"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.57153 6.14307H19.0001V21.5716C19.0001 22.0263 18.8195 22.4623 18.498 22.7838C18.1765 23.1053 17.7405 23.2859 17.2858 23.2859H5.28582C4.83116 23.2859 4.39513 23.1053 4.07364 22.7838C3.75214 22.4623 3.57153 22.0263 3.57153 21.5716V6.14307Z"
                                stroke="white"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7 6.14286V5.28571C7 4.14907 7.45153 3.05898 8.25526 2.25526C9.05898 1.45153 10.1491 1 11.2857 1C12.4224 1 13.5124 1.45153 14.3162 2.25526C15.1199 3.05898 15.5714 4.14907 15.5714 5.28571V6.14286"
                                stroke="white"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.71436 11.2876V18.1482"
                                stroke="white"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.8572 11.2876V18.1482"
                                stroke="white"
                                stroke-width="1.71429"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          onClick={() => setOpen(true)}
                        >
                          <Typography
                            variant="subtitle"
                            className={classes.buttonWhiteText}
                          >
                            Delete
                          </Typography>
                        </Button>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
