import React from "react";
import { makeStyles } from "tss-react/mui";
import { Footer } from "../../../footer/Footer";
import { PageTitle } from "../page-title/PageTitle";
import { FooterState } from "../../../../utils/enums";
import MediaQuery from 'react-responsive';

const useStyles = makeStyles()((theme) => ({
  background: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: theme.palette.background2,
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
      height: "100vh",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flexDirection: "column",
    },
  },
  outerBox: {
    display: "flex",
    background: theme.palette.background1,
    borderRadius: "25px",
    height: "80vh",
    width: "75vw",
    [`@media screen and (max-width: 1600px)`]: {
      height: "70vh",
      width: "80vw",
    },
    [`@media screen and (max-width: 1224px)`]: {
      height: "auto",
      width: "70vw",
    },
    [`@media screen and (max-width: 600px)`]: {
      height: "auto",
      width: "90vw",
    },
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    //margin: "60px 40px 60px 80px",
    margin: "10vh 4vw 10vh 6vw",
    width: "50%",
    [`@media screen and (max-width: 1600px)`]: {
      margin: "10vh 4vw 6vh 6vw",
    },
    [`@media screen and (min-width: 2000px) and (max-width: 2800px)`]: {
      margin: "10vh 4vw 15vh 6vw",
    },
    [`@media screen and (min-width: 2801px)`]: {
      margin: "10vh 4vw 20vh 6vw",
    },
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
      margin: "4vh 1vw 3vh",
      justifyContent: "center",
    },
  },
  form: {
    width: "22vw",
    margin: "0 auto",
    textAlign: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "50%",
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "75%",
    },
  },
  logo: {
    width: "30%",
    alignSelf: "start",
    [`@media screen and (max-width: 1224px)`]: {
      width: "300px",
      alignSelf: "normal",
      justifyContent: "center",
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "260px",
      alignSelf: "normal",
      justifyContent: "center",
    },
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    [`@media screen and (max-width: 1224px)`]: {
      width: "100%",
      marginBottom: "12vh",
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "100%",
      marginTop: "-8vh",
      marginBottom: "10vh",
    },
  },
  img: {
    width: "95%",
    maxHeight: "96%",
    minHeight: "96%",
  },
}));

interface CommonLayoutProps {
  title: string;
  desc: string;
  children: string | JSX.Element;
}

export const CommonLayout: React.FC<CommonLayoutProps> = ({
  title,
  desc,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <MediaQuery minWidth={1224}>
        <div className={classes.background}>
          <div className={classes.outerBox}>
            <div className={classes.formBox}>
              <img
                className={classes.logo}
                src="/images/nms-logo-big.png"
                alt="logo"
              />
              <div className={classes.form}>
                <PageTitle title={title} desc={desc} />
                {children}
                <Footer footerState={FooterState.Login} />
              </div>
            </div>
            <div className={classes.imgBox}>
              <img
                className={classes.img}
                src="/images/login-image.webp"
                alt="login"
              />
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div className={classes.background} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/login-image.webp'})` }}>
          <div className={classes.imgBox}>
            <img
              className={classes.logo}
              src="/images/nms-logo-big-white.png"
              alt="logo"
            />
          </div>
          <div className={classes.outerBox}>
            <div className={classes.formBox}>
              <div className={classes.form}>
                <PageTitle title={title} desc={desc} />
                {children}
                <Footer footerState={FooterState.Login} />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};
