import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Snackbar,
  TextField,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CommonLayout } from "../../components/common-layout/CommonLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles()((theme) => ({
  textField: {
    ...theme.typography.subtitle,
  },
  confirm: {
    marginBottom: "30px",
  },
  terms: {
    marginBottom: "15px",
  },
  button: {
    marginBottom: "25px",
    borderRadius: "15px",
  },
  logo: {
    paddingRight: "16px",
    width: "20px",
    height: "20px",
  },
  snackbar: {
    ...theme.typography.subtitle,
    display: "flex",
    background: "#6D7A8D",
    borderRadius: "23px",
    padding: "1.9vh 2.1vw",
    width: "23.4vw",
    gap: "2.8vh",
    color: "white",
    alignItems: "center",
    [`@media screen and (max-width: 1224px)`]: {
      width: "96%",
      justifyContent: "center",
    },
  },
}));

interface AccountActivationProps {}

export const AccountActivationPage: React.FC<AccountActivationProps> = () => {
  const { classes } = useStyles();
  const { resetPassword, loading } = useAuth();
  const { email } = useParams<{ email: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("Token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [match, setMatch] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const isValidLength = newPassword.length >= 8;
  const hasLowerCase = /[a-z]/.test(newPassword);
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const hasNumber = /[0-9]/.test(newPassword);
  const hasSymbol = /[!§$%&/()=?`´*'+~#_\-;:,.<>|\\{}\[\]]/.test(newPassword);

  const allValid =
    isValidLength &&
    hasLowerCase &&
    hasUpperCase &&
    hasNumber &&
    hasSymbol &&
    match;

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    setMatch(e.target.value === newPassword);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && token) {
      const response: any = await resetPassword(
        email,
        newPassword,
        confirmPassword,
        token,
        false
      );

      if (response.status === 200) {
        setError(false);
        navigate("/login");
      }

      if (response.response && response.response.status === 500) {
        setSnackbarMessage("Something went wrong, please try again later.");
        setError(true);
      } else if (response.response && response.response.status === 400) {
        setSnackbarMessage("Token was incorrect.");
        setError(true);
      }

      setOpen(true);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(!open)}
      >
        <Box className={classes.snackbar}>
          {!error && (
            <img src="/images/snackbar.png" alt="snackbar" width="35px"></img>
          )}
          {error && (
            <img src="/images/error.png" alt="snackbar" width="35px"></img>
          )}
          {snackbarMessage}
        </Box>
      </Snackbar>
      <CommonLayout title="Activate your account" desc="Please set a Password">
        <form onSubmit={allValid ? handleSubmit : () => {}}>
          <TextField
            InputProps={{ classes: { input: classes.textField } }}
            type="password"
            variant="standard"
            margin="normal"
            label="New Password"
            fullWidth
            autoFocus
            onChange={handleNewPasswordChange}
          />
          <List>
            <ListItem
              style={{
                color: isValidLength ? "#1D426D" : "#6D7A8D",
                padding: "0 16px",
              }}
            >
              {isValidLength ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              at least 8 characters
            </ListItem>
            <ListItem
              style={{
                color: hasLowerCase ? "#1D426D" : "#6D7A8D",
                padding: "0 16px",
              }}
            >
              {hasLowerCase ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="unchecked"
                />
              )}
              at least one lowercase letter
            </ListItem>
            <ListItem
              style={{
                color: hasUpperCase ? "#1D426D" : "#6D7A8D",
                padding: "0 16px",
              }}
            >
              {hasUpperCase ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              at least one uppercase letter
            </ListItem>
            <ListItem
              style={{
                color: hasNumber && hasSymbol ? "#1D426D" : "#6D7A8D",
                padding: "0 16px",
              }}
            >
              {hasNumber && hasSymbol ? (
                <img
                  className={classes.logo}
                  src="/images/rule-checked.png"
                  alt="rule-checked"
                />
              ) : (
                <img
                  className={classes.logo}
                  src="/images/rule-unchecked.png"
                  alt="rule-unchecked"
                />
              )}
              at least one number and symbol
            </ListItem>
          </List>
          <TextField
            className={classes.confirm}
            InputProps={{ classes: { input: classes.textField } }}
            type="password"
            label="Confirm new Password"
            variant="standard"
            margin="normal"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={!match}
            helperText={match ? "" : "*The Passwords do not match"}
          />
          <FormControlLabel
            className={classes.terms}
            control={
              <Checkbox
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
                name="agreeToTerms"
              />
            }
            label={
              <span>
                I accept the{" "}
                <Link
                  href="https://nodemonitoring.io/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </Link>{" "}
                and{" "}
                <Link
                  href="https://nodemonitoring.io/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </span>
            }
          />
          <Button
            className={classes.button}
            type="submit"
            fullWidth
            disabled={
              !allValid || !confirmPassword.length || loading || !agreedToTerms
            }
            variant="contained"
            color="primary"
          >
            {loading && <CircularProgress size="23px" />}
            {!loading && "Submit"}
          </Button>
        </form>
      </CommonLayout>
    </>
  );
};
