import React, { createContext, useContext, useState } from "react";

export enum StackState {
  NONE = "none",
  CREATE = "create",
  EDIT = "edit",
  FINISHED = "finished",
}

interface StackStateContextProps {
  stackState: StackState;
  setStackState: React.Dispatch<React.SetStateAction<StackState>>;
}

const StackStateContext = createContext<StackStateContextProps | undefined>(
  undefined
);

export const useStackState = (): StackStateContextProps => {
  const context = useContext(StackStateContext);
  if (!context) {
    throw new Error("useStackState must be used within a StackStateProvider");
  }
  return context;
};

export const StackStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [stackState, setStackState] = useState<StackState>(StackState.NONE);

  return (
    <StackStateContext.Provider value={{ stackState, setStackState }}>
      {children}
    </StackStateContext.Provider>
  );
};
