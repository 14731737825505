import React from "react";
import { makeStyles } from "tss-react/mui";
import { Box, Button, Typography } from "@mui/material";
import { StepOne } from "./step-one/StepOne";
import { StepThree } from "./step-three/StepThree";
import { StepTwo } from "./step-two/StepTwo";
import { StackItem } from "../../../../utils/types";
import { StepFour } from "./step-four/StepFour";
import { StackState } from "../../../../shared/context/StackStateContext";

const useStyles = makeStyles()((theme) => ({
  deployment: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "0 7.3vw",
    [`@media screen and (max-width: 1224px)`]: {
      margin: "10vh 7.3vw 0",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3.7vh",
  },
  buttonBoxEnd: {
    display: "flex",
    justifyContent: "end",
  },
  buttonBack: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonNext: {
    background: theme.palette.button.main,
    borderRadius: "15px",
    width: "20%",
    height: "4.6vh",
    [`@media screen and (max-width: 1224px)`]: {
      width: "40vw",
    },
  },
  buttonBlackText: {
    ...theme.typography.body1,
    color: theme.palette.button.mainText,
    textTransform: "none",
  },
}));

interface DeploymentProps {
  currentStack: StackItem;
  step: number;
  setStep: (number: number) => void;
  onBack: () => void;
  onNext: () => void;
  stackState: StackState;
}

export const Deployment: React.FC<DeploymentProps> = ({
  currentStack,
  step,
  setStep,
  onBack,
  onNext,
  stackState,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.deployment}>
      {step === 6 && <StepOne></StepOne>}
      {step === 7 && <StepTwo></StepTwo>}
      {step === 8 && <StepThree currentStack={currentStack}></StepThree>}
      {step === 9 && <StepFour></StepFour>}
      <Box className={classes.buttonBox}>
        <Button className={classes.buttonBack} onClick={() => onBack()}>
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            Back
          </Typography>
        </Button>
        <Button
          className={classes.buttonNext}
          onClick={() =>
            stackState === StackState.EDIT && step === 9 ? setStep(0) : onNext()
          }
        >
          <Typography variant="subtitle" className={classes.buttonBlackText}>
            {step === 9
              ? stackState === StackState.EDIT
                ? "Finish"
                : "I Followed All Steps"
              : "Next"}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
